import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Box, Button, Chip, Divider, Grid, Option, Select, Typography } from '@mui/joy';
import DashboardContainer from '../components/DashboardContainer';
import API from '../api';
import { Paper } from '@mui/material';
import styled from '@emotion/styled';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark, a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const Section = styled(Box)`
  padding: 1rem;
`;

const TableRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? 'var(--joy-palette-neutral-100)' : 'transparent'};
`;

const SiteEndpoints: React.FC = () => {
	const [endpointsByOriginUrl, setEndpointsByOriginUrl] = useState<Record<string, any[]>>({});
	const [selectedEndpoint, setSelectedEndpoint] = useState<any | null>(null);
	const [endpointResponse, setEndpointResponse] = useState<any | null>(null);

	// useEffect(() => {
	// 	setSiteResponse(null);
	// 	if (selectedAsset) {
	// 		API.getSiteResponse({ originUrl: selectedAsset.originUrl, method: selectedAsset.method, contentType: selectedAsset.contentType, url: selectedAsset.url }).then((res) => {
	// 			const bufferDataArray = res.site.buffer.data;
	// 			const decoder = new TextDecoder();
	// 			const stringResponse = decoder.decode(new Uint8Array(bufferDataArray));
	// 			setSiteResponse(stringResponse);
	// 		});
	// 	}
	// }, [selectedAsset]);

	useEffect(() => {
		const fetchSites = async () => {
			const { endpoints } = await API.getSiteEndpoints();
			const endpointsByOriginUrl: Record<string, any[]> = {};
			for (const endpoint of endpoints) {
				endpointsByOriginUrl[endpoint.originUrl] = endpointsByOriginUrl[endpoint.originUrl] || [];
				endpointsByOriginUrl[endpoint.originUrl].push(endpoint);
			}
			console.log(endpointsByOriginUrl);
			setSelectedEndpoint(Object.keys(endpointsByOriginUrl)[0]);
			setEndpointsByOriginUrl(endpointsByOriginUrl);
		};
		fetchSites();
	}, []);

	return (
		<DashboardContainer>
			<Typography marginBottom={2} level='h2'>Site Endpoints</Typography>
			<Select value={selectedEndpoint} onChange={(event, value) => setSelectedEndpoint(value)}>
				{Object.keys(endpointsByOriginUrl).map((originUrl) => (
					<Option key={originUrl} value={originUrl}>{originUrl}</Option>
				))}
			</Select>

			<Grid container spacing={2}>
				<Grid xs={12} md={7}>
					<Paper style={{ padding: 0, marginTop: 16, overflow: 'hidden' }}>
						<table style={{ tableLayout: 'fixed', width: '100%' }}>
							<thead>
								<tr>
									<th style={{ width: '82px' }}>Method</th>
									<th style={{ width: 'auto' }}>URL</th>
								</tr>
							</thead>
							<tbody>
								{endpointsByOriginUrl[selectedEndpoint]?.map((endpoint) => (
									<TableRow active={endpoint.url === selectedEndpoint?.url} key={endpoint.url} onClick={() => setSelectedEndpoint(endpoint)}>
										<td style={{ width: '82px' }}><Chip size='sm' variant='soft' color='neutral'>{endpoint.method}</Chip></td>
										<Typography sx={{ width: 'auto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} component='td' level='body-xs'>{endpoint.urlPattern}</Typography>
									</TableRow>
								))}
							</tbody>
						</table>
					</Paper>
				</Grid>
				<Grid xs={12} md={5}>
					<Paper style={{ padding: 0, marginTop: 16 }}>
						<Section>
							<Typography level='title-sm' sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{selectedEndpoint?.url}</Typography>
						</Section>
						<Divider />
						<Section>
							<Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='center' rowGap={1} columnGap={2} margin={0}>
								<Typography color='neutral' level='title-sm'>Method</Typography>
								<Chip size='sm' variant='soft' color='neutral'>{selectedEndpoint?.method}</Chip>
								<Typography color='neutral' whiteSpace='nowrap' level='title-sm'>Content Type</Typography>
								<Chip size='sm' variant='soft' color='neutral'>{selectedEndpoint?.contentType}</Chip>
							</Box>
						</Section>
						<Divider />
						<Section>
							{Boolean(selectedEndpoint) && (
								<>
									<Typography level='title-sm'>Response</Typography>
									<Box bgcolor='var(--joy-palette-neutral-700)' padding={1.5}>
										<SyntaxHighlighter
											language='json'
											style={a11yDark}
											customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }}
											showLineNumbers={false}
											wrapLongLines
											copyable
										>
											{endpointResponse}
										</SyntaxHighlighter>
									</Box>
								</>
							)}
						</Section>
					</Paper>
				</Grid>
			</Grid>
			{/* <AccordionGroup>
				{Object.entries(sitesByOriginUrl).map(([originUrl, urls]) => (
					<Accordion key={originUrl}>
						<AccordionSummary>
							<Typography>{originUrl}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							{urls.map((url) => <Typography key={url}>{url}</Typography>)}
						</AccordionDetails>
					</Accordion>
				))}
			</AccordionGroup> */}
		</DashboardContainer>
	);
};

export default SiteEndpoints;
