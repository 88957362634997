import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Avatar, Box, Button, Chip, Divider, Grid, ListItemDecorator, Option, Select, Tab, TabPanel, TabList, Tabs, Typography } from '@mui/joy';
import DashboardContainer from '../components/DashboardContainer';
import API from '../api';
import { Paper } from '@mui/material';
import styled from '@emotion/styled';
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import CreateSiteModal from '@/components/CreateSiteModal';


const Section = styled(Box)`
  padding: 1rem;
`;

const TableRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? 'var(--joy-palette-neutral-100)' : 'transparent'};
`;

const SitesPage: React.FC = () => {
	const [sites, setSites] = useState<any[]>([]);
	const [selectedSite, setSelectedSite] = useState<any | null>(null);
	const [selectedTab, setSelectedTab] = useState<string>('endpoints');
	const [selectedModel, setSelectedModel] = useState<any | null>(null);
	const [selectedModelTab, setSelectedModelTab] = useState<string>('schema');


	const [endpoints, setEndpoints] = useState<any[]>([]);
	const [models, setModels] = useState<any[]>([]);
	const [documents, setDocuments] = useState<any[]>([]);

	const [openCreateSiteModal, setOpenCreateSiteModal] = useState(false);

	useEffect(() => {
		const fetchSites = async () => {
			const { sites } = await API.getSites();
			setSites(sites);
			setSelectedSite(sites?.[0]?.id || null);
		};
		fetchSites();
	}, [openCreateSiteModal]);

	useEffect(() => {
		setEndpoints([]);
		setModels([]);
		if (selectedSite) {
			API.getSiteEndpoints(selectedSite).then((res) => {
				setEndpoints(res.endpoints);
				console.log('endpoints', res.endpoints);
			});
			API.getSiteModels(selectedSite).then((res) => {
				setModels(res.models);
				setSelectedModel(res.models[0]);
				console.log('models', res.models);
			});
		}
	}, [selectedSite]);

	useEffect(() => {
		if (selectedModel) {
			API.getModelDocuments(selectedModel.id).then((res) => {
				setDocuments(res.documents);
				console.log('documents', res.documents);
			});
		}
	}, [selectedModel]);

	return (
		<DashboardContainer>
			<CreateSiteModal open={openCreateSiteModal} onClose={() => {setOpenCreateSiteModal(false);}} />
			<Typography marginBottom={2} level='h2'>Sites</Typography>
			<Box
				display='flex'
				justifyContent='flex-end'
				marginBottom={2}
			>
				<Select style={{ flex: 3 }} value={selectedSite} onChange={(event, value) => setSelectedSite(value)} renderValue={(value) => {
					const site = sites.find((site) => site.id === value.value);
					return (
						<Box display='flex' alignItems='center' gap={1}>
							<img width={14} height={14} src={site?.imgUrl} />
							<Typography level='body-sm'>{site?.name}</Typography>
						</Box>
					);
				}}>
					{sites.map((site) => (
						<Option key={site.id} value={site.id}>
							<img width={14} height={14} src={site.imgUrl} />
							<Typography level='body-sm'>{site.name}</Typography>
						</Option>
					))}
				</Select>
				<Button
					variant='shadowed'
					color='secondary'
					onClick={() => setOpenCreateSiteModal(true)}
				>
					Create Site
				</Button>
			</Box>

			<Box marginTop={2}>

				<Tabs value={selectedTab} onChange={(event, value) => setSelectedTab(value as string)}>
					<TabList>
						<Tab value='endpoints'>Endpoints</Tab>
						<Tab value='models'>Models</Tab>
					</TabList>
					<TabPanel value='endpoints'>
						<Typography level='h3'>Endpoints</Typography>
					</TabPanel>
					<TabPanel value='models' sx={{ padding: 0, backgroundColor: 'white' }}>
						<Grid container spacing={0}>
							<Grid xs={12} md={3}>
								<Paper style={{ padding: 0, marginTop: 16, overflow: 'hidden' }}>
									<table style={{ tableLayout: 'fixed', width: '100%' }}>
										<thead>
											<tr>
												<th style={{ width: '82px' }}>Model</th>
											</tr>
										</thead>
										<tbody>
											{models.map((model) => (
												<TableRow active={model.id === selectedModel?.id} key={model.id} onClick={() => setSelectedModel(model)}>
													<td style={{ width: '82px' }}>{model.name}</td>
												</TableRow>
											))}
										</tbody>
									</table>
								</Paper>
							</Grid>
							<Grid xs={12} md={9}>
								<Paper style={{ padding: 0, marginTop: 16, overflow: 'hidden' }}>
									<Tabs value={selectedModelTab} onChange={(event, value) => setSelectedModelTab(value as string)}>
										<TabList>
											<Tab value='schema'>Schema</Tab>
											<Tab value='documents'>Documents</Tab>
										</TabList>
										<TabPanel value='schema'>
											<CodeMirror
												theme={vscodeDark}
												value={JSON.stringify(selectedModel?.schema, null, 2)}
												extensions={[json()]}
											/>
										</TabPanel>
										<TabPanel value='documents'>
											<Box display='flex' flexDirection='column' gap={1}>
												{documents.map((document) => (
													<CodeMirror
														key={document.id}
														theme={vscodeDark}
														value={JSON.stringify(document.data, null, 2)}
														extensions={[json()]}
														readOnly
														basicSetup={{ lineNumbers: false }}
													/>
												))}
											</Box>
										</TabPanel>
									</Tabs>
								</Paper>
							</Grid>
						</Grid>
					</TabPanel>
				</Tabs>

			</Box>

		</DashboardContainer>
	);
};

export default SitesPage;

