import React from 'react';

export default function TestTubeIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg version='1.1' id='fi_782711' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' {...props}>
			<g>
				<g>
					<path d='M296,232c-5.52,0-10,4.48-10,10s4.48,10,10,10c5.52,0,10-4.48,10-10S301.52,232,296,232z'></path>
				</g>
			</g>
			<g>
				<g>
					<path d='M256,172c-5.52,0-10,4.48-10,10s4.48,10,10,10c5.52,0,10-4.48,10-10S261.52,172,256,172z'></path>
				</g>
			</g>
			<g>
				<g>
					<path d='M136,432c-5.52,0-10,4.48-10,10c0,5.52,4.48,10,10,10s10-4.48,10-10C146,436.48,141.52,432,136,432z'></path>
				</g>
			</g>
			<g>
				<g>
					<path d='M454.416,407.678L326,179.381V58.286c11.641-4.127,20-15.248,20-28.286c0-16.542-13.458-30-30-30H196
			c-16.542,0-30,13.458-30,30c0,13.038,8.359,24.159,20,28.286v121.095L57.584,407.678c-12.328,21.916-12.109,47.959,0.584,69.663
			C70.86,499.043,93.448,512,118.59,512h274.82c25.142,0,47.729-12.957,60.422-34.659
			C466.525,455.637,466.744,429.594,454.416,407.678z M204.716,186.902c0.842-1.496,1.284-3.185,1.284-4.902V60h10
			c5.522,0,10-4.478,10-10s-4.478-10-10-10h-20c-5.514,0-10-4.486-10-10s4.486-10,10-10h120c5.514,0,10,4.486,10,10s-4.486,10-10,10
			h-20c-5.522,0-10,4.478-10,10s4.478,10,10,10h10v122c0,1.718,0.442,3.405,1.284,4.902l58.941,104.787
			c-35.995-2.915-71.865,4.365-104.821,21.43c-42.366,21.924-89.529,25.784-134,11.227L204.716,186.902z M436.567,467.244
			C427.502,482.745,411.368,492,393.41,492H118.59c-17.958,0-34.092-9.255-43.157-24.756c-9.067-15.503-9.224-34.106-0.417-49.762
			l42.408-75.392c20.576,7.185,41.84,10.812,63.437,10.812c3.197,0,6.402-0.079,9.613-0.238
			c27.905-1.382,54.862-8.711,80.124-21.784c33.792-17.496,70.959-23.536,107.783-17.581l58.604,104.183
			C445.791,433.138,445.635,451.741,436.567,467.244z'></path>
				</g>
			</g>
			<g>
				<g>
					<path d='M216,232c-16.542,0-30,13.458-30,30s13.458,30,30,30s30-13.458,30-30S232.542,232,216,232z M216,272
			c-5.514,0-10-4.486-10-10c0-5.514,4.486-10,10-10c5.514,0,10,4.486,10,10C226,267.514,221.514,272,216,272z'></path>
				</g>
			</g>
			<g>
				<g>
					<path d='M376,432H176c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h200c5.522,0,10-4.478,10-10
			C386,436.478,381.522,432,376,432z'></path>
				</g>
			</g>
		</svg>
	);
}
