import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, FormLabel, Input, ListItemDecorator, Modal, ModalDialog, Option, Select, Switch, Textarea, Typography } from '@mui/joy';
import LogoIcon from '@/icons/LogoIcon';
import SchemaInput, { JsonSchema } from '@/pages/Builder/components/SchemaInput';
import styled from '@emotion/styled';
import WebIcon from '@/icons/WebIcon';
import API from '@/api';
import { BuilderContext } from '@/pages/Builder/BuilderContext';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '@/context/AppContext';
import DisconnectedIcon from '@/icons/DisconnectedIcon';
import ReactCodeMirror, { EditorView } from '@uiw/react-codemirror';
import { customTheme } from '../pages/EvalRunPage';


const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;


const GenerateStepTestCaseModal = ({ open, onClose, selectedSteps, testCaseRun, snapshotSteps }: { open: boolean, onClose: () => void, selectedSteps: any, testCaseRun: any, snapshotSteps: any }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [name, setName] = useState<string>('New Test');
	const [prompt, setPrompt] = useState<string>(selectedSteps?.log?.log?.message);
	const [correctOutput, setCorrectOutput] = useState<string>('');
	const [evaluationMode, setEvaluationMode] = useState<'actions' | 'output'>('actions');
	const [testCaseSets, setTestCasesSets] = useState<any[]>([]);
	const [testCaseSetId, setTestCaseSetId] = useState<number>(2);

	console.log('selectedSteps', selectedSteps);

	useEffect(() => {
		const fetchTestCases = async () => {
			const { testCaseSets } = await API.getTestCaseSets();
			setTestCasesSets(testCaseSets);
		};
		fetchTestCases();
	}, []);

	useEffect(() => {
		if (!selectedSteps) return;

		API.generateTestCaseInformation({
			prompt: selectedSteps?.log?.log?.message,
			task: selectedSteps?.log?.log?.message,
		}).then((res) => {
			setName(res.prediction.name);
			setPrompt(res.prediction.prompt);
			setEvaluationMode(selectedSteps?.evaluationMode || res.prediction.evaluation_mode);
			setCorrectOutput(selectedSteps?.output || '');
		});


	}, [selectedSteps]);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);

		const body = {
			siteId: testCaseRun?.testCase?.siteId,
			name,
			prompt,
			startUrl: snapshotSteps[selectedSteps?.startActionIndex]?.before_state?.current_url,
			testCaseSetId,
			correctOutputs: [correctOutput],
			simulatorSessionId: testCaseRun?.sessionId,
			isSingleStep: true,
			singleStepReplayIndex: selectedSteps?.startActionIndex,
			evaluationMode,
		};

		console.log('body', body);

		const res = await API.postTestCase(body);
		console.log('res', res);

		// if (evaluationMode === 'actions') {
		// 	body.startActionIndex = selectedStep?.startActionIndex;
		// }

		setLoading(false);
	};

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog
				sx={{
					p: 2,
					backgroundColor: 'rgba(250, 250, 250, 0.75)',
					backdropFilter: 'blur(4px)',
					width: '400px',
					boxShadow: '0 1px inset var(--joy-palette-third-shadowHighColor)',
				}}
			>
				<LogoIcon width={42} height={42} />
				<Box>
					<Typography level='title-lg'>Generate Step Test Case</Typography>
					<Typography level='body-sm'>
						Generate a step test case for a given test case set.
					</Typography>
				</Box>
				<Form onSubmit={handleSubmit}>
					<Box display='flex' flexDirection='column' gap={0.5}>
						<Typography color='neutral' level='title-sm'>Name</Typography>
						<Input required size='sm' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
					</Box>

					<Box display='flex' flexDirection='column' gap={0.5}>
						<Typography color='neutral' level='title-sm'>Prompt</Typography>
						<Textarea
							size='sm'
							placeholder='Prompt'
							minRows={3}
							value={prompt}
							onChange={(e) => setPrompt(e.target.value)}
							required
						/>
					</Box>


					<Box display='flex' flexDirection='column' gap={0.5}>
						<Typography color='neutral' level='title-sm'>Evaluation Mode</Typography>
						<Select
							size='sm'
							placeholder='Evaluation Mode'
							value={evaluationMode}
							onChange={(e, value) => setEvaluationMode(value)}
						>
							<Option key='actions' value='actions'>Actions</Option>
							<Option key='output' value='output'>Output</Option>
						</Select>
					</Box>

					<Box display='flex' flexDirection='column' gap={0.5}>
						<Typography color='neutral' level='title-sm'>Test Case Set</Typography>
						<Select
							size='sm'
							placeholder='Test Case Set'
							value={testCaseSetId}
							onChange={(e, value) => setTestCaseSetId(value)}
						>
							{testCaseSets.map((testCaseSet) => (
								<Option key={testCaseSet.id} value={testCaseSet.id}>{testCaseSet.name}</Option>
							))}
						</Select>
					</Box>

					<Box display='flex' flexDirection='column' gap={0.5}>
						<Typography color='neutral' level='title-sm'>Replay Session ID</Typography>
						<Input size='sm' placeholder='Session ID' value={testCaseRun?.sessionId} disabled />
					</Box>

					<Box display='flex' flexDirection='column' gap={0.5}>
						<Typography color='neutral' level='title-sm'>Replay Start Index</Typography>
						<Input size='sm' placeholder='Session ID' value={selectedSteps?.startActionIndex} disabled />
					</Box>

					{evaluationMode === 'output' && (
						<Box display='flex' flexDirection='column' gap={0.5}>
							<Typography color='neutral' level='title-sm'>Output</Typography>
							<Textarea
								size='sm'
								placeholder='Correct output'
								minRows={3}
								value={correctOutput}
								onChange={(e) => setCorrectOutput(e.target.value)}
								required
							/>
						</Box>
					)}
					<Box display='flex' justifyContent='flex-end' mt={2}>
						<Button size='sm' type='submit' variant='shadowed' color='secondary' loading={loading}>Generate</Button>
					</Box>
				</Form>

			</ModalDialog>
		</Modal>
	);
};

export default GenerateStepTestCaseModal;
