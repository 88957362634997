import { html_beautify } from 'js-beautify';

export const trimStringEllipses = (str: string, length: number) => {
	if (str.length <= length) {
		return str;
	} else {
		return str.slice(0, length) + '...';
	}
};


function getType(value) {
	if (typeof value === 'string') {
		return 'string';
	} else if (typeof value === 'number') {
		return 'number';
	} else if (typeof value === 'boolean') {
		return 'boolean';
	} else if (value === null) {
		return 'null';
	} else if (Array.isArray(value)) {
		return 'array';
	} else if (typeof value === 'object') {
		return 'object';
	} else {
		return 'unknown';
	}
}

function getNestedField(obj, field) {
	const parts = field.split('.');
	let current = obj;
	for (const part of parts) {
		current = current?.[part];
	}
	return current;
}

export function extractDataStructure(data, rootName = '') {
	const keyToValueTypes = {};

	const processObjectKeys = (obj, prefix = '') => {
		for (const key in obj) {
			const value = obj[key];
			const valueType = getType(value);
			if (valueType === 'object') {
				processObjectKeys(value, `${prefix}.${key}`);
			} else if (valueType === 'array') {
				for (const item of value) {
					processObjectKeys(item, `${prefix}.${key}`);
				}
			} else {
				if (!keyToValueTypes[key]) {
					keyToValueTypes[key] = new Set();
				}
				keyToValueTypes[key].add(valueType);
			}
		}
	};

	processObjectKeys(data);


	const processObject = (obj, prefix = '') => {
		const format = {};
		for (const key in obj) {
			const value = obj[key];
			const valueType = getType(value);
			if (valueType === 'object') {
				const subFormat = processObject(value, `${prefix}.${key}`);
				format[key] = subFormat;
			} else if (valueType === 'array') {
				// todo: account for array of objects
				const subFormat = processObject(value[0], `${prefix}.${key}`);
				format[key] = subFormat;
			} else {
				format[key] = Array.from(keyToValueTypes[key]);
			}
		}
		return format;
	};

	const format = processObject(data);

	return format;
}


export function htmlDecode(input) {
	return input.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
}

export function formatHTML(html) {
	// remove all newlines
	html = html.replace(/\n/g, '');
	html  = htmlDecode(html);
	return html_beautify(html, { 'indent-spaces': 2, eol: '\n' });
}

export function getResponseAsText(response) {
	if (!response) {
		return '';
	}
	const decoder = new TextDecoder('utf-8');
	const text = decoder.decode(new Uint8Array(response.buffer.data));
	return formatHTML(text);
}

export const formatDuration = (duration: moment.Duration) => {
	const hours = duration.hours();
	const minutes = duration.minutes();
	const seconds = duration.seconds();

	return `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds ? `${seconds}s` : ''}`;
};
