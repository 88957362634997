import { Box } from '@mui/joy';
import React, { useEffect, useRef } from 'react';
import rrwebPlayer from 'rrweb-player';

interface SessionReplayerProps {
	sessionId: string;
  events: any;
}

const SessionReplayer: React.FC<SessionReplayerProps> = ({ sessionId, events }: SessionReplayerProps) => {
	const playerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (events.length === 0) return;
		const eventData = events.map(e => e.data).sort((a, b) => a.timestamp - b.timestamp);
		const player1 = new rrwebPlayer({
			target: playerRef.current,
			props: {
				events: eventData,
				autoPlay: false,
				width: 700,
				height: 600,
				useVirtualDom: true,
				mouseTail: false,
			}
		});
		player1.getReplayer().enableInteract();
	}, [events]);

	return (
		<Box>
			<Box ref={playerRef} style={{ flex: 1 }}></Box>
		</Box>
	);
};

export default SessionReplayer;
