import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Divider, Typography } from '@mui/joy';
import API from '@/api';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import ShinpadsBuilder from './ShinpadsBuilder';
import { BuilderContext } from './BuilderContext';
import Events from './Events';
import BrowserIFrame from './BrowserIFrame';
import TrainerSidebar from './TrainerSidebar';
import StartOfflineSessionModal from '@/components/StartOfflineSessionModal';
import OfflineSidebar from './OfflineSidebar';

const PageContainer = styled(Box)`
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	background-color: #fafafa;
	background-image: linear-gradient(to right, #d9d9d9 1px, transparent 1px), linear-gradient(to bottom, #d9d9d9 1px, transparent 1px);
	background-size: 32px 32px;
`;

const PageContentContainer = styled(Box)`
	display: flex;
	// gap: 8px;
	height: 100%;
	// padding: 16px;
	padding-bottom: 0;
`;

const LoadingContainer = styled(Box)<{ loading: boolean }>`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	// background-color: rgba(240, 240, 240, 0.60);
	background-color: #fff;
	// backdrop-filter: blur(4px);
	// border-radius: 5px;
	// border: 1px solid #e4e4e4;
	pointer-events: none;
	// transition: background-color 0.20s ease-in-out, backdrop-filter 0.20s ease-in-out;
`;

const RightContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 8px;
  height: 100%;
  flex-grow: 1;
`;

const OutputContainer = styled(Box)`
	width: 100%;
	flex-basis: 30%;
	display: flex;
	flex-direction: column;
	background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  overflow: hidden;
`;

const OfflineBrowserPage: React.FC = () => {
	const {
		browserSession,
		setBrowserSession,
		activeAction,
		setActiveAction,
		setFrameUrl,
		setIconUrl,
		runningNode,
		selectedWebFlow,
	} = useContext(BuilderContext);
	const iframeRef = useRef<HTMLIFrameElement>(null);
	const [loading, setLoading] = useState(true);
	const [output, setOutput] = useState({});
	const [startOfflineSessionModalOpen, setStartOfflineSessionModalOpen] = useState(false);


	function handleFrameNavigated(event: CustomEvent) {
		const { url, favIconUrl } = event.detail;
		setFrameUrl(url);
		setIconUrl(favIconUrl);
	}

	function handleNodeEnd(event: CustomEvent) {
		console.log('node-end from builder', event.detail);
		const node = selectedWebFlow?.nodes.find(a => a.id === event.detail.nodeId);
		console.log('node that ended', node);
		if (node?.type === 'extract') {
			setOutput({ ...output, ...event.detail.response });
			console.log('output', { ...output, ...event.detail.response });
		}
	}

	useEffect(() => {
		window.addEventListener('node-end', handleNodeEnd);
		return () => {
			window.removeEventListener('node-end', handleNodeEnd);
		};
	}, [runningNode, setOutput, output, selectedWebFlow?.nodes]);


	useEffect(() => {
		const setup = async () => {
			setLoading(true);
			// const res = await API.getBuilderSession();
			// const session = res.browserSession;
			// setBrowserSession(session);
			// setLoading(false);
			// console.log('Session', session);
			console.log('browserSession', browserSession);
			if (!browserSession) {
				setStartOfflineSessionModalOpen(true);
			}
		};

		window.addEventListener(Events.ON_FRAME_NAVIGATED, handleFrameNavigated);

		setup();
		return () => {
			console.log('Unmounting, disconnecting socket');
			window.removeEventListener(Events.ON_FRAME_NAVIGATED, handleFrameNavigated);
		};
	}, []);

	return (
		<PageContainer>
			<StartOfflineSessionModal open={startOfflineSessionModalOpen} onClose={() => setStartOfflineSessionModalOpen(false)} />
			<Helmet>
				<title>Plato Trainer</title>
			</Helmet>
			<PageContentContainer>
				<OfflineSidebar iframeRef={iframeRef} />

				<RightContainer>
					<Box display='flex' justifyContent='center' alignItems='center' flexBasis='100%' bgcolor='#fff'>
						{browserSession ? (
							<BrowserIFrame
								ref={iframeRef}
								browserSessionId={browserSession.publicId}
								isBuilder
							/>
						) : (
							<LoadingContainer loading={loading}>
								{loading ? <CircularProgress color='secondary' /> : <Typography>No browsers available. Please try again in a couple minutes.</Typography>}
							</LoadingContainer>
						)}

					</Box>
					{/* <OutputContainer boxShadow='md'>
						<Box padding={2} paddingY={1}>
							<Typography fontSize='18px' fontWeight='bold' level='title-md'>Output</Typography>
						</Box>
						<Divider />
						<Box p={2} bgcolor='neutral.700' flexGrow={1} sx={{ overflowY: 'auto' }}>
							<SyntaxHighlighter showLineNumbers language='json' style={a11yDark} customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }} wrapLongLines>
								{JSON.stringify(output, null, 2)}
							</SyntaxHighlighter>
						</Box>
					</OutputContainer> */}
				</RightContainer>
			</PageContentContainer>
		</PageContainer>
	);
};


export default OfflineBrowserPage;
