import React, { useContext, useEffect, useRef, useState } from 'react';
import Main from '@/components/Main';
import { Typography, Box, Grid, IconButton, Input, Button, Skeleton, Table, Chip, Divider, ToggleButtonGroup, CircularProgress, AspectRatio, ButtonGroup, ColorPaletteProp, LinearProgress, Slider } from '@mui/joy';
import API from '../api';
import moment from 'moment';
import styled from '@emotion/styled';
import DashboardContainer from '../components/DashboardContainer';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BrowserIFrame from './Builder/BrowserIFrame';
import LogoIcon from '@/icons/LogoIcon';
import ArrowBackIcon from '@/icons/ArrowBackIcon';
import rrwebPlayer from 'rrweb-player';
import { customTheme } from './EvalRunPage';
import { EditorView } from '@codemirror/view';
import CloseIcon from '@/icons/CloseIcon';
import ReactCodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import ReactMarkdown from 'react-markdown';
import SessionReplayer from '../components/SessionReplayer';

const Section = styled(Box)`
  padding: 1rem;
`;

const UsageRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? 'var(--joy-palette-neutral-100)' : 'transparent'};
`;

const PageContainer = styled(Box)`
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	display: flex;
	flex-direction: column;
	background-color: #fafafa;
	// background-image: radial-gradient(circle, #d9d9d9 1px, transparent 1px);
	background-size: 32px 32px;
`;

const PreviewContainer = styled(Box)`
	width: 100%;
	height: 100%;
	// border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 16px;
	overflow: auto;
	background-color: #fafafa;
	background-image: linear-gradient(to right, #d9d9d9 1px, transparent 1px), linear-gradient(to bottom, #d9d9d9 1px, transparent 1px);
	background-size: 32px 32px;
`;

const PageContentContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	// gap: 8px;
	height: 100%;
	// padding: 16px;
	padding-bottom: 0;
	max-width: 100%;
`;

const RightContainer = styled(Box)`
	display: flex;
	flex-direction: row;
  height: 100%;
  flex-grow: 1;
	overflow-y: auto;
`;

const Sidebar = styled(Box)`
  width: 380px;
  height: 100%;
  // background: var(--joy-palette-background-level1);
  background-color: #ffff;
  // border-radius: 5px;
  flex-shrink: 0;
  overflow-y: auto;
  border-right: 1px solid #e4e4e4;
  // border: 1px solid #e4e4e4;
  position: relative;
	display: flex;
	flex-direction: column;
  // box-shadow: 2px 0px 8px #00000029;
`;

const TimelineBar = styled(Box)`
	width: 100%;
	max-width: 100%;
	max-height: 50%;
	// background-color: #fff;
	border-radius: 0px;
	// background: var(--joy-palette-background-level1);
	background-color: #fff;
	border: 1px solid #e4e4e4;
	border-left: none;
	display: flex;
	flex-direction: column;
	padding: 16px;
	// gap: 8px;
`;

const TimelineStepsContainer = styled(Box)`
	display: flex;
	flex-direction: row;
	gap: 8px;
	overflow-x: auto;
`;

const Header = styled(Box)`
	// background-color: rgba(240, 240, 240, 0.70);
	// background: var(--joy-palette-background-level1);
	backdrop-filter: blur(8px);
	// border-bottom: 1px solid #e4e4e4;
  display: flex;
  height: 58px;
  align-items: center;
  gap: 8px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  justify-content: space-between;
`;

const StepsContainer = styled(Box)`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 16px;
`;

const formatDuration = (duration: moment.Duration) => {
	const hours = duration.hours();
	const minutes = duration.minutes();
	const seconds = duration.seconds();

	return `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds ? `${seconds}s` : ''}`;
};

const SessionPage: React.FC = () => {
	const { sessionId } = useParams();
	const [steps, setSteps] = useState([]);
	const [logs, setLogs] = useState([]);
	const [stepsOrderedByTime, setStepsOrderedByTime] = useState([]);
	const [currentStepIndex, setCurrentStepIndex] = useState(0);
	const [session, setSession] = useState(null);
	const [rrwebEvents, setRrwebEvents] = useState([]);
	const [snapshot, setSnapshot] = useState(null);

	const navigate = useNavigate();
	useEffect(() => {
		API.getSession(sessionId).then((res) => {
			console.log('res', res);
			setSession(res.session);
			setRrwebEvents(Object.values(res.rrweb_events));
			setSnapshot(res.snapshot);
		});
	}, []);

	return (
		<DashboardContainer titleBar={{ title: 'Session' }} showTitleBar={false} mainProps={{ style: { padding: 0 } }}>
			<Helmet>
				<title>Plato Session Annotator</title>
			</Helmet>
			<PageContentContainer>
				<Box p={2} py={1.5} display='flex' alignItems='center' gap={2} borderBottom='1px solid #e4e4e4'>
					<IconButton size='sm' variant='outlined' color='neutral' onClick={() => navigate(-1)} sx={{ minWidth: '28px', minHeight: '28px' }}>
						<ArrowBackIcon fill='currentColor' width={12} height={12} />
					</IconButton>
					<Typography level='h4'>Session</Typography>
					<Box display='flex'>
						<Typography level='body-sm' color='neutral' fontWeight='regular'>{sessionId}</Typography>
					</Box>
				</Box>


				<RightContainer>
					<Box width='100%' display='flex' flexDirection='column'>
						<PreviewContainer>
							<SessionReplayer sessionId={sessionId} events={rrwebEvents} />
						</PreviewContainer>
					</Box>
				</RightContainer>
			</PageContentContainer>
		</DashboardContainer>
	);
};

export default SessionPage;
