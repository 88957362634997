import API from '@/api';
import LogoIcon from '@/icons/LogoIcon';
import styled from '@emotion/styled';
import { Box, Button, Input, Modal, ModalDialog, Typography } from '@mui/joy';
import React, { useState } from 'react';

const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const CreateTestCaseModal = ({
    open,
    onClose,
    testCaseSet
}: {
    open: boolean;
    onClose: (created?: boolean) => void;
    testCaseSet: any;
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [startUrl, setStartUrl] = useState<string>('');
    const [prompt, setPrompt] = useState<string>('');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        try {
            await API.createTestCase({
                name,
                startUrl,
                prompt,
                testCaseSetId: testCaseSet.id,
            });
            onClose(true);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    return (
        <Modal open={open} onClose={() => onClose()}>
            <ModalDialog
                sx={{
                    p: 2,
                    backgroundColor: 'rgba(250, 250, 250, 0.75)',
                    backdropFilter: 'blur(4px)',
                    width: '400px',
                    boxShadow: '0 1px inset var(--joy-palette-third-shadowHighColor)',
                }}
            >
                <LogoIcon width={42} height={42} />
                <Box>
                    <Typography level='title-lg'>Create Test Case</Typography>
                    <Typography level='body-sm'>
                        Add a new test case to {testCaseSet?.name}.
                    </Typography>
                </Box>
                <Form onSubmit={handleSubmit}>
                    <Box display='flex' flexDirection='column' gap={0.5}>
                        <Typography level='body-sm'>Name</Typography>
                        <Input
                            size='sm'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            sx={{ boxShadow: 'none', borderRadius: '5px', border: '1px solid #e4e4e4' }}
                            placeholder='Test case name'
                        />
                    </Box>
                    <Box display='flex' flexDirection='column' gap={0.5}>
                        <Typography level='body-sm'>Start URL</Typography>
                        <Input
                            size='sm'
                            value={startUrl}
                            onChange={(e) => setStartUrl(e.target.value)}
                            required
                            sx={{ boxShadow: 'none', borderRadius: '5px', border: '1px solid #e4e4e4' }}
                            placeholder='https://example.com'
                        />
                    </Box>
                    <Box display='flex' flexDirection='column' gap={0.5}>
                        <Typography level='body-sm'>Prompt</Typography>
                        <Input
                            size='sm'
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            required
                            multiline
                            minRows={3}
                            sx={{ boxShadow: 'none', borderRadius: '5px', border: '1px solid #e4e4e4' }}
                            placeholder='Enter the test case prompt'
                        />
                    </Box>
                    <Box display='flex' justifyContent='flex-end' mt={2}>
                        <Button size='sm' type='submit' variant='shadowed' color='secondary' loading={loading}>
                            Create
                        </Button>
                    </Box>
                </Form>
            </ModalDialog>
        </Modal>
    );
};

export default CreateTestCaseModal;