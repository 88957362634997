import BenchmarkIcon from '@/icons/BenchmarkIcon';
import DocumentIcon from '@/icons/DocumentIcon';
import HomeIcon from '@/icons/HomeIcon';
import LogoIcon from '@/icons/LogoIcon';
import SettingsIcon from '@/icons/SettingsIcon';
import TestTubeIcon from '@/icons/TestTubeIcon';
import UsageIcon from '@/icons/UsageIcon';
import styled from '@emotion/styled';
import { Avatar, Box, Divider, List, ListItem, ListItemButton, Typography } from '@mui/joy';
import React, { useContext, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import CrownIcon from '../icons/CrownIcon';
import RobotIcon from '../icons/RobotIcon';


const Container = styled(Box)`
  // width: 100%;
	height: 100%;
  // background-color: #fff;
	background: var(--joy-palette-neutral-50);
	position: relative;
	z-index: 1000;
	width: fit-content;
	// background: var(--joy-palette-background-level1);
  // height: 100vh;
  border-right: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
	padding: 12px;
	padding-top: 12px;
	padding-bottom: 12px;
`;

const ListContainer = styled(Box)`
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const BottomListContainer = styled(Box)`
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const BottomSection = styled(Box)`
  display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	flex-direction: column;
	flex-grow: 1;
`;

const TopNavigation: React.FC = () => {
	const currentPath = useLocation().pathname;
	const { user: { user } } = useContext(AppContext);
	const [isHovered, setIsHovered] = useState(false);

	return (
		<Container onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
			<Box display='flex' pb={2} px='6px' gap={2} justifyContent='flex-start' alignItems='center'>
				<a href='/' style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
					<LogoIcon style={{ borderRadius: '5px' }} width='28px' height='28px' />
					{isHovered && (
						<Typography fontSize='18px' fontWeight='bold' level='title-md'>Plato</Typography>
					)}
				</a>
				{/* <Typography mt={0.5} level='body-xs' color='neutral'>
          v1.1.12
				</Typography> */}
			</Box>
			<ListContainer>
				<List orientation='vertical' sx={{ width: '100%' }}>
					<ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px' }} component={NavLink} to='/' selected={currentPath === '/'}>
							<HomeIcon width='18px' height='18px' fill='currentColor' />
							{isHovered ? 'Dashboard' : ''}
						</ListItemButton>
					</ListItem>
				</List>
			</ListContainer>
			<Divider />

			<ListContainer>

				<List orientation='vertical' sx={{ width: '100%' }}>
					<ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px', whiteSpace: 'nowrap' }} component={NavLink} to='/evals' selected={currentPath.startsWith('/evals')}>
							<UsageIcon width='18px' height='18px' fill='currentColor' />
							{isHovered ? 'Eval Runs' : ''}
						</ListItemButton>
					</ListItem>
					{/* <ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px', whiteSpace: 'nowrap' }} component={NavLink} to='/sessions' selected={currentPath.startsWith('/sessions')}>
							<ChromeIcon width='18px' height='18px' fill='currentColor' />
							{isHovered ? 'Browser Sessions' : ''}
						</ListItemButton>
					</ListItem> */}
				</List>
			</ListContainer>
			<Divider />
			<ListContainer>
				<List orientation='vertical' sx={{ width: '100%' }}>
					<ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px', whiteSpace: 'nowrap' }} component={NavLink} to='/benchmarks' selected={currentPath === '/benchmarks'}>
							<BenchmarkIcon width='18px' height='18px' fill='currentColor' />
							{isHovered ? 'Benchmarks' : ''}
						</ListItemButton>
					</ListItem>
					<ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px', whiteSpace: 'nowrap' }} component={NavLink} to='/tests' selected={currentPath === '/tests'}>
							<TestTubeIcon width='18px' height='18px' fill='currentColor' />
							{isHovered ? 'Tests' : ''}
						</ListItemButton>
					</ListItem>
					<ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px', whiteSpace: 'nowrap' }} component={NavLink} to='/agent-artifacts' selected={currentPath === '/agent-artifacts'}>
							<RobotIcon width='18px' height='18px' fill='currentColor' />
							{isHovered ? 'Agent Artifacts' : ''}
						</ListItemButton>
					</ListItem>
				</List>
			</ListContainer>
			{/* {user?.email?.includes('plato.so') && (
				<>
					<Divider />
					<BottomListContainer>
						<List orientation='vertical' sx={{ width: '100%' }}>
							<ListItem>
								<ListItemButton color='danger' sx={{ fontWeight: 500, fontSize: '16px', whiteSpace: 'nowrap' }} component={NavLink} to='/sites' selected={currentPath === '/sites'}>
									<FlowIcon width='18px' height='18px' fill='currentColor' />
									{isHovered ? 'Sites' : ''}
								</ListItemButton>
							</ListItem>
							<ListItem>
								<ListItemButton color='danger' sx={{ fontWeight: 500, fontSize: '16px', whiteSpace: 'nowrap' }} component={NavLink} to='/site-requests' selected={currentPath === '/site-requests'}>
									<CodeIcon width='18px' height='18px' fill='currentColor' />
									{isHovered ? 'Requests' : ''}
								</ListItemButton>
							</ListItem>
							<ListItem>
								<ListItemButton color='danger' sx={{ fontWeight: 500, fontSize: '16px', whiteSpace: 'nowrap' }} component={NavLink} to='/template-generation' selected={currentPath === '/template-generation'}>
									<TemplateIcon width='18px' height='18px' fill='currentColor' />
									{isHovered ? 'Templates' : ''}
								</ListItemButton>
							</ListItem>
						</List>
					</BottomListContainer>
				</>
			)} */}

			<BottomSection>
				<List orientation='vertical' sx={{ justifyContent: 'flex-end', width: '100%' }}>
					{user?.email?.includes('plato.so') && (
						<ListItem>
							<ListItemButton color='danger' sx={{ fontWeight: 500, fontSize: '16px' }} component={NavLink} to='/admin' selected={currentPath === '/admin'}>
								<CrownIcon width='18px' height='18px' fill='currentColor' />
								{isHovered ? 'Admin' : ''}
							</ListItemButton>
						</ListItem>
					)}
					<ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px' }} component={Link} to='https://docs.plato.so' selected={false}>
							<DocumentIcon width='18px' height='18px' fill='currentColor' />
							{isHovered ? 'Docs' : ''}
						</ListItemButton>
					</ListItem>
					<ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px' }} component={NavLink} to='/settings' selected={currentPath === '/settings'}>
							<SettingsIcon width='18px' height='18px' fill='currentColor' />
							{isHovered ? 'Settings' : ''}
						</ListItemButton>
					</ListItem>
				</List>
				<Box display='flex' gap={2} px='6px' alignItems='center' justifyContent='flex-start' maxWidth='100%' mt={1}>
					<Avatar size='sm' src={user?.imageUrl} />
					{isHovered && (
						<Typography sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} level='body-md'>{user?.email}</Typography>
					)}
				</Box>
			</BottomSection>
		</Container>
	);
};

export default TopNavigation;
