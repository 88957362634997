import axios from 'axios';

let BASE_URL = window.location.origin;
const FASTAPI_URL = 'http://localhost:8000';

if (ENVIRONMENT === 'production' && !BASE_URL.includes('localhost')) {
	if (!BASE_URL.startsWith('https://')) {
		BASE_URL = BASE_URL.replace('http://', 'https://');
	}
}


class API {
	static async getUserFromSession() {
		const user = await axios.get(BASE_URL + '/api/user/from-session');

		return user.data;
	}

	static async viewAsUser(email: string) {
		const res = await axios.post(BASE_URL + '/api/admin/view-as', { email });
		const { data } = res;
		if (!data?.id) {
			console.error('User not found');
			return;
		}
		document.cookie = `view-as=${data.publicId}`;
		window.location.href = '/';
	}

	static async clearViewAs() {
		document.cookie = 'view-as=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		window.location.href = '/';
	}

	static async logout() {
		await axios.post(BASE_URL + '/api/user/logout');
	}


	static async submitContactForm(message: string) {
		await axios.post(`${BASE_URL}/api/user/contact-form`, { message });
	}


	static async getSiteRequests(filters: Record<string, string>) {
		const res = await axios.get(`${BASE_URL}/api/sites/requests`, { params: filters });
		return res.data;
	}

	static async getSiteResponse(options: { originUrl: string, method: string, contentType: string, url: string }) {
		const res = await axios.post(`${BASE_URL}/api/sites/site-response`, options);
		return res.data;
	}

	static async getSiteResponses(options: { originUrl: string, method: string, contentType: string, url: string }) {
		const res = await axios.get(`${BASE_URL}/api/sites/responses`, { params: options });
		return res.data;
	}

	static async getSiteEndpoints(options: { siteId?: string, urlPattern?: string, method?: string }) {
		const res = await axios.get(`${BASE_URL}/api/sites/endpoints`, { params: options });
		return res.data;
	}

	static async createSiteEndpoint(data: any) {
		const res = await axios.post(`${BASE_URL}/api/sites/endpoints`, data);
		return res.data;
	}

	static async updateSiteEndpoint(endpointId: string, data: any) {
		const res = await axios.put(`${BASE_URL}/api/sites/endpoints/${endpointId}`, data);
		return res.data;
	}

	static async getSiteModels(siteId: string) {
		const res = await axios.get(`${BASE_URL}/api/sites/${siteId}/models`);
		return res.data;
	}

	static async postSiteEndpointFromRequest(options: { originUrl: string, method: string, contentType: string, url: string }) {
		const res = await axios.post(`${BASE_URL}/api/sites/site-endpoint-from-request`, options);
		return res.data;
	}

	static async getSites() {
		const res = await axios.get(`${BASE_URL}/api/sites`);
		return res.data;
	}
	static async createSite(data: { name: string, url: string, imgUrl: string }) {
		const res = await axios.post(`${BASE_URL}/api/sites`, data);
		return res.data;
	}

	static async updateSite(siteId: string, data: { name?: string, url?: string, imgUrl?: string, stateSchema?: string, databaseSchema?: string }) {
		const res = await axios.put(`${BASE_URL}/api/sites/${siteId}`, data);
		return res.data;
	}

	static async getSiteOriginUrls() {
		const res = await axios.get(`${BASE_URL}/api/sites/origin-urls`);
		return res.data;
	}

	static async getModelDocuments(modelId: string) {
		const res = await axios.get(`${BASE_URL}/api/sites/models/${modelId}/documents`);
		return res.data;
	}

	static async getTestCases() {
		const res = await axios.get(`${BASE_URL}/api/testcases`);
		return res.data;
	}

	static async getTestCaseSets() {
		const res = await axios.get(`${BASE_URL}/api/testcases/sets`);
		return res.data;
	}

	static async createTestCase(data: { name: string, startUrl: string, prompt: string, testCaseSetId: number }) {
		const res = await axios.post(`${BASE_URL}/api/testcases`, {
			name: data.name,
			startUrl: data.startUrl,
			prompt: data.prompt,
			testCaseSetId: data.testCaseSetId,
			siteId: 1, // Default site ID
			correctOutputs: [], // Empty array as default
			simulatorSessionId: '', // Empty string as default
			isSingleStep: false, // Default to false
			singleStepReplayIndex: 0, // Default to 0
			evaluationMode: 'actions' // Default to output mode
		});
		return res.data;
	}

	static async deleteTestCaseSet(setId: string) {
		const res = await axios.delete(`${BASE_URL}/api/testcases/sets/${setId}`);
		return res.data;
	}

	static async createTestCaseSet(data: { name: string, description: string, icon: string }) {
		const res = await axios.post(`${BASE_URL}/api/testcases/sets`, data);
		return res.data;
	}

	static async getMyEvalRuns() {
		const res = await axios.get(`${BASE_URL}/api/evals`);
		return res.data;
	}

	static async archiveEval(evalRunId: string) {
		const res = await axios.delete(`${BASE_URL}/api/evals/${evalRunId}`);
		return res.data;
	}

	static async enqueueEvalTestCaseRun(evalRunId: string, testCaseId: number) {
		const res = await axios.post(`${BASE_URL}/api/evals/${evalRunId}/test-case/${testCaseId}/enqueue`);
		return res.data;
	}

	static async enqueuePendingEvalTestCaseRuns(evalRunId: string) {
		const res = await axios.post(`${BASE_URL}/api/evals/${evalRunId}/enqueue-pending`);
		return res.data;
	}

	static async getEvalRun(evalRunId: string) {
		const res = await axios.get(`${BASE_URL}/api/evals/${evalRunId}`);
		return res.data;
	}

	static async runEval(testCaseSetId: number, options: { environment: string, queueImmediately: boolean, runsPerTestCase: number, name: string }) {
		const res = await axios.post(`${BASE_URL}/api/evals`, { testCaseSetId, ...options });
		return res.data;
	}

	static async getRRWebEvents(testCaseRunId: string) {
		const res = await axios.get(`${BASE_URL}/api/evals/test-case-run/${testCaseRunId}/rrweb-events`);
		return res.data;
	}

	static async getEvalTestCaseRunSnapshot(testCaseRunId: string) {
		const res = await axios.get(`${BASE_URL}/api/evals/test-case-run/${testCaseRunId}/snapshot`);
		return res.data;
	}

	static async getEvalTestCaseRunSession(testCaseRunId: string) {
		const res = await axios.get(`${BASE_URL}/api/evals/test-case-run/${testCaseRunId}/session`);
		return res.data;
	}

	static async getEvalTestCaseRun(testCaseRunId: string) {
		const res = await axios.get(`${BASE_URL}/api/evals/test-case-run/${testCaseRunId}`);
		return res.data;
	}

	static async renderTemplate(template: string, data: any) {
		const res = await axios.post(`${BASE_URL}/api/template/render`, { template, data });
		return res.data;
	}


	static async updateTestCase(id: string, data: { prompt: string, correctOutputs: any }) {
		const res = await axios.put(`${BASE_URL}/api/testcases/${id}`, data);
		return res.data;
	}

	static async postTestCase(data: any) {
		const res = await axios.post(`${BASE_URL}/api/testcases`, data);
		return res.data;
	}

	static async generateTestCaseInformation(data: any) {
		const res = await axios.post(`${BASE_URL}/api/testcases/generate-prediction`, data);
		return res.data;
	}

	static async scoreEvalTestCaseRun(testCaseRunId: string, score: number, annotatorReasoning?: string) {
		const res = await axios.post(`${BASE_URL}/api/evals/test-case-run/${testCaseRunId}/score`, { score, annotatorReasoning });
		return res.data;
	}

	static async getSessions() {
		const res = await axios.get(`${BASE_URL}/api/session/`);
		return res.data;
	}

	static async getSession(sessionId: string) {
		const res = await axios.get(`${BASE_URL}/api/session/${sessionId}`);
		return res.data;
	}

	static async getSessionNetworkRequests(sessionId: string) {
		const res = await axios.get(`${BASE_URL}/api/session/${sessionId}/network-requests`);
		return res.data;
	}

	static async getHTMLStructuredInputs(html: string, schema: string) {
		const res = await axios.post(`${FASTAPI_URL}/run`, {
			agent: {
				__target__: 'agents.html_structured_input_extractor.html_structured_input_extractor',
				llm: {
					model: 'gpt-4o',
					provider: 'portkey',
					config: 'test',
					temperature: 0.2,
					max_tokens: 250
				}
			},
			experiment: {
				name: 'lfd'
			},
			inputs: {
				html: html,
				schema: schema
			},
		},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			});

		return res.data.result;
	}

	static async generateTemplate(samples: { html: string }[], schema: string, fullHTML: string, generationInstruction) {
		const res = await axios.post(`${FASTAPI_URL}/run`, {
			agent: {
				__target__: 'agents.template_generator.template_generator',
				llm: {
					model: 'gpt-4o',
					provider: 'portkey',
					temperature: 0.2,
					max_tokens: 1000
				}
			},
			experiment: {
				name: 'lfd'
			},
			inputs: {
				samples: samples,
				schema: schema,
				original_full_html: fullHTML,
				generation_instruction: generationInstruction
			},
		},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			});

		return res.data.result;
	}

	static async getAgentArtifacts() {
		const res = await axios.get(`${BASE_URL}/api/agent-artifacts/`);
		return res.data;
	}

	static async createAgentArtifact(data: {
		alias: string;
		type: 'human' | 'dev' | 'git_container';
		description: string;
		imgUrl?: string;
		humanName?: string;
		endpoint?: string;
		repoUrl?: string;
		branch?: string;
		commitHash?: string;
	}) {
		const res = await axios.post(`${BASE_URL}/api/agent-artifacts/`, data);
		return res.data;
	}

	static async archiveAgentArtifact(artifactId: string) {
		const res = await axios.delete(`${BASE_URL}/api/agent-artifacts/${artifactId}`);
		return res.data;
	}
}

export default API;
