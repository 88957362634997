import React, { useContext, useState } from 'react';
import { Box, Button, FormLabel, Input, Modal, ModalDialog, Textarea, Typography } from '@mui/joy';
import LogoIcon from '@/icons/LogoIcon';
import ParameterInput from '@/pages/Builder/components/ParameterInputV2';
import SchemaInput, { JsonSchema } from '@/pages/Builder/components/SchemaInput';
import styled from '@emotion/styled';
import WebIcon from '@/icons/WebIcon';
import API from '@/api';
import { BuilderContext } from '@/pages/Builder/BuilderContext';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '@/context/AppContext';


const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const StartTrainerSessionModal = ({ open, onClose }: { open: boolean, onClose: () => void }) => {
	const navigate = useNavigate();
	const { setBrowserSession } = useContext(BuilderContext);
	const [loading, setLoading] = useState<boolean>(false);
	// const [responseFormat, setResponseFormat] = useState<JsonSchema>({ type: 'object', title: 'response', properties: {
	// 	companies: { type: 'array', items: { type: 'object', properties: {
	// 		name: { type: 'string' },
	// 		description: { type: 'string' },
	// 		location: { type: 'string' },
	// 		img_url: { type: 'string' },
	// 		tags: { type: 'array', items: { type: 'string' } },
	// 	} } },
	// } });
	const [responseFormat, setResponseFormat] = useState<JsonSchema>({ type: 'object', title: 'response', properties: {
		options: { type: 'array', items: { type: 'object', properties: {
			name: { type: 'string' },
			price: { type: 'number' },
			quantity: { type: 'number' },
			flavour: { type: 'string' },
			imgUrl: { type: 'string' },
		} } },
	} });

	const [startUrl, setStartUrl] = useState<string>('https://amazon.com');
	const [task, setTask] = useState<string>('Get available soylent flavours and sizes');

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		const response = await API.startTrainerSession({ startUrl, task, responseFormat });
		console.log('session', response);
		setBrowserSession(response.browserSession);
		navigate('/trainer');
		setLoading(false);
		onClose();
	};

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog
				sx={{
					p: 2,
					backgroundColor: 'rgba(250, 250, 250, 0.75)',
					backdropFilter: 'blur(4px)',
					width: '400px',
					boxShadow: '0 1px inset var(--joy-palette-third-shadowHighColor)',
				}}
			>
				<LogoIcon width={42} height={42} />
				<Box>
					<Typography level='title-lg'>Start Test Session</Typography>
					<Typography level='body-sm'>
					Provide a URL to start a test session.
					</Typography>
				</Box>
				<Form onSubmit={handleSubmit}>
					<Input
						startDecorator={<WebIcon width={12} height={12} />}
						size='sm'
						value={startUrl}
						onChange={(e) => setStartUrl(e.target.value)}
						required
						type='url'
						sx={{ boxShadow: 'none', borderRadius: '5px', border: '1px solid #e4e4e4' }}
						placeholder='https://example.com'
					/>
					<Textarea size='sm' value={task} onChange={(e) => setTask(e.target.value)} required sx={{ boxShadow: 'none', borderRadius: '5px', border: '1px solid #e4e4e4' }} minRows={3} placeholder='Task description' />
					<SchemaInput value={responseFormat} onChange={setResponseFormat} />
					<Box display='flex' justifyContent='flex-end'>
						<Button size='sm' type='submit' variant='shadowed' color='secondary' loading={loading}>Start</Button>
					</Box>
				</Form>

			</ModalDialog>
		</Modal>
	);
};

export default StartTrainerSessionModal;
