import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Main from '@/components/Main';
import { Typography, Box, Grid, IconButton, Input, Button, Skeleton, Table, Chip, Divider, ToggleButtonGroup, CircularProgress, AspectRatio, ButtonGroup, ColorPaletteProp, LinearProgress, Slider, Switch, Tooltip } from '@mui/joy';
import API from '../api';
import moment from 'moment';
import styled from '@emotion/styled';
import DashboardContainer from '../components/DashboardContainer';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BrowserIFrame from './Builder/BrowserIFrame';
import LogoIcon from '@/icons/LogoIcon';
import ArrowBackIcon from '@/icons/ArrowBackIcon';
import rrwebPlayer from 'rrweb-player';
import { customTheme } from './EvalRunPage';
import { EditorView } from '@codemirror/view';
import CloseIcon from '@/icons/CloseIcon';
import ReactCodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import ReactMarkdown from 'react-markdown';
import SessionReplayer from '../components/SessionReplayer';
import GenerateStepTestCaseModal from '../components/CreateStepTestCaseModal';
import { Collapse, Fade } from '@mui/material';
import ReloadIcon from '../icons/ReloadIcon';
import { AppContext } from '../context/AppContext';

const Section = styled(Box)`
  padding: 1rem;
`;

const UsageRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? 'var(--joy-palette-neutral-100)' : 'transparent'};
`;

const PageContainer = styled(Box)`
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	display: flex;
	flex-direction: column;
	background-color: #fafafa;
	// background-image: radial-gradient(circle, #d9d9d9 1px, transparent 1px);
	background-size: 32px 32px;
`;

const PreviewContainer = styled(Box)`
	width: 100%;
	flex-grow: 1;
	// border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 16px;
	overflow: auto;
	background-color: #fafafa;
	background-image: linear-gradient(to right, #d9d9d9 1px, transparent 1px), linear-gradient(to bottom, #d9d9d9 1px, transparent 1px);
	background-size: 32px 32px;
`;

const PageContentContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	// gap: 8px;
	height: 100%;
	// padding: 16px;
	padding-bottom: 0;
	max-width: 100%;
`;

const RightContainer = styled(Box)`
	display: flex;
	flex-direction: row;
  height: 100%;
  flex-grow: 1;
	overflow-y: auto;
`;

const Sidebar = styled(Box)`
  width: 380px;
  height: 100%;
  // background: var(--joy-palette-background-level1);
  background-color: #ffff;
  // border-radius: 5px;
  flex-shrink: 0;
  overflow-y: auto;
  border-right: 1px solid #e4e4e4;
  // border: 1px solid #e4e4e4;
  position: relative;
	display: flex;
	flex-direction: column;
  // box-shadow: 2px 0px 8px #00000029;
`;

const TimelineBar = styled(Box)`
	width: 100%;
	max-width: 100%;
	// max-height: 50%;
	// background-color: #fff;
	border-radius: 0px;
	// background: var(--joy-palette-background-level1);
	background-color: #fff;
	border: 1px solid #e4e4e4;
	border-left: none;
	display: flex;
	flex-direction: column;
	padding: 16px;
	// gap: 8px;
`;

const TimelineStepsContainer = styled(Box)`
	display: flex;
	flex-direction: row;
	gap: 8px;
	overflow-x: auto;
`;

const Header = styled(Box)`
	// background-color: rgba(240, 240, 240, 0.70);
	// background: var(--joy-palette-background-level1);
	backdrop-filter: blur(8px);
	// border-bottom: 1px solid #e4e4e4;
  display: flex;
  height: 58px;
  align-items: center;
  gap: 8px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  justify-content: space-between;
`;

const StepsContainer = styled(Box)`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 16px;
`;

const formatDuration = (duration: moment.Duration) => {
	const hours = duration.hours();
	const minutes = duration.minutes();
	const seconds = duration.seconds();

	return `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds ? `${seconds}s` : ''}`;
};


export const getStatus = (evalRun: any) => {
	if (evalRun?.endedAt) {
		return {
			text: 'Done',
			color: 'success'
		};
	}
	if (evalRun?.startedAt) {
		return {
			text: 'Running',
			color: 'primary'
		};
	}
	return {
		text: 'Pending',
		color: 'neutral'
	};
};


const EvalTestPage: React.FC = () => {
	const { evalTestId } = useParams();
	const [testCaseRun, setTestCaseRun] = useState(null);
	const [steps, setSteps] = useState([]);
	const [logs, setLogs] = useState([]);
	const [stepsOrderedByTime, setStepsOrderedByTime] = useState([]);
	const [currentStepIndex, setCurrentStepIndex] = useState(0);
	const [rrwebEvents, setRrwebEvents] = useState([]);
	const [snapshotSteps, setSnapshotSteps] = useState([]);
	const [isRRWebMode, setIsRRWebMode] = useState(false);
	const [selectedSteps, setSelectedSteps] = useState(null);
	const [score, setScore] = useState(null);
	const [annotatorReasoning, setAnnotatorReasoning] = useState(null);
	const [isSavingScore, setIsSavingScore] = useState(false);
	const [sessionLoading, setSessionLoading] = useState(true);

	const navigate = useNavigate();
	useEffect(() => {
		API.getEvalTestCaseRun(evalTestId).then((testCaseRun) => {
			console.log('testCaseRun', testCaseRun);
			setTestCaseRun(testCaseRun);
			setScore(testCaseRun?.score);
			setAnnotatorReasoning(testCaseRun?.annotatorReasoning);
		});
		// API.getRRWebEvents(evalTestId).then((events) => {
		// 	console.log('events', events);
		// 	setRrwebEvents(events);
		// });
		// API.getEvalTestCaseRunSnapshot(evalTestId).then((snapshot) => {
		// 	console.log('snapshot', snapshot);
		// 	if (snapshot) {
		// 		setSnapshotSteps(snapshot.snapshots);
		// 	}
		// });

		setSessionLoading(true);
		API.getEvalTestCaseRunSession(evalTestId).then((session) => {
			const { rrweb_events, snapshot, logs } = session;
			console.log('rrweb_events', rrweb_events);
			console.log('snapshot', snapshot);
			const sortedLogs = logs.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)));
			console.log('logs', sortedLogs);
			setRrwebEvents(Object.values(rrweb_events));
			setSnapshotSteps(snapshot?.snapshots || []);
			setLogs(sortedLogs);
			if (!snapshot?.snapshots?.length && Object.values(rrweb_events).length) {
				setIsRRWebMode(true);
			}
			setSessionLoading(false);
		});
	}, []);

	const handleSaveScore = async (e: React.FormEvent<HTMLFormElement>) => {
		setIsSavingScore(true);
		e.preventDefault();
		await API.scoreEvalTestCaseRun(evalTestId, score, annotatorReasoning);
		setIsSavingScore(false);
	};

	const numActions = snapshotSteps.length * 2;
	// const currentStep = snapshotSteps[currentStepIndex];
	// console.log('currentStep', currentStep);

	const [currentState, currentAction, currentLog] = useMemo(() => {
		const stepIndex = Math.floor(currentStepIndex / 2);
		const currentStep = snapshotSteps[stepIndex];
		if (!currentStep) {
			return [null, null, null];
		}

		// find latest log with log.timestamp before the currentStep.action.timestamp
		const currentLog = logs
			.filter(log => moment(log.createdAt).isBefore(moment(currentStep.action.timestamp)))
			.reduce((latest, current) =>
				!latest || moment(current.createdAt).isAfter(moment(latest.createdAt)) ? current : latest,
			null);

		if (currentStepIndex % 2 === 0) {
			const bbox = currentStep.before_state.bboxes[currentStep.action?.id];
			console.log('bbox', bbox);
			return [currentStep.before_state, currentStep.action, currentLog];
		}
		return [currentStep.after_state, currentStep.action, currentLog];
	}, [currentStepIndex, snapshotSteps]);

	const logsByActionIndex = useMemo(() => {
		const result = [];

		console.log('snapshotSteps', snapshotSteps.map(step => step.action));

		// Go through each snapshot step
		snapshotSteps.forEach((step, stepIndex) => {
			// Each step has two indices (before and after state)


			const mostRecentLog = logs
				.filter(log => moment(log.createdAt).isBefore(moment(step.action.timestamp)))
				.reduce((latest, current) =>
					!latest || moment(current.createdAt).isAfter(moment(latest.createdAt)) ? current : latest,
				null);

			if (mostRecentLog) {
				// Check if this is the same log as the previous entry
				const lastEntry = result[result.length - 1];
				if (lastEntry && lastEntry.log.id === mostRecentLog.id) {
					// Update the action index of the existing entry
					lastEntry.actionIndex = (stepIndex + 1) * 2;
				} else {
					result.push({
						actionIndex: (stepIndex + 1) * 2,
						log: mostRecentLog
					});
				}
			}
		});

		return result;
	}, [logs, snapshotSteps]);

	console.log('logsByActionIndex', logsByActionIndex);


	console.log('currentAction', currentAction);
	console.log('currentState', currentState);
	console.log('currentLog', currentLog);

	return (
		<DashboardContainer titleBar={{ title: 'Session' }} showTitleBar={false} mainProps={{ style: { padding: 0 } }}>
			<Helmet>
				<title>Plato Session - {testCaseRun?.testCase?.name || ''}</title>
			</Helmet>
			<GenerateStepTestCaseModal open={Boolean(selectedSteps)} onClose={() => setSelectedSteps(null)} testCaseRun={testCaseRun} selectedSteps={selectedSteps} snapshotSteps={snapshotSteps} />
			<PageContentContainer>
				{/* <Sidebar>
					<Header p={2} pr={1}>
						<Box display='flex' alignItems='center' gap={1}>
							<NavLink style={{ display: 'flex' }} to='/'>
								<LogoIcon style={{ borderRadius: '4px' }} width={24} height={24} />
							</NavLink>
							<NavLink to='/'>
								<Typography fontSize='18px' fontWeight='bold' level='title-md'>Plato</Typography>
							</NavLink>
						</Box>
						<Box display='flex' flexDirection='row' gap={1}>
							<Button variant='shadowed' color='third' onClick={() => navigate(-1)}>Back</Button>
						</Box>
					</Header>
					<StepsContainer>
						<Box display='flex' flexDirection='row' gap={1}>
							<Typography level='title-md'>{mainAction?.type}</Typography>
							<Typography level='title-md'>{JSON.stringify(mainAction?.data)}</Typography>
						</Box>
						{steps[currentStep]?.browserUpdates?.map((update, index) => (
							<Box key={index} width='150px'>
								<Typography level='title-sm'>{update.type}</Typography>
							</Box>
						))}
					</StepsContainer>
				</Sidebar> */}
				<Box p={2} py={1.5} display='flex' alignItems='center' gap={2} borderBottom='1px solid #e4e4e4'>
					<IconButton size='sm' variant='outlined' color='neutral' onClick={() => navigate(-1)} sx={{ minWidth: '28px', minHeight: '28px' }}>
						<ArrowBackIcon fill='currentColor' width={12} height={12} />
					</IconButton>
					<Typography level='h4'>Test Case Run</Typography>
					<Box display='flex'>
						<Typography level='body-sm' color='neutral' fontWeight='regular'>{evalTestId}</Typography>
					</Box>
				</Box>


				<RightContainer>
					<Box width='100%' display='flex' flexDirection='column'>
						<PreviewContainer>
							{sessionLoading && (
								<CircularProgress />
							)}

							{!sessionLoading && (
								<>
									{isRRWebMode ? (
										<SessionReplayer sessionId={evalTestId} events={rrwebEvents} />
									) : (
										<Box width='100%' display='flex' flexDirection='column' gap={1}  boxShadow='md' borderRadius={5} overflow='hidden' bgcolor='white'>
											<Box width='100%' display='flex' p={1} borderBottom='1px solid #e4e4e4'>
												<Input
													size='sm'
													value={currentState?.current_url}
													fullWidth
													sx={{ boxShadow: 'none' }}
													readOnly
												// endDecorator={
												// 	<Button variant='soft' size='sm' color='neutral' onClick={() => window.open(`/api/browser/session/${sessionId}/recording-step/${currentStep?.id}/page.mhtml`, '_blank')}>
												// 		MHTML
												// 	</Button>
												// }
												/>
											</Box>
											<Box sx={{ overflowY: 'auto' }}>
												{currentState && (
												// <Box key={currentStep?.id} component='img' src={currentStep?.thumbnail || `/api/browser/session/${sessionId}/recording-step/${currentStep?.id}/page.png`} alt={`Step ${currentStepIndex + 1}`} style={{ width: '100%', objectFit: 'contain' }} />
													<Box key={currentState?.id} component='img' src={`data:image/png;base64,${currentState?.screenshot_base64}`} alt={`Step ${currentStepIndex + 1}`} style={{ width: '100%', objectFit: 'contain' }} />
												)}
											</Box>
										</Box>
									)}
									{/* <div style={{ width: '100%', height: '100%', backgroundImage: `url(${steps[currentStep]?.thumbnail})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} /> */}
									{/* <div ref={playerRef} id='rrweb-player1' style={{ flex: 1 }}/> */}
								</>
							)}

						</PreviewContainer>

						<Collapse in={!sessionLoading}>
							<TimelineBar>
								<Box display='flex' flexDirection='row' alignItems='center' justifyContent='flex-end'>
									<Typography component='label' level='title-sm' endDecorator={<Switch checked={isRRWebMode} onChange={() => setIsRRWebMode(!isRRWebMode)} />}>Live Replay</Typography>
								</Box>
								{currentAction && (
									<>
										{/* <Box display='flex' flexDirection='row' gap={1}>
										<Tooltip title={JSON.stringify(currentAction, null, 2)}>
											<Chip size='sm' variant='soft' color='primary'>{currentAction?.type?.toUpperCase() || 'action'}</Chip>
										</Tooltip>
										<Chip size='sm' variant='soft' color='neutral'>{currentStepIndex % 2 === 0 ? 'Before' : 'After'}</Chip>
									</Box> */}
										{/* <ReactCodeMirror
										value={JSON.stringify(currentAction, null, 2)}
										readOnly
										editable={false}
										style={{ fontSize: '12px' }}
										extensions={[json(), EditorView.lineWrapping, customTheme]}
									/> */}
									</>
								)}

								{/* {currentLog?.log && (
								<Box borderRadius='5px' border='1px solid #e4e4e4' bgcolor='var(--joy-palette-neutral-100)' overflow='hidden' sx={{ overflowY: 'auto', marginTop: '8px' }}>
									<Box px='4px' sx={{ fontSize: '12px', '& p': { marginBlockStart: '4px', marginBlockEnd: '4px' } }}>
										<ReactMarkdown>{currentLog?.log?.message}</ReactMarkdown>
									</Box>
								</Box>
							)} */}
								{/* {currentStep?.log && (
								<Chip size='sm' variant='soft' color='primary' sx={{ marginBottom: '8px' }}>{currentStep?.log?.type || 'log'}</Chip>
							)}
							<Box borderRadius='5px' border='1px solid #e4e4e4' bgcolor='var(--joy-palette-neutral-100)' overflow='hidden' sx={{ overflowY: 'auto' }}>
								{currentStep?.log ? (
									<Box px='4px' sx={{ fontSize: '12px', '& p': { marginBlockStart: '4px', marginBlockEnd: '4px' } }}>
										<ReactMarkdown>{currentStep?.log?.message}</ReactMarkdown>
									</Box>
								) : (
									<ReactCodeMirror
										value={JSON.stringify(currentStep?.browserUpdates, null, 2)}
										readOnly
										editable={false}
										style={{ fontSize: '12px' }}
										extensions={[json(), EditorView.lineWrapping, customTheme]}
									/>
								)}
							</Box> */}
								{/* <LinearProgress determinate value={currentStep / steps.length * 100} color='secondary' /> */}
								<Box display='grid' gridTemplateColumns={`repeat(${numActions - 1}, 1fr)`} gap={0} marginTop={2}>
									{logsByActionIndex.map((log, index) => (
										<Tooltip size='sm' placement='top' title={JSON.stringify(log?.log?.log?.message, null, 2)} key={index}>
											<Box
												key={index}
												bgcolor={currentLog?.id === log?.log?.id ? 'var(--joy-palette-primary-500)' : 'var(--joy-palette-primary-400)'}
												onClick={() => setSelectedSteps({ log: log?.log, startActionIndex: (logsByActionIndex[index - 1]?.actionIndex || 0) / 2, actionEndIndex: log.actionIndex / 2 })}
												sx={{
													// width: `${((Math.min(log.actionIndex, numActions - 1) - (logsByActionIndex[index - 1]?.actionIndex || 0)) / (numActions - 1)) * 100}%`,
													gridColumnStart: (logsByActionIndex[index - 1]?.actionIndex || 0) + 1,
													gridColumnEnd: Math.min(log.actionIndex, numActions - 1) + 1,
													padding: '6px',
													borderRadius: '4px',
													flexShrink: 0,
													marginRight: index === logsByActionIndex.length - 1 ? '0px' : '2px',
													marginLeft: index === 0 ? '0px' : '2px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													overflow: 'hidden',
													cursor: 'pointer',
												}}
											>
												<Typography sx={{ color: 'white', maxWidth: '100%', maxHeight: '100%' }} fontSize='11px' textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden' textAlign='left' level='body-xs'>{JSON.stringify(log?.log?.log?.message, null, 2)}</Typography>
											</Box>
										</Tooltip>
									))}
								</Box>
								<Slider
									marks={Array.from({ length: numActions }, (_, i) => i % 2 === 1 ? { value: i, label: snapshotSteps[(i - 1) / 2]?.action?.type } : false).filter(Boolean) as { value: number, label: string }[]}
									value={currentStepIndex}
									min={0}
									max={numActions - 1}
									step={1}
									onChange={(_, value) => setCurrentStepIndex(value as number)}
									color='secondary'
									sx={{ marginBottom: '28px', paddingTop: '4px' }}
								/>
								<Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
									<Box display='flex' flexDirection='row' alignItems='center' gap={1}>
										<Typography level='title-sm'>{currentStepIndex} / {Math.max(numActions - 1, 0)}</Typography>
										<Typography
											component='a'
											level='title-sm'
											fontWeight='bold'
											color='primary'
											sx={{ cursor: 'pointer' }}
											onClick={() => setSelectedSteps({ log: currentLog, startActionIndex: Math.floor(currentStepIndex / 2), actionEndIndex: Math.floor(currentStepIndex / 2) + 1, evaluationMode: 'output', output: testCaseRun?.output })}
										>
										Create test
										</Typography>
									</Box>
									<Box display='flex' flexDirection='row' alignItems='center' gap={1}>
										<ButtonGroup>
											<Button disabled={currentStepIndex === 0} variant='outlined' color='neutral' onClick={() => setCurrentStepIndex(currentStepIndex - 1)}>Previous</Button>
											<Button disabled={currentStepIndex >= (numActions - 1)} variant='outlined' color='neutral' onClick={() => setCurrentStepIndex(currentStepIndex + 1)}>Next</Button>
										</ButtonGroup>
									</Box>
								</Box>
								{/* <TimelineStepsContainer>
							{steps.map((step) => (
								<Box key={step.id} width='150px'>
									<Typography level='title-sm'>{step.stepNumber}</Typography>
									<img style={{ width: '100%', height: 'auto' }} src={step.thumbnail} alt={`Step ${step.stepNumber}`} />
								</Box>
							))}
						</TimelineStepsContainer> */}
							</TimelineBar>
						</Collapse>
					</Box>

					<Box sx={{ borderLeft: '1px solid #e4e4e4', minWidth: '450px', maxWidth: '450px', maxHeight: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
						{/* <Section display='flex' justifyContent='space-between' alignItems='flex-start'>
							<Typography level='body-xs'>{selectedSession?.publicId}</Typography>
							<Box display='flex' justifyContent='flex-end' gap={1}>
								<NavLink to={`/sessions/${selectedSession?.publicId}`}>
									<Button size='sm' variant='shadowed' color='third'>Annotate</Button>
								</NavLink>
								<IconButton size='sm' variant='outlined' color='neutral' onClick={() => setSelectedSession(null)} sx={{ minWidth: '28px', minHeight: '28px' }}>
									<CloseIcon fill='currentColor' width={10} height={10} />
								</IconButton>
							</Box>
						</Section> */}
						<Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' gap={2} p={2} py={1} borderBottom='1px solid #e4e4e4'>
							<Typography level='body-xs'>{testCaseRun?.publicId}</Typography>
							<Box display='flex' flexDirection='row' alignItems='center' gap={1}>
								<IconButton size='sm' variant='outlined' color='neutral' onClick={() => API.enqueueEvalTestCaseRun(testCaseRun.publicId, testCaseRun.testCase.id)} sx={{ minWidth: '28px', minHeight: '28px' }}>
									<ReloadIcon fill='currentColor' width={10} height={10} />
								</IconButton>
								{/* <IconButton size='sm' variant='outlined' color='neutral' onClick={() => setBrowserSession(null)} sx={{ minWidth: '28px', minHeight: '28px' }}>
									<CloseIcon fill='currentColor' width={10} height={10} />
								</IconButton> */}
							</Box>
						</Box>
						<Section>
							<Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='flex-start' rowGap={1} columnGap={2} margin={0}>
								<Typography color='neutral' level='title-sm'>Type</Typography>
								<Typography level='body-sm'>{testCaseRun?.eval?.options?.environment}</Typography>
								<Typography color='neutral' level='title-sm'>Status</Typography>
								<Chip size='sm' variant='soft' color={getStatus(testCaseRun).color as ColorPaletteProp}>{getStatus(testCaseRun).text}</Chip>
								<Typography color='neutral' level='title-sm' whiteSpace='nowrap'>Started At</Typography>
								{testCaseRun?.startedAt ? (
									<Typography level='body-sm'>{moment(testCaseRun?.startedAt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
								) : (
									<Typography level='body-sm'>-</Typography>
								)}
								<Typography color='neutral' level='title-sm' whiteSpace='nowrap'>Ended At</Typography>
								{testCaseRun?.endedAt ? (
									<Typography level='body-sm'>{moment(testCaseRun?.endedAt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
								) : (
									<Typography level='body-sm'>-</Typography>
								)}
								<Typography color='neutral' level='title-sm'>Duration</Typography>
								{testCaseRun?.startedAt && testCaseRun?.endedAt ? (
									<Typography level='body-sm'>{formatDuration(moment.duration(moment(testCaseRun?.endedAt).diff(moment(testCaseRun?.startedAt)))) || '0s'}</Typography>
								) : (
									<Typography level='body-sm'>-</Typography>
								)}
								<Typography color='neutral' level='title-sm' sx={{ whiteSpace: 'nowrap' }}>Session ID</Typography>
								<Typography level='body-sm'>{testCaseRun?.sessionId}</Typography>
							</Box>
						</Section>
						<Divider/>

						<Section>
							<Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='flex-start' rowGap={1} columnGap={2} margin={0} overflow='hidden'>
								<Typography sx={{ whiteSpace: 'nowrap' }} color='neutral' level='title-sm'>Testcase</Typography>
								<Typography level='body-sm'>{testCaseRun?.testCase?.name}</Typography>

								<Typography color='neutral' whiteSpace='nowrap' level='title-sm'>Start URL</Typography>
								<Typography level='body-sm' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>{testCaseRun?.testCase?.startUrl}</Typography>

								<Typography color='neutral' whiteSpace='nowrap' level='title-sm'>Prompt</Typography>
								<Typography level='body-sm' overflow='hidden' whiteSpace='wrap'>{testCaseRun?.testCase?.prompt}</Typography>

								<Typography color='neutral' whiteSpace='nowrap' level='title-sm'>Evaluation Mode</Typography>
								<Typography level='body-sm'>{testCaseRun?.testCase?.evaluationMode}</Typography>

							</Box>
						</Section>
						<Divider/>
						<Section>
							<form onSubmit={handleSaveScore}>
								<Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='flex-start' rowGap={1} columnGap={2} margin={0}>
									<Typography color='neutral' whiteSpace='nowrap' level='title-sm'>Score</Typography>
									{/* <Typography level='body-sm'>{testCaseRun?.score}</Typography> */}
									<Input type='number' required sx={{ minHeight: '28px', boxShadow: 'none' }} size='sm' value={score} variant='outlined' onChange={(e) => setScore(e.target.value)} />
									{(!score || score < 1) && (
										<>
											<Typography color='neutral' whiteSpace='nowrap' level='title-sm'>Score Reason</Typography>
											<Input required sx={{ minHeight: '28px', boxShadow: 'none' }} size='sm' value={annotatorReasoning} variant='outlined' onChange={(e) => setAnnotatorReasoning(e.target.value)} />
										</>
									)}
								</Box>
								<Box display='flex' flexDirection='row' alignItems='center' justifyContent='flex-end' gap={1} marginTop={1}>
									<Button type='submit' size='sm' variant='outlined' color='neutral' loading={isSavingScore}>Save</Button>
								</Box>
							</form>
						</Section>
						{testCaseRun?.testCase?.evaluationMode === 'output' && (
							<>
								<Divider/>
								<Box padding={2}>
									{/* <ToggleButtonGroup value={view} onChange={(e, v) => setView(v)}>
									<Button value='output' size='sm' color={view === 'output' ? 'secondary' : 'neutral'} variant={view === 'output' ? 'solid' : 'outlined'}>Output</Button>
									<Button value='logs' size='sm' color={view === 'logs' ? 'secondary' : 'neutral'} variant={view === 'logs' ? 'solid' : 'outlined'}>Logs</Button>
								</ToggleButtonGroup> */}
									<Box display='flex' flexDirection='column' gap={2}>
										<Box display='flex' flexDirection='column' gap={1}>
											<Typography color='neutral' level='title-sm'>Output</Typography>
											<Box borderRadius='5px' px='4px' sx={{ fontSize: '12px', '& p': { marginBlockStart: '4px', marginBlockEnd: '4px' } }} border='1px solid #e4e4e4' bgcolor='var(--joy-palette-neutral-100)' overflow='hidden'>
												{testCaseRun?.output?.output !== undefined ? (
													<ReactMarkdown>{testCaseRun?.output?.output || 'null'}</ReactMarkdown>
												) : (
													testCaseRun?.output?.message ? (
														<ReactMarkdown>{testCaseRun?.output?.message}</ReactMarkdown>
													) : (
														<ReactMarkdown>{testCaseRun?.output}</ReactMarkdown>
														// <ReactCodeMirror
														// 	value={JSON.stringify(testCaseRun?.output, null, 2)}
														// 	readOnly
														// 	editable={false}
														// 	style={{ fontSize: '12px' }}
														// 	extensions={[EditorView.lineWrapping, customTheme]}
														// />
													)
												)}
											</Box>
										</Box>
									</Box>
								</Box>
								<Box padding={2}>
									{/* <ToggleButtonGroup value={view} onChange={(e, v) => setView(v)}>
									<Button value='output' size='sm' color={view === 'output' ? 'secondary' : 'neutral'} variant={view === 'output' ? 'solid' : 'outlined'}>Output</Button>
									<Button value='logs' size='sm' color={view === 'logs' ? 'secondary' : 'neutral'} variant={view === 'logs' ? 'solid' : 'outlined'}>Logs</Button>
								</ToggleButtonGroup> */}
									<Box display='flex' flexDirection='column' gap={2}>
										<Box display='flex' flexDirection='column' gap={1}>
											<Typography color='neutral' level='title-sm'>Expected Output</Typography>
											{testCaseRun?.testCase?.correctOutputs?.[0] ? (
												<Box borderRadius='5px' px='4px' sx={{ fontSize: '12px', '& p': { marginBlockStart: '4px', marginBlockEnd: '4px' } }} border='1px solid #e4e4e4' bgcolor='var(--joy-palette-neutral-100)' overflow='hidden'>
													<ReactMarkdown>{testCaseRun?.testCase?.correctOutputs?.[0]}</ReactMarkdown>
												</Box>
											) : (
												<Typography fontStyle='italic' level='body-sm'>No expected output set</Typography>
											)}
										</Box>
									</Box>
								</Box>
							</>
						)}
					</Box>
				</RightContainer>
			</PageContentContainer>
		</DashboardContainer>
	);
};

export default EvalTestPage;
