import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { HeaderLandingAnnotator } from '../components/Header';
import Footer from '../components/Footer';
import { AspectRatio, Box, Button, ButtonGroup, Chip, CircularProgress, Divider, IconButton, Input, LinearProgress, Slider, Typography } from '@mui/joy';
import CodeIcon from '@/icons/CodeIcon';
import ArrowRightIcon from '@/icons/ArrowRightIcon';
import { Helmet } from 'react-helmet';
import AuthIcon from '@/icons/AuthenticationIcon';
import CursorIcon from '@/icons/CursorIcon';
import FlowIcon from '@/icons/FlowIcon';
import GitHubIcon from '@/icons/GithubIcon';
import Fade from 'react-reveal/Fade';
import { css, keyframes } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import ParameterInput from './Builder/components/ParameterInputV2';
import PlayIcon from '../icons/PlayIcon';
import VisionIcon from '../icons/VisionIcon';
import RocketIcon from '@/icons/RocketIcon';
import CursorPointerIcon from '@/icons/CursorPointerIcon';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark, a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import RedShoesIcon from '@/icons/RedShoesIcon';
import LogoIcon from '@/icons/LogoIcon';
import SchemaInput from './Builder/components/SchemaInput';
import SchemaOutput from './Builder/components/SchemaOutput';
import CheckIcon from '@/icons/CheckIcon';
import CloseIcon from '@/icons/CloseIcon';
import DashIcon from '@/icons/DashIcon';


const HeroContainer = styled.div`
	padding-top: 10rem;
	padding-bottom: 6rem;
	padding-left: 2rem;
	padding-right: 2rem;
	position: relative;
	background-color: #ffffff;
	background-size: 96px;
	background-image: linear-gradient(to right, #d9d9d9 1px, transparent 1px);
	@media (max-width: 768px) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
`;

const PolkadoBackground = styled.div`
	position: absolute;
	top: 0;
	left: -1rem;
	width: calc(100% + 2rem);
	height: 100%;
  background-image: radial-gradient(circle, #d9d9d9 25%, transparent 25%);
  background-size: 8px 8px;
  mask-image: radial-gradient(ellipse at center, black 30%, transparent 75%);
	z-index: 1;
`;

const LinearBackground = styled.div`
	position: absolute;
	top: 0;
	left: -0.5rem;
	width: calc(100% + 1rem);
	height: 100%;
	background-size: 8px 8px;
	background-image: linear-gradient(to right, #d9d9d9 1px, transparent 1px), linear-gradient(to bottom, #d9d9d9 1px, transparent 1px);
  mask-image: radial-gradient(ellipse at center, black 30%, transparent 85%);
	z-index: 1;
`;
const GradientBackground = styled.div`
	position: absolute;
	top: 0;
	left: -0.5rem;
	width: calc(100% + 1rem);
	height: 100%;
	background: radial-gradient(ellipse at center, var(--joy-palette-neutral-300) 0%, transparent 65%);
	z-index: 1;
`;

const HeroContent = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	z-index: 10;
	position: relative;
	gap: 1rem;
	max-width: 1000px;
	margin: 0 auto;
`;

const RadialGradient = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	height: 100%;
	mask-image: radial-gradient(ellipse at center,transparent 1%,#000 75%);
	background-color: white;
`;

const LogoContainer = styled(Box)`
	background-color: #e3effb;
	color: #12467b;
	padding: 5px;
	border-radius: 5px;
	max-width: max-content;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 8px;
`;

const HeroCodeExample = styled(Box)`
	position: absolute;
	// background-color: var(--joy-palette-neutral-300);
	z-index: 10;
	// border-radius: 5px;
	bottom: -2.5rem;
	right: -2.5rem;
	background-color: #fafafa;
	padding: 1rem;
	border-radius: 8px;
	border: 1px solid #E4E4E4;
	box-shadow: 0 2px inset var(--joy-palette-third-shadowHighColor), var(--joy-shadow-md)
`;

const Section = styled.section`
	display: flex;
	justify-content: center;
	padding: 1rem;
	background-color: white;
`;

const InfoContainer = styled(Box)`
	position: relative;
	background-color: #fafafa;
	padding: 1rem;
	border-radius: 8px;
	border: 1px solid #E4E4E4;
	box-shadow: 0 2px inset var(--joy-palette-third-shadowHighColor);
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	& > * {
		z-index: 10;
	}
`;

const rotatingBorder = keyframes`
	to {
		background-position: 60px 0%, -60px 100%, 0% -60px, 100% 60px;
	}
`;

const RotatingBorder = styled(Box)<{bgHighlight?: boolean}>`
	animation: ${rotatingBorder} 10s linear infinite;
	background-image:
		linear-gradient(90deg, #0a6bcb 50%, transparent 50%),
		linear-gradient(90deg, #0a6bcb 50%, transparent 50%),
		linear-gradient(0deg, #0a6bcb 50%, transparent 50%),
		linear-gradient(0deg, #0a6bcb 50%, transparent 50%);
	background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
	background-size: 6px 2px, 6px 2px, 2px 6px, 2px 6px;
	background-position: 0% 0%, 0% 100%, 0% 0%, 100% 0%;
	border: none;
	position: relative;
	${({ bgHighlight }) => bgHighlight && css`
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #0a6bcb24;
		}
	`}
`;


const AnnotatorLandingPage: React.FC = () => {
	const isMobile = useMediaQuery('(max-width: 992px)');
	const containerRef = useRef<HTMLDivElement>(null);
	const [containerZoom, setContainerZoom] = useState(1);

	useEffect(() => {
		const handleResize = () => {
			if (containerRef.current) {
				const rect = containerRef.current.getBoundingClientRect();
				console.log('rect.width', rect.width);
				setContainerZoom(rect.width / 800);
			}
		};
		handleResize();

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [containerRef]);

	// useEffect(() => {
	// 	API.getIntegrationSources().then((data) => {
	// 		console.log('data', data);
	// 		setIntegrationSources(data);
	// 	});
	// }, []);

	useEffect(() => {
		// check if query param has error or loginError
		const url = new URL(window.location.href);
		const error = url.searchParams.get('error');
		const loginError = url.searchParams.get('loginError');
		if (error || loginError) {
			// TODO: show snackbar with error
			console.error('Error:', error || loginError);
		}
	}
	, []);

	return (
		<div>
			<Helmet>
				<style>{'body { background-color: #ffffff !important; }'}</style>
				<title>Plato Agentic Systems</title>
			</Helmet>
			<HeaderLandingAnnotator />
			<HeroContainer>
				<HeroContent>
					<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' width='100%' height='100%' marginBottom={6}>
						<Fade>
							<Chip size='md' sx={{ mb: 1 }} startDecorator={<RocketIcon width={12} height={12} fill='currentColor' />} color='primary'>Now Live</Chip>
							{/* <a href='https://github.com/useplato/plato' target='_blank' rel='noreferrer'>
								<Chip size='md' sx={{ mb: 1 }} startDecorator={<GitHubIcon width={14} height={14} fill='currentColor' />} color='primary'>Early access</Chip>
							</a> */}
							{/* <a href='https://github.com/useplato/plato' target='_blank' rel='noreferrer'>
								<Box display='flex' alignItems='center' border='1px solid #E4E4E4' borderRadius='4px' overflow='hidden' marginBottom={1}>
									<Box display='flex' gap={1} alignItems='center' paddingX={1} paddingY={.5} sx={{ backgroundColor: '#F7F7F7' }}>
										<GitHubIcon width={18} height={18} />
										<Typography level='title-sm'>Star</Typography>
									</Box>
									<Box display='flex' gap={1} alignItems='center' paddingX={1} paddingY={.5} sx={{ backgroundColor: '#ffffff' }}>
										<Typography level='body-xs'>open source</Typography>
									</Box>
								</Box>
							</a> */}
						</Fade>
						<Fade delay={50}>
							<Typography fontFamily='Lora, serif' textAlign='center' lineHeight={1.1} letterSpacing={-2.5} fontSize={58} level='h1'>Evals for Web Agents</Typography>
						</Fade>
						<Fade delay={100}>
							<Typography fontFamily='Lora, serif' textAlign='center' color='neutral' level='body-md' marginTop={1}>
							Datasets and environments for evaluating your web agents
							</Typography> </Fade>
						<Fade delay={150}>
							<Box display='flex' gap={1} marginTop={4}>
								<Button size='md' color='third' variant='shadowed' onClick={() => document.getElementById('learn-more')?.scrollIntoView({ behavior: 'smooth' })}>Learn more</Button>
								<a href='https://cal.com/rob-plato/30min' target='_blank' rel='noreferrer'>
									<Button size='md' color='secondary' variant='shadowed' endDecorator={<ArrowRightIcon width={12} height={12} fill='currentColor' />}>Talk with us</Button>
								</a>
							</Box>
						</Fade>
					</Box>
					<Fade delay={200}>
						<Box paddingX={1}>
							<Box ref={containerRef} position='relative' maxWidth='800px' width='100%' margin='0 auto'>
								<Box sx={{ paddingBottom: 'calc(9/16 * 100%)' }}>
									<Box width='800px' height='450px' sx={{ transform: `scale(${containerZoom})`, position: 'absolute', top: 0, left: 0, transformOrigin: '0 0' }}>
										<HeroCodeExample boxShadow='md'>
											<Typography level='title-lg' fontWeight='bold' marginBottom={1}>Tests</Typography>
											<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' gap={2}>
												<Box display='flex' alignItems='center' justifyContent='center' gap={1} width='100%'>
													<Box display='flex' alignItems='center' justifyContent='center' width='24px' height='24px' borderRadius='50%' bgcolor='#e4fbe3'>
														<CheckIcon width='10' height='10' fill='#0a470a' />
													</Box>
													<Box display='flex' flexDirection='column' alignItems='flex-start' justifyContent='flex-start' gap={0} marginRight={2}>
														<Typography level='body-sm' fontWeight='bold' lineHeight={1.3}>Create account</Typography>
														<Typography level='body-xs' color='neutral' lineHeight={1.3}><b>16.2s</b> - Account created</Typography>
													</Box>
													<Box flexGrow={1} display='flex' alignItems='center' justifyContent='flex-end'>
														<Typography level='body-xs' color='primary' fontWeight='bold' lineHeight={1.3}>View</Typography>
													</Box>
												</Box>
												<Box display='flex' alignItems='center' justifyContent='center' gap={1} width='100%'>
													<Box display='flex' alignItems='center' justifyContent='center' width='24px' height='24px' borderRadius='50%' bgcolor='var(--joy-palette-danger-100)'>
														<CloseIcon width='8' height='8' fill='var(--joy-palette-danger-500)' />
													</Box>
													<Box display='flex' flexDirection='column' alignItems='flex-start' justifyContent='flex-start' gap={0} marginRight={2}>
														<Typography level='body-sm' fontWeight='bold' lineHeight={1.3}>Find cheapest adidas shoe</Typography>
														<Typography level='body-xs' color='neutral' lineHeight={1.3}><b>40.4s</b> - Incorrect item purchased</Typography>
													</Box>
													<Box flexGrow={1} display='flex' alignItems='center' justifyContent='flex-end'>
														<Typography level='body-xs' color='primary' fontWeight='bold' lineHeight={1.3}>View</Typography>
													</Box>
												</Box>
												<Box display='flex' alignItems='center' justifyContent='center' gap={1} width='100%'>
													<Box display='flex' alignItems='center' justifyContent='center' width='24px' height='24px' borderRadius='50%' bgcolor='#e4fbe3'>
														<CheckIcon width='10' height='10' fill='#0a470a' />
													</Box>
													<Box display='flex' flexDirection='column' alignItems='flex-start' justifyContent='flex-start' gap={0} marginRight={2}>
														<Typography level='body-sm' fontWeight='bold' lineHeight={1.3}>Buy vintage converse</Typography>
														<Typography level='body-xs' color='neutral' lineHeight={1.3}><b>31.1s</b> - Vintage converse bought</Typography>
													</Box>
													<Box flexGrow={1} display='flex' alignItems='center' justifyContent='flex-end'>
														<Typography level='body-xs' color='primary' fontWeight='bold' lineHeight={1.3}>View</Typography>
													</Box>
												</Box>
												<Box display='flex' alignItems='center' justifyContent='center' gap={1} width='100%'>
													<Box display='flex' alignItems='center' justifyContent='center' width='24px' height='24px' borderRadius='50%' bgcolor='var(--joy-palette-warning-100)'>
														<DashIcon width='10' height='10' fill='var(--joy-palette-warning-500)' />
													</Box>
													<Box display='flex' flexDirection='column' alignItems='flex-start' justifyContent='flex-start' gap={0} marginRight={2}>
														<Typography level='body-sm' fontWeight='bold' lineHeight={1.3}>Get return policy for shoes</Typography>
														<Typography level='body-xs' color='neutral' lineHeight={1.3}><b>1m20s</b> - No answer found</Typography>
													</Box>
													<Box flexGrow={1} display='flex' alignItems='center' justifyContent='flex-end'>
														<Typography level='body-xs' color='primary' fontWeight='bold' lineHeight={1.3}>View</Typography>
													</Box>
												</Box>

											</Box>

										</HeroCodeExample>
										<Box width='100%' display='flex' flexDirection='column' alignItems='center' justifyContent='center' height='100%' border='1px solid #E4E4E4' borderRadius='5px' overflow='hidden' boxShadow='sm'>
											<Box width='100%' height='6%' bgcolor='#ababab' display='flex' alignItems='center' padding='0 8px'>
												<Box width='8px' height='8px' bgcolor='#FF4136' borderRadius='50%' marginRight='4px'></Box>
												<Box width='8px' height='8px' bgcolor='#FFDC00' borderRadius='50%' marginRight='4px'></Box>
												<Box width='8px' height='8px' bgcolor='#01FF70' borderRadius='50%'></Box>
											</Box>
											<Box display='flex' height='94%' width='100%' bgcolor='#F7F7F7'>
												<Box height='100%' width='22%' padding={1}>
													<Box  display='flex' flexDirection='column' gap={1} alignItems='flex-start' sx={{ transform: 'scale(0.60)', width: 'calc(100% / 0.60)', height: 'calc(100% / 0.60)', transformOrigin: 'top left' }}>
														<Box display='flex' alignItems='center' gap={1} width='100%' marginBottom={2}>
															<Box style={{ display: 'flex',  gap: '8px', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
																<LogoIcon style={{ borderRadius: '5px' }} width='28px' height='28px' />
																<Typography fontFamily='"Source Code Pro", monospace' fontSize='18px' fontWeight='bold' level='title-md'>Plato</Typography>
																<Box flexGrow={1} display='flex' flexDirection='column' alignItems='flex-start' justifyContent='flex-end' gap={0.5} marginLeft={2}>
																	<Typography level='body-xs' textAlign='right' width='100%'>00:24:32</Typography>
																</Box>
															</Box>
														</Box>
														<Box boxShadow='sm' width='100%' borderRadius='5px'>
															<Box display='flex'>
																<Box width='100%' display='flex' height='32px' alignItems='center' justifyContent='space-between' border='1px solid #E4E4E4' borderBottom={0} borderRadius={5} paddingX={1} paddingY={0} sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} bgcolor='var(--joy-palette-background-level1)' overflow='hidden'>
																	<Typography level='body-xs' fontWeight='bold'>TASK</Typography>
																</Box>
															</Box>
															<Box border='1px solid #E4E4E4' borderRadius='5px' sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} padding={0} overflow='hidden'>
																<Input
																	size='sm'
																	variant='plain'
																	sx={{
																		flexGrow: 1,
																		// paddingLeft: 0,
																		borderRadius: 0,
																		color: 'var(--joy-palette-text-primary)',
																		'&:before': {
																			boxShadow: 'none',
																		},
																		'&:disabled': {
																			color: 'var(--joy-palette-text-primary)',
																		},
																	}}
																	required
																	value='find the details of all red shoes'
																	slotProps={{
																		startDecorator: {
																			sx: {
																				margin: 0,
																			},
																		},
																	}}
																/>
															</Box>
														</Box>
														<Box width='100%' boxShadow='sm' borderRadius={5}>
															<SchemaOutput value={{ type: 'object', title: 'shoe_schema', properties: {
																shoes: { type: 'array', items: { type: 'object', properties: {
																	title: { type: 'string' },
																	description: { type: 'string' },
																	price: { type: 'string' },
																} } },
															} }} onChange={() => {}} />
														</Box>
														{/* <Box boxShadow='sm' width='100%'  borderRadius='5px'>
															<Box display='flex'>
																<Box width='100%' display='flex' height='32px' alignItems='center' justifyContent='space-between' border='1px solid #E4E4E4' borderBottom={0} borderRadius={5} paddingX={1} paddingY={0} sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} bgcolor='var(--joy-palette-background-level1)' overflow='hidden'>
																	<Typography level='body-xs' fontWeight='bold'>ACT</Typography>
																</Box>
															</Box>
															<Box border='1px solid #E4E4E4' borderRadius='5px' sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} padding={0} overflow='hidden'>
																<ParameterInput
																	value='click each product'
																	typeValue='click'
																	typeOptions={['click', 'type', 'image', 'boolean']}
																	isArray={false}
																	allowArray={true}
																	editable={false}
																	onChange={() => {}}
																/>
															</Box>
														</Box>
														<Box boxShadow='sm' width='100%'  borderRadius='5px'>
															<Box display='flex'>
																<Box width='100%' display='flex' height='32px' alignItems='center' justifyContent='space-between' border='1px solid #E4E4E4' borderBottom={0} borderRadius={5} paddingX={1} paddingY={0} sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} bgcolor='var(--joy-palette-background-level1)' overflow='hidden'>
																	<Typography level='body-xs' fontWeight='bold'>EXTRACT</Typography>
																</Box>
															</Box>
															<Box border='1px solid #E4E4E4' borderRadius='5px' sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} padding={0} overflow='hidden'>
																<ParameterInput
																	value='product'
																	typeValue='object'
																	typeOptions={['object', 'text', 'image', 'boolean']}
																	isArray={false}
																	allowArray={false}
																	editable={false}
																	onChange={() => {}}
																/>
																<Box display='flex' flexDirection='column' paddingLeft={1.5} bgcolor='#E4E4E4'>
																	<Divider />
																	<ParameterInput
																		value='name'
																		typeValue='text'
																		typeOptions={['object', 'text', 'image', 'boolean']}
																		isArray={false}
																		allowArray={false}
																		editable={false}
																		onChange={() => {}}
																	/>
																	<Divider />
																	<ParameterInput
																		value='description'
																		typeValue='text'
																		typeOptions={['object', 'text', 'image', 'boolean']}
																		isArray={false}
																		allowArray={false}
																		editable={false}
																		onChange={() => {}}
																	/>
																	<Divider />
																	<ParameterInput
																		value='price'
																		typeValue='text'
																		typeOptions={['object', 'text', 'image', 'boolean']}
																		isArray={false}
																		allowArray={false}
																		editable={false}
																		onChange={() => {}}
																	/>
																</Box>
															</Box>
														</Box> */}
														<Box flexGrow={1} display='flex' width='100%' gap={2} alignItems='flex-end' justifyContent='flex-end'>
															<Box flexGrow={1} display='flex' flexDirection='column' alignItems='flex-start' justifyContent='flex-end' gap={0.5}>
																<Typography level='body-xs' textAlign='left' width='100%'>Running Test 61/100</Typography>
																<LinearProgress determinate size='sm' value={61} color='secondary' sx={{ width: '100%', backgroundColor: '#E4E4E4' }} />
															</Box>
															<Button size='sm' color='third' variant='shadowed'>
																End
															</Button>
														</Box>
													</Box>
												</Box>
												<Box width='100%' height='100%' display='flex' flexDirection='column'>
													<Box width='100%' paddingY={0.5} paddingRight={0.5}>
														<Input
															sx={{
																fontSize: '10px',
																minHeight: '16px',
																height: '16px',
																borderRadius: '3px',
																boxShadow: 'none',
																border: '1px solid #E4E4E4',
																backgroundColor: '#fff'
															}}
															size='sm'
															value='https:///myshop.com/shoes'
														/>
													</Box>
													<Box width='calc(100% - 4px)' height='100%' bgcolor='#fff' sx={{ borderRadius: '5px' }} display='flex' gap={2} padding={2} paddingX={2}>
														<Box height='100%' width='12%' bgcolor='#E4E4E4' borderRadius='4px' padding='12px'>
															{/* <RotatingBorder display='flex' gap={0.5} alignItems='center' marginBottom={2} width='fit-content' padding='2px'>
													<Box width='24px' height='24px' bgcolor='#d0d0d0' borderRadius='2px' />
													<Box width='56px' height='16px' bgcolor='#d0d0d0' borderRadius='2px' />
												</RotatingBorder>
												<Box width='100%' height='6px' bgcolor='#d0d0d0' borderRadius='2px' /> */}
														</Box>
														<Box display='flex' flexDirection='column' gap={2} flexGrow={1}>
															<Box width='100%' height='48px' bgcolor='#E4E4E4' borderRadius='4px' />
															<Box display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr 1fr' gap={2} margin={0} flexGrow={1} width='100%'>
																{Array.from({ length: 10 }).map((_, index) => (
																	<Box display='flex' flexDirection='column' width='100%' height='100%' gap={.5} p={0} key={index} borderRadius='4px'>
																		{index === 0 ? (
																			<RotatingBorder position='relative' flexGrow={1} bgcolor='#E4E4E4' display='flex' alignItems='center' justifyContent='center' borderRadius='4px' boxShadow={index === 0 ? 'sm' : 'none'} border={index === 0 ? '1px solid #d0d0d0' : 'none'}>
																				{/* <Box sx={{ position: 'absolute', left: '10%', top: '10%', bottom: '10%', right: '10%' }}>
																				<RedShoesIcon width="100%" height="100%" fill="#676767" />
																			</Box> */}
																				{index === 0 && (
																					<Box
																						position='absolute'
																						top='50%'
																						left='50%'
																					>
																						<CursorPointerIcon width={18} height={18} />
																					</Box>
																				)}
																			</RotatingBorder>

																		) : (
																			<Box position='relative' flexGrow={1} bgcolor='#E4E4E4' borderRadius='4px' />
																		)}

																		<Box display='flex' flexDirection='column' gap={.5}>
																			<Box position='relative' width='50%' height='12px' bgcolor='#E4E4E4' borderRadius='2px'/>
																			<Box position='relative' display='flex' gap={.5} flexDirection='column' height='fit-content' padding='2px'>
																				<Box width='100%' height='6px' bgcolor='#E4E4E4' borderRadius='2px' />
																				<Box width='100%' height='6px' bgcolor='#E4E4E4' borderRadius='2px' />
																			</Box>
																			<Box position='relative' width='25%' height='16px' bgcolor='#E4E4E4' borderRadius='2px'>
																			</Box>
																			{/* <Box width='100%' height='8px' bgcolor='#E4E4E4' borderRadius='4px' /> */}
																		</Box>
																	</Box>
																))}
															</Box>
														</Box>
													</Box>
													<Box width='100%'>
														<Box display='grid' gridTemplateColumns={`repeat(${12 - 1}, 1fr)`} gap={0} marginTop={2}>
															<Box
																bgcolor='var(--joy-palette-secondary-400)'
																sx={{
																	gridColumnStart: 1,
																	gridColumnEnd: 3,
																	padding: '2px 4px',
																	borderRadius: '4px',
																	flexShrink: 0,
																	marginRight: '2px',
																	marginLeft: '0px',
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center',
																	overflow: 'hidden',
																	cursor: 'pointer',
																}}
															>
																<Typography sx={{ color: 'white', maxWidth: '100%', maxHeight: '100%' }} fontSize='8px' textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden' textAlign='left' level='body-xs'>
																	step: go to https://myshop.com/shoes
																</Typography>
															</Box>
															<Box
																bgcolor='var(--joy-palette-secondary-700)'
																sx={{
																	gridColumnStart: 3,
																	gridColumnEnd: 7,
																	padding: '2px 4px',
																	borderRadius: '4px',
																	flexShrink: 0,
																	marginRight: '2px',
																	marginLeft: '2px',
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center',
																	overflow: 'hidden',
																	cursor: 'pointer',
																}}
															>
																<Typography sx={{ color: 'white', maxWidth: '100%', maxHeight: '100%' }} fontSize='8px' textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden' textAlign='left' level='body-xs'>
																	step: locate red shoes
																</Typography>
															</Box>
															<Box
																bgcolor='var(--joy-palette-secondary-400)'
																sx={{
																	gridColumnStart: 7,
																	gridColumnEnd: 12,
																	padding: '2px 4px',
																	borderRadius: '4px',
																	flexShrink: 0,
																	marginRight: '0px',
																	marginLeft: '2px',
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center',
																	overflow: 'hidden',
																	cursor: 'pointer',
																}}
															>
																<Typography sx={{ color: 'white', maxWidth: '100%', maxHeight: '100%' }} fontSize='8px' textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden' textAlign='left' level='body-xs'>
																	...
																</Typography>
															</Box>
														</Box>
														<Slider
															step={1}
															min={0}
															max={12}
															size='sm'
															value={4}
															marks={[{ value: 1, label: 'load' }, { value: 3, label: 'scroll' }, { value: 5, label: 'click' }, { value: 7, label: 'type' }, { value: 9, label: 'click' }, { value: 11, label: 'click' }]}
															color='secondary'
															sx={{ marginBottom: '16px', paddingTop: '4px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</Fade>
				</HeroContent>
				<RadialGradient />
			</HeroContainer>
			<main style={{ padding: '0rem 0rem' }}>
				{/* <Section style={{ paddingBottom: '5rem', paddingTop: '5rem', marginTop: '2rem' }}>
					<Box display='flex' flexDirection='column' gap={2} width='1000px' maxWidth='100%'>
						<Fade>
							<Box mb={2}>
								<Typography textAlign='center' lineHeight={1.1} letterSpacing={-2.2} fontSize={48} level='h1'>What Plato can do</Typography>
								<Typography textAlign='center' color='neutral' level='body-md' marginTop={1}>
								Run headless browsers, extract data, perform actions, and automate tasks
								</Typography>
							</Box>
						</Fade>
						<Fade>
							<Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={2}>
								<InfoContainer flexBasis='61.8%'>
									<LogoContainer>
										<CursorIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>On-demand headless browsers</Typography>
									<Typography level='body-sm'>Pain free browser infrastructure that also works with Playwright, Selenium, Puppeteer, etc.</Typography>
									<Box position='relative' flexGrow={1} paddingY={2}>
										<Box position='relative' maxWidth='400px' zIndex={10} margin='0 auto' boxShadow='md'>
											<AspectRatio ratio={16/9} variant='plain'>
												<Box>
													<Box width='100%' height='100%' display='flex' flexDirection='column' alignItems='flex-start' sx={{ backgroundColor: '#fff', borderRadius: '5px', overflow: 'hidden' }}>
														<Box width='100%' height='8px' bgcolor='#ababab' />
														<Box width='50%' height='12px' bgcolor='#E4E4E4' borderRadius='12px' margin='0 auto' marginTop={2} />
														<Box flexGrow={1} width='100%' display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' gap={1} padding={2}>
															{Array.from({ length: 8 }).map((_, index) => (
																<Box display='flex' bgcolor='#E4E4E4' flexDirection='column' width='100%' height='100%' gap={.5} p={0} key={index} borderRadius='4px'>
																</Box>
															))}
														</Box>
													</Box>
												</Box>
											</AspectRatio>
											<Box position='absolute' bottom='-8px' left='-12px' bgcolor='neutral.700' zIndex={10} padding={2} borderRadius='4px' sx={{ fontSize: '14px', maxWidth: '240px' }} boxShadow='lg'>
												<span style={{ color: '#C586C0' }}>import</span> <span style={{ color: '#4EC9B0' }}>Plato</span> <span style={{ color: '#C586C0' }}>from</span> <span style={{ color: '#CE9178' }}>&apos;plato&apos;</span><br/>
												<span style={{ color: '#9CDCFE' }}>plato</span> <span style={{ color: '#D4D4D4' }}>=</span> <span style={{ color: '#4EC9B0' }}>Plato</span><span style={{ color: '#D4D4D4' }}>.</span><span style={{ color: '#DCDCAA' }}>startSession</span><span style={{ color: '#D4D4D4' }}>()</span>
											</Box>
										</Box>

									</Box>
								</InfoContainer>
								<InfoContainer flexBasis='38.2%'>
									<LogoContainer>
										<AuthIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Solve captchas</Typography>
									<Typography level='body-sm'>Plato can solve captchas</Typography>

									<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' paddingY={2} paddingTop={4} position='relative'>
										<PolkadoBackground />

										<Box boxShadow='md' display='flex' flexDirection='column' alignItems='center' justifyContent='center' border='1px solid #E4E4E4' borderRadius='5px' padding={2} paddingY={2} sx={{ backgroundColor: '#fff', width: '85%', maxWidth: '260px' }} zIndex={10} position='relative'>
											<Box display='flex' flexDirection='column' gap={.5} width='100%' marginBottom={1}>
												<Box width='40%' height='18px' sx={{ backgroundColor: '#ababab', borderRadius: '4px' }} />
												<Box width='70%' height='14px' sx={{ backgroundColor: '#eef0f1', borderRadius: '4px' }} />
											</Box>
											<Box display='flex' flexDirection='column' gap={2} width='100%'>
												<Box padding={1} bgcolor='#f7f7f7'>
													<RotatingBorder marginY={1} width='fit-content' marginX='auto' borderRadius='5px' padding='4px' paddingX='12px' bgHighlight>
														<Typography margin={0} textAlign='center' width='100%' level='h4' sx={{ fontFamily: 'Inter, cursive', fontStyle: 'italic', letterSpacing: '2px', fontSize: '32px', transform: 'skew(-5deg)', width: 'fit-content' }}>LSYVR</Typography>
													</RotatingBorder>
												</Box>
												<Box width='100%' height='24px' sx={{ backgroundColor: '#eef0f1', borderRadius: '4px', display: 'flex', alignItems: 'center', justifyContent: 'start', paddingLeft: '8px', fontSize: '12px' }}>
													|
												</Box>
												<Button size='sm' fullWidth variant='solid' color='primary' sx={{ minHeight: '24px' }}>
													<Box width='32px' height='6px' bgcolor='#eef0f1' borderRadius='4px' />
												</Button>
											</Box>
										</Box>
									</Box>
								</InfoContainer>
							</Box>
							<Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={2}>
								<InfoContainer flexBasis='42%'>
									<LogoContainer>
										<FlowIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>UI-independent automations</Typography>
									<Typography level='body-sm'>Define high-level automations and let Plato handle the details</Typography>
									<Box position='relative' flexGrow={1} height='200px'>
										<GradientBackground />
										<Box zIndex={10} position='absolute' top={0} left={0} width='100%' height='100%' display='flex' justifyContent='center' alignItems='center'>
											<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginY={2} sx={{ transform: 'scale(0.5)' }}>
												<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' borderRadius='8px' padding={2} paddingY={4} sx={{ backgroundColor: '#fff', maxWidth: '250px', width: '100%' }} boxShadow='rgba(36, 42, 66, 0.08) 0px 8px 20px -6px, rgba(36, 42, 66, 0.06) 0px 1px 4px -1px, rgba(36, 42, 66, 0.06) 0px 0px 2px 0px'>
													<Typography textAlign='left' width='100%' level='title-lg' marginBottom={2}>Login</Typography>
													<Box display='flex' flexDirection='column' gap={2} width='100%'>
														<Box width='100%' height='28px' sx={{ backgroundColor: '#eef0f1', borderRadius: '4px' }} />
														<Box width='100%' height='28px' sx={{ backgroundColor: '#eef0f1', borderRadius: '4px' }} />
														<Button disabled sx={{ marginTop: 1 }} variant='shadowed' color='secondary' size='sm' fullWidth>Login</Button>
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
								</InfoContainer>
								<InfoContainer flexBasis='58%'>
									<LogoContainer>
										<VisionIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Multi-step scraping</Typography>
									<Typography level='body-sm'>Extract data across multiple pages</Typography>
									<Box position='relative'>
										<LinearBackground />
										<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginY={2}>
											<Box zIndex={10} marginTop={2} boxShadow='md' display='flex' justifyContent='flex-start' border='1px solid #E4E4E4' borderRadius='5px' padding={2} paddingY={2} sx={{ backgroundColor: '#fff', marginX: '1rem', width: '85%' }} gap={2}>
												<Box width='50%' display='flex' flexDirection='column'>
													<Typography textAlign='left' width='100%' level='h4'>Indexing</Typography>
													<Box display='flex' flexDirection='column'  marginBottom={2} sx={{ maskImage: 'linear-gradient(to bottom, transparent, white 30%, white 70%, transparent)' }}>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://acme.com/</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://acme.com/about</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' fontWeight='500' textColor='primary' level='body-sm'>https://acme.com/search</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://acme.com/search?category=shoes</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://acme.com/item/white-tennis-shoes</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://acme.com/cart</Typography>
													</Box>

													<Box display='flex' flexDirection='column' gap={2} width='100%'>
													</Box>
												</Box>
												<Box sx={{ width: '50%', flexGrow: 1 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
													<AspectRatio ratio='16/9' variant='plain' sx={{ width: '100%' }}>
														<Box>
															<Box width='100%' height='100%' display='flex' flexDirection='column' alignItems='flex-start' sx={{ backgroundColor: '#f7f7f7', borderRadius: '5px', overflow: 'hidden' }}>
																<Box width='100%' height='8px' bgcolor='#ababab' />
																<RotatingBorder width='50%' height='12px' bgcolor='#E4E4E4' borderRadius='12px' margin='0 auto' marginTop={2} />
																<Box flexGrow={1} width='100%' display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' gap={1} padding={2}>
																	{Array.from({ length: 8 }).map((_, index) => (
																		<Box display='flex' bgcolor='#E4E4E4' flexDirection='column' width='100%' height='100%' gap={.5} p={0} key={index} borderRadius='4px'>
																		</Box>
																	))}
																</Box>
															</Box>
														</Box>
													</AspectRatio>
													<Typography textAlign='center' width='100%' level='body-xs'>clicking the search bar...</Typography>
												</Box>
											</Box>
										</Box>
									</Box>
								</InfoContainer>
							</Box>

						</Fade>
					</Box>
				</Section> */}
				{/* <Section style={{ paddingBottom: '6rem', paddingTop: '6rem', marginTop: '2rem', backgroundColor: '#f7f7f7' }}>
					<Box display='flex' flexDirection='column' gap={2} width='1000px' maxWidth='100%'>
						<Fade>
							<Box>
								<Typography textAlign='center' lineHeight={1.1} letterSpacing={-2.2} fontSize={48} level='h1'>Understand your agents</Typography>
								<Typography textAlign='center' color='neutral' level='body-md' marginTop={1}>
								Run benchmarks and custom create evals
								</Typography>
							</Box>
						</Fade>
						<Fade>

							<Box display='flex' justifyContent='center' flexDirection={isMobile ? 'column' : 'row'} gap={2}>
								<InfoContainer flexBasis='50%' sx={{ backgroundColor: '#ffffff !important' }}>
									<LogoContainer>
										<CodeIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Run & debug evals</Typography>
									<Typography level='body-sm'>Create evals and run them with your own model</Typography>
									<Box bgcolor='neutral.700' zIndex={10} padding={2} borderRadius='4px' marginTop={2} sx={{ fontSize: '14px', color: '#fff' }} boxShadow='lg'>
										<span style={{ color: '#DCDCAA' }}>curl</span> -X <span style={{ color: '#C586C0' }}>POST</span> https://api.plato.so/run-task \<br/>
											-H <span style={{ color: '#CE9178' }}>&quot;Content-Type: application/json&quot;</span> \<br/>
											-d <span style={{ color: '#CE9178' }}>{'\''}</span>{'{'}<br/>
										<span style={{ color: '#9CDCFE', marginLeft: '16px' }}>&quot;start_url&quot;</span>: <span style={{ color: '#CE9178' }}>&quot;https://myshop.com&quot;</span>,<br/>
										<span style={{ color: '#9CDCFE', marginLeft: '16px' }}>&quot;task&quot;</span>: <span style={{ color: '#CE9178' }}>&quot;Extract product information for any red shoes&quot;</span>,<br/>
										<span style={{ color: '#9CDCFE', marginLeft: '16px' }}>&quot;response_format&quot;</span>: {'{'}<br/>
										<span style={{ color: '#9CDCFE', marginLeft: '32px' }}>&quot;name&quot;</span>: <span style={{ color: '#CE9178' }}>&quot;string&quot;</span>,<br/>
										<span style={{ color: '#9CDCFE', marginLeft: '32px' }}>&quot;description&quot;</span>: <span style={{ color: '#CE9178' }}>&quot;string&quot;</span>,<br/>
										<span style={{ color: '#9CDCFE', marginLeft: '32px' }}>&quot;price&quot;</span>: <span style={{ color: '#CE9178' }}>&quot;number&quot;</span>,<br/>
										<span style={{ color: '#9CDCFE', marginLeft: '32px' }}>&quot;image_url&quot;</span>: <span style={{ color: '#CE9178' }}>&quot;string&quot;</span><br/>
										<span style={{ marginLeft: '16px' }}>{'}'}</span><br/>
										{'}'}<span style={{ color: '#CE9178' }}>{'\''}</span>
									</Box>
									<Box display='flex' justifyContent='flex-end' marginTop={2}>
										<a href='https://docs.plato.so' target='_blank' rel='noreferrer'>
											<Button size='md' color='third' variant='shadowed' endDecorator={<ArrowRightIcon fill='currentColor' width={12} height={12} />}>View API Docs</Button>
										</a>
									</Box>
								</InfoContainer>
								<InfoContainer flexBasis='50%' sx={{ backgroundColor: '#ffffff !important' }}>
									<LogoContainer>
										<CursorIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Create annotations</Typography>
									<Typography level='body-sm'>Collect data for whatever site you need</Typography>
									<Box paddingTop={2} paddingX={2} width='100%' margin='0 auto' maxWidth='500px' position='relative' flexGrow={1} display='flex' flexDirection='column' justifyContent='center'>
										<LinearBackground />
										<Box zIndex={10} position='relative'>
											<Box boxShadow='md'>
												<Box display='flex'>
													<Box width='100%' display='flex' height='32px' alignItems='center' justifyContent='space-between' border='1px solid #E4E4E4' borderBottom={0} borderRadius={5} paddingX={1} paddingY={0} sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} bgcolor='var(--joy-palette-background-level1)'>
														<Typography level='body-xs' fontWeight='bold'>ACT</Typography>
													</Box>
												</Box>
												<Box border='1px solid #E4E4E4' borderRadius='5px' sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} padding={0} overflow='hidden' marginBottom={1}>
													<ParameterInput
														value='click each company on the page'
														typeValue='click'
														typeOptions={['click', 'type', 'image', 'boolean']}
														isArray={false}
														allowArray={true}
														editable={false}
														onChange={() => {}}
													/>
												</Box>
											</Box>
											<Box boxShadow='md'>
												<Box display='flex'>
													<Box width='100%' display='flex' height='32px' alignItems='center' justifyContent='space-between' border='1px solid #E4E4E4' borderBottom={0} borderRadius={5} paddingX={1} paddingY={0} sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} bgcolor='var(--joy-palette-background-level1)'>
														<Typography level='body-xs' fontWeight='bold'>EXTRACT</Typography>
													</Box>
												</Box>
												<Box border='1px solid #E4E4E4' borderRadius='5px' sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} padding={0} overflow='hidden'>
													<ParameterInput
														value='companies'
														typeValue='object'
														typeOptions={['object', 'text', 'image', 'boolean']}
														isArray={true}
														allowArray={true}
														editable={false}
														onChange={() => {}}
													/>
													<Box display='flex' flexDirection='column' paddingLeft={1.5} bgcolor='#E4E4E4'>
														<Divider />
														<ParameterInput
															value='name'
															typeValue='text'
															typeOptions={['object', 'text', 'image', 'boolean']}
															isArray={false}
															allowArray={false}
															editable={false}
															onChange={() => {}}
														/>
														<Divider />
														<ParameterInput
															value='description'
															typeValue='text'
															typeOptions={['object', 'text', 'image', 'boolean']}
															isArray={false}
															allowArray={false}
															editable={false}
															onChange={() => {}}
														/>
														<Divider />
														<ParameterInput
															value='image_url'
															typeValue='image'
															typeOptions={['object', 'text', 'image', 'boolean']}
															isArray={false}
															allowArray={false}
															editable={false}
															onChange={() => {}}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
									<Box display='flex' justifyContent='flex-end' marginTop={2}>
										<NavLink to='/login'>
											<Button size='md' color='third' variant='shadowed' endDecorator={<ArrowRightIcon fill='currentColor' width={12} height={12} />}>Try it out</Button>
										</NavLink>
									</Box>
								</InfoContainer>
							</Box>


							<Box style={{ maxWidth: '800px', margin: '0 auto' }} boxShadow='md'>

							</Box>
						</Fade>
					</Box>
				</Section> */}
				<Section id='learn-more' style={{ paddingBottom: '7rem', paddingTop: '2rem', marginTop: '2rem' }}>
					<Box display='flex' flexDirection='column' gap={2} width='1000px' maxWidth='100%'>
						<Fade>
							<Box mb={2}>
								<Typography fontFamily='Lora, serif' textAlign='center' lineHeight={1.1} letterSpacing={-2.2} fontSize={48} level='h1'>Build Reliable Agents</Typography>
								<Typography fontFamily='Lora, serif' textAlign='center' color='neutral' level='body-md' marginTop={1}>
								Run benchmarks and create custom evals
								</Typography>
							</Box>
						</Fade>
						<Fade>
							<Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={2}>
								<InfoContainer flexBasis='61.8%'>
									<LogoContainer>
										<CursorIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography fontFamily='Lora, serif' level='h3'>Realistic Offline Benchmarks</Typography>
									<Typography fontFamily='Lora, serif' level='body-sm'>Static versions of WebVoyager + 1000s of more sites and tasks</Typography>
									<Box position='relative' flexGrow={1} display='flex'>
										<LinearBackground />
										<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginY={2}>
											<Box zIndex={10} marginTop={2} boxShadow='md' display='flex' justifyContent='flex-start' border='1px solid #E4E4E4' borderRadius='5px' padding={2} paddingY={2} sx={{ backgroundColor: '#fff', marginX: '1rem', width: '85%' }} gap={2}>
												<Box width='50%' display='flex' flexDirection='column'>
													<Typography textAlign='left' width='100%' level='h4'>Evalutating</Typography>
													<Box display='flex' flexDirection='column'  marginBottom={2} sx={{ maskImage: 'linear-gradient(to bottom, transparent, white 30%, white 70%, transparent)' }}>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://amazon.com/</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://amazon.com/about</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' fontWeight='500' textColor='primary' level='body-sm'>https://amazon.com/search</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://amazon.com/search?category=shoes</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://amazon.com/item/white-tennis-shoes</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://amazon.com/cart</Typography>
													</Box>

													<Box display='flex' flexDirection='column' gap={2} width='100%'>
													</Box>
													{/* <Box width='100%' height='12px' bgcolor='#fff' display='flex' justifyContent='center' position='relative' border='1px solid #9fa6ad' borderRadius='5px'>
														<Box position='absolute' left={-1} top={-1} right='39%' height='12px' bgcolor='#333' borderRadius='5px' boxShadow='0 -2px inset #000, 0 1px inset #444' />
													</Box> */}
												</Box>
												<Box sx={{ width: '50%', flexGrow: 1 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
													<AspectRatio ratio='16/9' variant='plain' sx={{ width: '100%' }}>
														<Box>
															<Box width='100%' height='100%' display='flex' flexDirection='column' alignItems='flex-start' sx={{ backgroundColor: '#f7f7f7', borderRadius: '5px', overflow: 'hidden' }}>
																<Box width='100%' height='8px' bgcolor='#ababab' />
																<RotatingBorder width='50%' height='12px' bgcolor='#E4E4E4' borderRadius='12px' margin='0 auto' marginTop={2} />
																<Box flexGrow={1} width='100%' display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' gap={1} padding={2}>
																	{Array.from({ length: 8 }).map((_, index) => (
																		<Box display='flex' bgcolor='#E4E4E4' flexDirection='column' width='100%' height='100%' gap={.5} p={0} key={index} borderRadius='4px'>
																		</Box>
																	))}
																</Box>
															</Box>
														</Box>
													</AspectRatio>
													<Typography textAlign='center' width='100%' level='body-xs'>clicking the search bar...</Typography>
												</Box>
											</Box>
										</Box>
									</Box>
								</InfoContainer>
								<InfoContainer flexBasis='50%'>
									<LogoContainer>
										<VisionIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography fontFamily='Lora, serif' level='h3'>Create Evals from Demonstrations</Typography>
									<Typography fontFamily='Lora, serif' level='body-sm'>Generate static enviornments from human demonstrations</Typography>
									<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginY={2}>
										<Box bgcolor='neutral.700' zIndex={10} padding={2} borderRadius='4px' marginTop={2} sx={{ fontSize: '13px', color: '#fff' }} boxShadow='lg'>
											<span style={{ color: '#ffffff' }}>{'{'}</span>
											<br/><span style={{ color: '#7cacf8', marginLeft: '16px' }}>"url"</span><span style={{ color: '#ffffff' }}>: </span><span style={{ color: '#fe8d59' }}>"https://myshop.com"</span><span style={{ color: '#ffffff' }}>,</span>
											<br/><span style={{ marginLeft: '16px', display: 'inline-block', color: '#7cacf8' }}>"task"</span><span style={{ color: '#ffffff' }}>: </span><span style={{ color: '#fe8d59' }}>"Extract product information for any red shoes"</span><span style={{ color: '#ffffff' }}>,</span>
											<br/><span style={{ marginLeft: '16px', display: 'inline-block', color: '#7cacf8' }}>"html"</span><span style={{ color: '#ffffff' }}>: </span><span style={{ color: '#fe8d59' }}>{'<html><body>...</body></html>'}</span><span style={{ color: '#ffffff' }}>,</span>
											<br/><span style={{ marginLeft: '16px', display: 'inline-block', color: '#7cacf8' }}>"actions"</span><span style={{ color: '#ffffff' }}>: [</span>
											<br/><span style={{ marginLeft: '32px', display: 'inline-block', color: '#ffffff' }}>{'{'}</span>
											<br/><span style={{ marginLeft: '40px', display: 'inline-block', color: '#7cacf8' }}>"type"</span><span style={{ color: '#ffffff' }}>: </span><span style={{ color: '#fe8d59' }}>"click"</span><span style={{ color: '#ffffff' }}>,</span>
											<br/><span style={{ marginLeft: '40px', display: 'inline-block', color: '#7cacf8' }}>"target"</span><span style={{ color: '#ffffff' }}>: </span><span style={{ color: '#fe8d59' }}>"https://myshop.com/search?category=shoes"</span>
											<br/><span style={{ marginLeft: '32px', display: 'inline-block', color: '#ffffff' }}>{'}'}</span>
											<br/><span style={{ marginLeft: '32px', display: 'inline-block', color: '#ffffff' }}>{'...'}</span>
											<br/><span style={{ marginLeft: '16px', display: 'inline-block', color: '#ffffff' }}>]</span>
											<br/><span style={{ color: '#D4D4D4' }}>{'}'}</span>
										</Box>
									</Box>
								</InfoContainer>
								{/* <InfoContainer flexBasis='38.2%'>
									<LogoContainer>
										<FlowIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Trained on a variety of flows</Typography>
									<Typography level='body-sm'>Plato is trained on a wide variety of websites and uses vision and the DOM to understand pages</Typography>
									<Box position='relative' flexGrow={1} height='200px'>
										<GradientBackground />
										<Box zIndex={10} position='absolute' top={0} left={0} width='100%' height='100%' display='flex' justifyContent='center' alignItems='center'>
											<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginY={2} sx={{ transform: 'scale(0.5)' }}>
												<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' borderRadius='8px' padding={2} paddingY={4} sx={{ backgroundColor: '#fff', maxWidth: '250px', width: '100%' }} boxShadow='rgba(36, 42, 66, 0.08) 0px 8px 20px -6px, rgba(36, 42, 66, 0.06) 0px 1px 4px -1px, rgba(36, 42, 66, 0.06) 0px 0px 2px 0px'>
													<Typography textAlign='left' width='100%' level='title-lg' marginBottom={2}>Login</Typography>
													<Box display='flex' flexDirection='column' gap={2} width='100%'>
														<Box width='100%' height='28px' sx={{ backgroundColor: '#eef0f1', borderRadius: '4px' }} />
														<Box width='100%' height='28px' sx={{ backgroundColor: '#eef0f1', borderRadius: '4px' }} />
														<Button disabled sx={{ marginTop: 1 }} variant='shadowed' color='secondary' size='sm' fullWidth>Login</Button>
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
								</InfoContainer> */}
							</Box>
							<Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={2}>
								<InfoContainer flexBasis='50%'>
									<LogoContainer>
										<VisionIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography fontFamily='Lora, serif' level='h3'>Observability</Typography>
									<Typography fontFamily='Lora, serif' level='body-sm'>Debug exactly what your agent is doing</Typography>
									<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginY={2}>
										<AspectRatio ratio='16/9' variant='plain' sx={{ width: '100%', maxWidth: '500px' }}>
											<Box>
												<Box width='100%' height='100%' display='flex' flexDirection='column' alignItems='flex-start' sx={{ backgroundColor: '#fff', borderRadius: '5px', overflow: 'hidden' }}>
													<Box width='100%' height='12px' bgcolor='#ababab' />
													<Box display='flex' flexDirection='row' width='100%' height='100%'>
														<Box sx={{
															width: '100%',
															border: '2px dashed #ababab',
															margin: '8px',
															position: 'relative',
														}}>
															<Box flexGrow={1} width='100%' height='100%' display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' gap={1} padding={2}>
																{Array.from({ length: 8 }).map((_, index) => (
																	<Box display='flex' bgcolor='#E4E4E4' flexDirection='column' width='100%' height='100%' gap={.5} p={0} key={index} borderRadius='4px'>
																	</Box>
																))}
															</Box>
														</Box>
														<Box height='100%' width='39%' bgcolor='var(--joy-palette-neutral-700)' padding={2} display='flex' flexWrap='wrap' gap={0.5} alignItems='center'>
															<Box width='48px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='32px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='64px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='24px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='40px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='56px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='32px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='48px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='24px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='36px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box display='flex' gap={.5} width='100%' marginLeft={1}>
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
																<Box flexBasis='50%' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															</Box>
															<Box display='flex' gap={.5} width='100%' marginLeft={1}>
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
																<Box flexBasis='50%' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															</Box>
															<Box display='flex' gap={.5} width='100%' marginLeft={1}>
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
																<Box flexBasis='50%' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															</Box>
															<Box width='52px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='44px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='28px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='60px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='40px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='32px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='48px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='36px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='56px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='44px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='28px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='52px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='36px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='44px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
														</Box>
													</Box>
												</Box>
											</Box>
										</AspectRatio>
									</Box>
								</InfoContainer>
								<InfoContainer flexBasis='50%'>
									<LogoContainer>
										<AuthIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography fontFamily='Lora, serif' level='h3'>Human Validation</Typography>
									<Typography fontFamily='Lora, serif' level='body-sm'>Use our service or tools for human validation</Typography>

									<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' paddingY={2} paddingTop={4} position='relative'>
										<PolkadoBackground />

										<Box boxShadow='md' display='flex' flexDirection='column' alignItems='center' justifyContent='center' border='1px solid #E4E4E4' borderRadius='5px' padding={2} paddingY={2} sx={{ backgroundColor: '#fff', width: '85%', maxWidth: '570px' }} zIndex={10} position='relative'>
											<Typography textAlign='left' width='100%' level='h4' marginBottom={1}>Agent Output</Typography>
											<Box display='flex' flexDirection='column' gap={1} width='100%'>
												<Box display='flex' flexGrow={1} alignItems='center' gap={1}>
													<Input
														sx={{
															boxShadow: 0,
															border: '1px solid #E4E4E4',
															outline: 'none',
															paddingRight: '0px',
															borderRadius: '4px',
															'&:before': {
																boxShadow: 'none',
															},
														}}
														size='sm'
														value='Click each patient in the list'
														startDecorator={<Chip size='sm'>Planner</Chip>}
														fullWidth
														endDecorator={
															<Box sx={{ width: '64px' }}>
																<ButtonGroup sx={{ justifyContent: 'space-between' }}>
																	<Button size='sm' variant='soft' color='success' sx={{ minWidth: '32px', minHeight: '32px', width: '32px', height: '32px', padding: 0, borderRadius: '0px' }}>
																		<CheckIcon width={12} height={12} fill='currentColor' />
																	</Button>
																	<Button size='sm' variant='soft' color='danger' sx={{ minWidth: '32px', minHeight: '32px', width: '32px', height: '32px', padding: 0, borderTopRightRadius: '4px', borderBottomRightRadius: '4px', borderLeft: 'none' }}>
																		<CloseIcon width={12} height={12} fill='currentColor' />
																	</Button>
																</ButtonGroup>
															</Box>
														}
													/>
												</Box>
												<Box display='flex' flexGrow={1} alignItems='center' gap={1}>
													<Input
														sx={{
															boxShadow: 0,
															border: '1px solid #E4E4E4',
															outline: 'none',
															paddingRight: '0px',
															borderRadius: '4px',
															'&:before': {
																boxShadow: 'none',
															},
														}}
														size='sm'
														value='Click the patient "Michelle Smith"'
														startDecorator={<Chip size='sm'>Navigator</Chip>}
														fullWidth
														endDecorator={
															<Box sx={{ width: '64px' }}>
																<ButtonGroup sx={{ justifyContent: 'space-between' }}>
																	<Button size='sm' variant='soft' color='success' sx={{ minWidth: '32px', minHeight: '32px', width: '32px', height: '32px', padding: 0, borderRadius: '0px' }}>
																		<CheckIcon width={12} height={12} fill='currentColor' />
																	</Button>
																	<Button size='sm' variant='soft' color='danger' sx={{ minWidth: '32px', minHeight: '32px', width: '32px', height: '32px', padding: 0, borderTopRightRadius: '4px', borderBottomRightRadius: '4px', borderLeft: 'none' }}>
																		<CloseIcon width={12} height={12} fill='currentColor' />
																	</Button>
																</ButtonGroup>
															</Box>
														}
													/>
												</Box>
												<Box display='flex' flexGrow={1} alignItems='center' gap={1}>
													<Input
														sx={{
															boxShadow: 0,
															border: '1px solid #E4E4E4',
															outline: 'none',
															paddingRight: '0px',
															borderRadius: '4px',
															'&:before': {
																boxShadow: 'none',
															},
														}}
														size='sm'
														value='Click the patient "John Doe"'
														startDecorator={<Chip size='sm'>Navigator</Chip>}
														fullWidth
														endDecorator={
															<Box sx={{ width: '64px' }}>
																<ButtonGroup sx={{ justifyContent: 'space-between' }}>
																	<Button size='sm' variant='soft' color='success' sx={{ minWidth: '32px', minHeight: '32px', width: '32px', height: '32px', padding: 0, borderRadius: '0px' }}>
																		<CheckIcon width={12} height={12} fill='currentColor' />
																	</Button>
																	<Button size='sm' variant='soft' color='danger' sx={{ minWidth: '32px', minHeight: '32px', width: '32px', height: '32px', padding: 0, borderTopRightRadius: '4px', borderBottomRightRadius: '4px', borderLeft: 'none' }}>
																		<CloseIcon width={12} height={12} fill='currentColor' />
																	</Button>
																</ButtonGroup>
															</Box>
														}
													/>
												</Box>
												<Box display='flex' flexGrow={1} alignItems='center' gap={1}>
													<Input
														sx={{
															boxShadow: 0,
															border: '1px solid #E4E4E4',
															outline: 'none',
															paddingRight: '0px',
															borderRadius: '4px',
															'&:before': {
																boxShadow: 'none',
															},
														}}
														size='sm'
														value='Click the patient "Rob Farlow"'
														startDecorator={<Chip size='sm'>Navigator</Chip>}
														fullWidth
														endDecorator={
															<Box sx={{ width: '64px' }}>
																<ButtonGroup sx={{ justifyContent: 'space-between' }}>
																	<Button size='sm' variant='soft' color='success' sx={{ minWidth: '32px', minHeight: '32px', width: '32px', height: '32px', padding: 0, borderRadius: '0px' }}>
																		<CheckIcon width={12} height={12} fill='currentColor' />
																	</Button>
																	<Button size='sm' variant='soft' color='danger' sx={{ minWidth: '32px', minHeight: '32px', width: '32px', height: '32px', padding: 0, borderTopRightRadius: '4px', borderBottomRightRadius: '4px', borderLeft: 'none' }}>
																		<CloseIcon width={12} height={12} fill='currentColor' />
																	</Button>
																</ButtonGroup>
															</Box>
														}
													/>
												</Box>
											</Box>
										</Box>
									</Box>
								</InfoContainer>
							</Box>
						</Fade>
						{/* <Fade delay={100}>
							<Box display='flex' justifyContent='center' width='100%' marginTop={12}>
								<iframe
									width='800'
									height='450'
									src='https://www.youtube.com/embed/BOLTCao8tUE'
									title='Plato Demo Video'
									frameBorder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
									allowFullScreen
								/>
							</Box>
						</Fade> */}
					</Box>
				</Section>
				{/* <Section style={{ marginTop: '6rem', backgroundImage: 'linear-gradient(to right, #d9d9d9 1px, transparent 1px), linear-gradient(to bottom, #d9d9d9 1px, transparent 1px)', backgroundSize: '42px 42px', position: 'relative' }}>
					<Box display='flex' flexDirection='column' zIndex={10} width='1000px' maxWidth='100%'>
						<Box mb={2}>
							<Fade>
								<Typography textAlign='center' lineHeight={1.1} letterSpacing={-2.2} fontSize={48} level='h1'>Pricing</Typography>
							</Fade>
							<Fade delay={50}>
								<Typography textAlign='center' color='neutral' level='body-md' marginTop={1}>
									Choose a plan that fits your needs.
								</Typography>
							</Fade>
						</Box>
						<PlansContainer>
							<div/>
							{plans.map((plan, index) => (
								<Fade key={plan.name} delay={50 + (75 * index)}>
									<Plan plan={plan} />
								</Fade>
							))}
							<div/>
						</PlansContainer>
					</Box>
					<RadialGradient />
				</Section> */}
			</main>
			<Footer />
		</div>
	); };

export default AnnotatorLandingPage;
