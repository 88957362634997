import styled from '@emotion/styled';
import { Avatar, Box, Button, IconButton, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import API from '../api';
import CreateAgentArtifactModal from '../components/CreateAgentArtifactModal';
import DashboardContainer from '../components/DashboardContainer';
import PlusIcon from '../icons/PlusIcon';
import TrashIcon from '../icons/TrashIcon';

const Section = styled(Box)`
	padding: 1rem;
`;

const TableRow = styled.tr<{ active: boolean }>`
	cursor: pointer;
	background-color: ${({ active }) => active ? 'var(--joy-palette-neutral-100)' : 'transparent'};
	&:hover {
		background-color: var(--joy-palette-neutral-50);
	}
`;

const AgentArtifactsPage: React.FC = () => {
    const [agentArtifacts, setAgentArtifacts] = useState<any[]>([]);
    const [selectedArtifact, setSelectedArtifact] = useState<any>(null);
    const [createArtifactModalOpen, setCreateArtifactModalOpen] = useState<boolean>(false);

    const getTypeEmoji = (type: string) => {
        switch (type) {
            case 'dev':
                return '👨‍💻';
            case 'git_container':
                return '📦';
            case 'human':
                return '👤';
            default:
                return '❓';
        }
    };

    const fetchAgentArtifacts = async () => {
        const { artifacts } = await API.getAgentArtifacts();
        console.log(artifacts);
        setAgentArtifacts(artifacts);
    };

    const handleArchiveArtifact = async (artifact: any) => {
        if (window.confirm(`Are you sure you want to archive "${artifact.alias}"?`)) {
            await API.archiveAgentArtifact(artifact.id);
            setSelectedArtifact(null);
            fetchAgentArtifacts();
        }
    };

    const handleRowClick = (artifact: any) => {
        setSelectedArtifact(selectedArtifact?.id === artifact.id ? null : artifact);
    };

    useEffect(() => {
        fetchAgentArtifacts();
    }, []);

    return (
        <DashboardContainer titleBar={{
            title: 'Agent Artifacts', actions: [
                <Button
                    startDecorator={<PlusIcon width={12} height={12} fill='currentColor' />}
                    variant='shadowed'
                    color='secondary'
                    onClick={() => setCreateArtifactModalOpen(true)}
                >
                    Create
                </Button>
            ]
        }} showTitleBar mainProps={{ style: { padding: 0 } }}>
            <Helmet>
                <title>Plato - Agent Artifacts</title>
            </Helmet>
            <CreateAgentArtifactModal
                open={createArtifactModalOpen}
                onClose={(created) => {
                    setCreateArtifactModalOpen(false);
                    if (created) {
                        fetchAgentArtifacts();
                    }
                }}
            />
            <Box display='flex' flexDirection='row' style={{ height: '100%' }}>
                <Box width='100%' sx={{ overflowX: 'auto' }}>
                    <table>
                        <thead>
                            <tr>
                                <th><Typography level='title-sm' color='neutral'>Alias</Typography></th>
                                <th><Typography level='title-sm' color='neutral'>Type</Typography></th>
                                <th><Typography level='title-sm' color='neutral'>Description</Typography></th>
                                <th style={{ textAlign: 'center' }}><Typography level='title-sm' color='neutral'>Archive</Typography></th>
                            </tr>
                        </thead>
                        <tbody>
                            {agentArtifacts.map((artifact) => (
                                <TableRow
                                    key={artifact.id}
                                    active={selectedArtifact?.id === artifact.id}
                                    onClick={() => handleRowClick(artifact)}
                                >
                                    <td>
                                        <Box display='flex' alignItems='center' gap={1}>
                                            {artifact.data.img_url && <Avatar size='sm' src={artifact.data.img_url} />}
                                            <Typography level='body-md'>{artifact.alias}</Typography>
                                        </Box>
                                    </td>
                                    <td>
                                        <Typography level='body-sm'>{getTypeEmoji(artifact.data.type)}</Typography>
                                    </td>
                                    <td>
                                        <Typography level='body-sm'>{artifact.data.description}</Typography>
                                    </td>
                                    <td onClick={(e) => e.stopPropagation()} style={{ textAlign: 'center' }}>
                                        <IconButton
                                            size='sm'
                                            variant='outlined'
                                            color='danger'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleArchiveArtifact(artifact);
                                            }}
                                        >
                                            <TrashIcon width={14} height={14} />
                                        </IconButton>
                                    </td>
                                </TableRow>
                            ))}
                        </tbody>
                    </table>
                </Box>
                {Boolean(selectedArtifact) && (
                    <Box sx={{ borderLeft: '1px solid #e4e4e4', minWidth: '400px', maxWidth: '400px' }}>
                        <Section>
                            <Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='center' rowGap={1} columnGap={2} margin={0}>
                                <Typography color='neutral' level='title-sm'>Alias</Typography>
                                <Typography level='body-sm' sx={{ wordBreak: 'break-word' }}>{selectedArtifact?.alias}</Typography>

                                <Typography color='neutral' level='title-sm'>Type</Typography>
                                <Typography level='body-sm' sx={{ wordBreak: 'break-word' }}>
                                    {getTypeEmoji(selectedArtifact?.data.type)} {selectedArtifact?.data.type}
                                </Typography>

                                <Typography color='neutral' level='title-sm'>Description</Typography>
                                <Typography level='body-sm' sx={{ wordBreak: 'break-word' }}>{selectedArtifact?.data.description}</Typography>

                                {selectedArtifact?.data.type === 'human' && (
                                    <>
                                        <Typography color='neutral' level='title-sm'>Human Name</Typography>
                                        <Typography level='body-sm' sx={{ wordBreak: 'break-word' }}>{selectedArtifact?.data.human_name}</Typography>
                                    </>
                                )}

                                {selectedArtifact?.data.type === 'dev' && (
                                    <>
                                        <Typography color='neutral' level='title-sm'>Endpoint</Typography>
                                        <Typography level='body-sm' sx={{ wordBreak: 'break-word' }}>{selectedArtifact?.data.endpoint}</Typography>
                                    </>
                                )}

                                {selectedArtifact?.data.type === 'git_container' && (
                                    <>
                                        <Typography color='neutral' level='title-sm'>Repository URL</Typography>
                                        <Typography level='body-sm' sx={{ wordBreak: 'break-word' }}>{selectedArtifact?.data.repo_url}</Typography>

                                        <Typography color='neutral' level='title-sm'>Branch</Typography>
                                        <Typography level='body-sm' sx={{ wordBreak: 'break-word' }}>{selectedArtifact?.data.branch}</Typography>

                                        <Typography color='neutral' level='title-sm'>Commit Hash</Typography>
                                        <Typography level='body-sm' sx={{ wordBreak: 'break-word' }}>{selectedArtifact?.data.commit_hash}</Typography>
                                    </>
                                )}
                            </Box>
                        </Section>
                    </Box>
                )}
            </Box>
        </DashboardContainer>
    );
};

export default AgentArtifactsPage;