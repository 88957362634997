import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Box, Button, Checkbox, Chip, Divider, Grid, IconButton, Input, Option, Select, Textarea, Typography } from '@mui/joy';
import DashboardContainer from '../components/DashboardContainer';
import API from '../api';
import { Paper } from '@mui/material';
import styled from '@emotion/styled';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark, a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import CodeIcon from '@/icons/CodeIcon';
import CreateSiteEndpointModal from '@/components/CreateSiteEndpointModal';
import { useDebounce } from 'use-debounce';
import CloseIcon from '../icons/CloseIcon';
import ReactCodeMirror, { EditorState, EditorView } from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import { javascript } from '@codemirror/lang-javascript';
import { customTheme } from './EvalRunPage';
import { linter, lintGutter } from '@codemirror/lint';
import CodeMirrorMerge from 'react-codemirror-merge';

const Original = CodeMirrorMerge.Original;
const Modified = CodeMirrorMerge.Modified;


const Section = styled(Box)`
  padding: 1rem;
`;

const TableRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? 'var(--joy-palette-neutral-100)' : 'transparent'};
`;

interface RegexNode {
	regex: string;
}

interface JsonNode {
	tag: string;
	attributes: Record<string, string | RegexNode>;
	children: JsonNode[];
}

function domToJSON(node: HTMLElement | ChildNode): JsonNode {
	const obj = {
		tag: node.nodeName.toLowerCase(),
		attributes: {},
		children: [],
	};

	// Extract attributes
	if (node instanceof Element) {
		for (const attr of node.attributes) {
			obj.attributes[attr.name] = attr.value;
		}
	}

	// Process child nodes
	for (const child of node.childNodes) {
		if (child.nodeType === Node.ELEMENT_NODE) {
			obj.children.push(domToJSON(child));
		} else if (child.nodeType === Node.TEXT_NODE && child.nodeValue.trim()) {
			obj.children.push({ text: child.nodeValue.trim() });
		}
	}

	return obj;
}

function htmlToJson(html: string) {
	const parser = new DOMParser();
	const doc = parser.parseFromString(html, 'text/html');
	const json = domToJSON(doc.documentElement);
	return json;
}

function findDiff(template: object, response: object, path: string[] = []): string[] | null {
	// Helper function for DFS
	// Skip if template key doesn't exist in response
	if (template === undefined) return null;

	// If values are different primitives, we found a diff
	if (typeof template !== 'object' || template === null) {
		if (template !== response) return path;
		return null;
	}

	console.log('thing abc', typeof template, typeof response);

	if (typeof template === 'object' && typeof response !== 'object') {
		console.log('THING 123', template, response, path);
		if (template['regex']) {
			const regex = new RegExp(template['regex'], 'i');
			if (regex.test(response)) return null;
		}
		return path;
	}


	// handle array case
	// if its an array, the order should not matter
	// sort array
	if (Array.isArray(template) && Array.isArray(response)) {
		for (let i = 0; i < response.length; i++) {
			// find a matching child in the template
			// if none are matching, create error for the one that is most similar
			let bestDiff = findDiff(template[i], response[i], [...path, i.toString()]);
			if (!bestDiff) continue;

			for (let j = 0; j < template.length; j++) {
				const diff = findDiff(template[j], response[i], [...path, i.toString()]);
				if (!diff) continue;
				if (j == i) bestDiff = diff;
			}
			if (bestDiff) return bestDiff;
		}
		return null;
	}

	// Recursively check all keys in template
	for (const key of Object.keys(response)) {
		if (template[key] === undefined) return [...path, key];
		const result = findDiff(template[key], response[key], [...path, key]);
		if (result !== null) return result;
	}

	return null;
}

function findDiffJsonNode(template: JsonNode, response: JsonNode): string[] | null {
	// Check if nodes have different tags
	if (template.tag !== response.tag) {
		return [];
	}

	// Check attributes
	for (const key of Object.keys(template.attributes)) {
		if (template.attributes[key]?.regex) {
			const regex = new RegExp(template.attributes[key].regex, 'i');
			if (!regex.test(response.attributes[key] as string)) {
				return ['attributes', key];
			}
		} else if (template.attributes[key] !== response.attributes[key]) {
			return ['attributes', key];
		}
	}

	// Check children recursively
	for (let i = 0; i < template.children.length; i++) {
		if (i >= response.children.length) {
			return ['children', i.toString()];
		}

		const templateChild = template.children[i];
		const responseChild = response.children[i];

		// Handle text nodes
		if ('text' in templateChild && 'text' in responseChild) {
			// const regex = new RegExp(templateChild.text as string, 'i');
			if (templateChild.text !== responseChild.text) {
				return ['children', i.toString(), 'text'];
			}
			continue;
		}

		// Handle element nodes
		if (!('text' in templateChild) && !('text' in responseChild)) {
			const childDiff = findDiff(templateChild as JsonNode, responseChild as JsonNode);
			if (childDiff !== null) {
				return ['children', i.toString(), ...childDiff];
			}
			continue;
		}

		// One is text node and other is element node
		return ['children', i.toString()];
	}

	// Check if response has extra children
	if (response.children.length > template.children.length) {
		return ['children', template.children.length.toString()];
	}

	return null;
}

function getDiff(template: JsonNode, response: JsonNode) {
	const path = findDiff(template, response);
	console.log('diff path', path);
	const templateDuplicate = JSON.parse(JSON.stringify(template));
	const otherValue = getObjectValueFromPath(response, path);
	setObjectValueFromPath(templateDuplicate, path, otherValue);
	return {
		doc: templateDuplicate,
		path,
	};
}


function getObjectValueFromPath(obj: any, path: string[]) {
	for (const key of path) {
		obj = obj?.[key];
	}
	return obj;
}

function setObjectValueFromPath(obj: any, path: string[], value: any) {
	for (const key of path.slice(0, -1)) {
		obj = obj?.[key];
	}
	if (obj) {
		obj[path[path.length - 1]] = value;
	}
	return obj;
}

function parseResponseBody(request: any) {
	if (!request?.response_body) return '';
	if (request?.content_type?.includes('text/html')) {
		return htmlToJson(atob(request?.response_body));
	} else if (request?.content_type?.includes('application/json')) {
		return JSON.parse(atob(request?.response_body));
	}
	try {
		return atob(request?.response_body);
	} catch (e) {
		return request?.response_body;
	}
}

function acceptBoth(template: JsonNode, response: JsonNode, diff: string[]) {
	const responseValue = getObjectValueFromPath(response, diff);
	const templateValue = getObjectValueFromPath(template, diff);

	const duplicateTemplate = JSON.parse(JSON.stringify(template));

	setObjectValueFromPath(duplicateTemplate, diff, {
		regex: `^(${responseValue}|${templateValue})$`,
		default: templateValue,
	});

	return duplicateTemplate;
}

function acceptAny(template: JsonNode, response: JsonNode, diff: string[]) {
	const value = getObjectValueFromPath(response, diff);
	const duplicateTemplate = JSON.parse(JSON.stringify(template));

	setObjectValueFromPath(duplicateTemplate, diff, {
		regex: '.*',
		default: value,
	});

	return duplicateTemplate;
}

const RequestTemplateBuilder: React.FC = () => {
	const [selectedRequest, setSelectedRequest] = useState<any | null>(null);
	const [requests, setRequests] = useState<any[]>([]);
	const [bodyTemplate, setBodyTemplate] = useState<string>('');
	const [parsedDiffDoc, setParsedDiffDoc] = useState<string>('');
	const [currentDiff, setCurrentDiff] = useState<any>({});

	const [filters, setFilters] = useState<Record<string, string>>({
		originUrl: '',
		url: '/searchresults',
		method: '',
		contentType: 'text/html',
		requestBody: '{}',
	});

	const [createEndpointModalOpen, setCreateEndpointModalOpen] = useState<boolean>(false);

	const [debouncedFilters] = useDebounce(filters, 300);

	const filteredRequests = useMemo(() => {
		const newFilteredRequests = requests.filter((request) => {
			if (!request.request.method?.includes(filters.method) || !request.request.content_type?.includes(filters.contentType) || !request.request.url?.includes(filters.url)) {
				return false;
			}

			try {
				const requestBodyFilter = JSON.parse(filters.requestBody);
				if (Object.keys(requestBodyFilter).length > 0) {
					const requestBody = JSON.parse(request.request.request_body);
					return Object.keys(requestBodyFilter).every((key) => {
						const regex = new RegExp(requestBodyFilter[key], 'i');
						return regex.test(requestBody[key]);
					});
				}
			} catch (e) {
				console.error(e);
			}

			return true;
		});
		console.log(newFilteredRequests);
		return newFilteredRequests;
	}, [requests, debouncedFilters]);

	useEffect(() => {
		if (!filteredRequests?.[0]?.request) setBodyTemplate('');
		else setBodyTemplate(JSON.stringify(parseResponseBody(filteredRequests[0].request), null, 2));
	}, [filteredRequests?.[0]?.id]);


	const onGenerateEndpoint = () => {
		// API.postSiteEndpointFromRequest({ ...selectedAsset }).then((res) => {
		// 	console.log(res);
		// });
		setCreateEndpointModalOpen(true);
	};

	useEffect(() => {
		API.getSessionNetworkRequests('52c5ac44-0be5-4163-94cf-a5aac2fb2dce').then((requests) => {
			setRequests((prevRequests) => [...prevRequests, ...Object.values(requests).map((request, index) => ({ request, sessionId: '52c5ac44-0be5-4163-94cf-a5aac2fb2dce', id: `52c5ac44-0be5-4163-94cf-a5aac2fb2dce-${index}` }))]);
		});
		API.getSessionNetworkRequests('517fc6db-918c-4d17-8d4b-00dbec89d9da').then((requests) => {
			setRequests((prevRequests) => [...prevRequests, ...Object.values(requests).map((request, index) => ({ request, sessionId: '517fc6db-918c-4d17-8d4b-00dbec89d9da', id: `517fc6db-918c-4d17-8d4b-00dbec89d9da-${index}` }))]);
		});
	}, []);

	// useEffect(() => {
	// 	const fetchSites = async () => {
	// 		if (!debouncedFilters.originUrl) return;

	// 		const { requests } = await API.getSiteRequests({ ...debouncedFilters });

	// 		if (!selectedRequest) {
	// 			setSelectedRequest(requests[0]);
	// 		}
	// 		setSiteRequests(requests);
	// 	};
	// 	fetchSites();
	// }, [debouncedFilters]);

	return (
		<DashboardContainer showTitleBar titleBar={{ title: 'Request Template Builder' }} mainProps={{ style: { padding: 0, display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' } }}>
			<Box display='flex' flexDirection='row' height='40%'>
				<Box width='100%' sx={{ overflow: 'auto' }}>
					<table>
						<thead>
							<tr>
								<th><Typography level='title-sm' color='neutral'>Method</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Content Type</Typography></th>
								<th><Typography level='title-sm' color='neutral'>URL</Typography></th>
							</tr>
						</thead>
						<tbody>
							{filteredRequests.map((request) => (
								<>
									<TableRow active={selectedRequest?.id === request.id} key={request.id} onClick={() => selectedRequest?.id === request.id ? setSelectedRequest(null) : setSelectedRequest(request)}>
										<td style={{ maxWidth: '250px' }}>
											<Typography whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis' level='body-sm'>{request.request.method}</Typography>
										</td>
										<td style={{ maxWidth: '200px' }}>
											<Typography whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis' level='body-xs'>{request.request.content_type}</Typography>
										</td>
										<td style={{ maxWidth: '250px' }}>
											<Typography whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis' level='body-xs'>{request.request.url}</Typography>
										</td>
									</TableRow>
									{selectedRequest?.id === request.id && (

										<>
											<tr>
												<td colSpan={3}>
													<Box display='flex' flexDirection='column' gap={1}>
														<Box display='flex' flexDirection='column' gap={1}>
															<Typography color='neutral' level='title-sm'>URL</Typography>
															<ReactCodeMirror
																value={selectedRequest.request.url}
																readOnly
																maxHeight='400px'
																autoFocus={false}
																editable={false}
																style={{ fontSize: '12px' }}
																extensions={[EditorView.lineWrapping, customTheme]}
															/>
														</Box>
														<Box display='flex' flexDirection='column' gap={1}>
															<Typography color='neutral' level='title-sm'>Content Type</Typography>
															<ReactCodeMirror
																value={selectedRequest.request.content_type}
																readOnly
																maxHeight='400px'
																autoFocus={false}
																editable={false}
																style={{ fontSize: '12px' }}
																extensions={[EditorView.lineWrapping, customTheme]}
															/>
														</Box>
														<Box display='flex' flexDirection='column' gap={1}>
															<Typography color='neutral' level='title-sm'>Request Body</Typography>
															<ReactCodeMirror
																value={JSON.stringify(selectedRequest.request.request_body)}
																readOnly
																maxHeight='400px'
																autoFocus={false}
																editable={false}
																style={{ fontSize: '12px' }}
																extensions={[EditorView.lineWrapping, customTheme]}
															/>
														</Box>
														<Box display='flex' flexDirection='column' gap={1}>
															<Typography color='neutral' level='title-sm'>Request Headers</Typography>
															<ReactCodeMirror
																value={JSON.stringify(selectedRequest.request.request_headers, null, 2)}
																readOnly
																maxHeight='400px'
																autoFocus={false}
																editable={false}
																style={{ fontSize: '12px' }}
																extensions={[EditorView.lineWrapping, customTheme]}
															/>
														</Box>
														<Box display='flex' flexDirection='column' gap={1}>
															<Typography color='neutral' level='title-sm'>Response Body</Typography>
															<ReactCodeMirror
																value={atob(selectedRequest.request.response_body)}
																readOnly
																maxHeight='400px'
																autoFocus={false}
																editable={false}
																style={{ fontSize: '12px' }}
																extensions={[EditorView.lineWrapping, customTheme]}
															/>
														</Box>
														<Box display='flex' flexDirection='column' gap={1}>
															<Typography color='neutral' level='title-sm'>Response Headers</Typography>
															<ReactCodeMirror
																value={JSON.stringify(selectedRequest.request.response_headers, null, 2)}
																readOnly
																maxHeight='400px'
																autoFocus={false}
																editable={false}
																style={{ fontSize: '12px' }}
																extensions={[EditorView.lineWrapping, customTheme]}
															/>
														</Box>
													</Box>
												</td>
											</tr>
										</>
									)}
								</>
							))}
						</tbody>
					</table>
				</Box>
				<Box sx={{ overflowY: 'auto', flexGrow: 1, maxWidth: '500px', minWidth: '500px', borderLeft: '1px solid #e4e4e4' }}>
					<Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' gap={2} p={2} py={1} borderBottom='1px solid #e4e4e4'>
						<Typography level='body-xs'>{selectedRequest?.url}</Typography>
						<Box display='flex' flexDirection='row' alignItems='center' gap={1}>
							{/* <Button size='sm' variant='outlined' color='neutral' onClick={onRecord} loading={recordLoading}>
								Record
							</Button>
							{selectedTestCaseEdited && (
								<Button size='sm' variant='outlined' color='primary' onClick={onSave}>
									Save
								</Button>
							)} */}
							{/* <IconButton size='sm' variant='outlined' color='neutral' onClick={() => setSelectedRequest(null)} sx={{ minWidth: '28px', minHeight: '28px' }}>
								<CloseIcon fill='currentColor' width={10} height={10} />
							</IconButton> */}
							{/* <Button size='sm' variant='outlined' color='neutral' onClick={() => {
								const diffDoc = getDiff(JSON.parse(bodyTemplate), parseResponseBody(filteredRequests[1].request));
								console.log(diffDoc);
								setParsedDiffDoc(JSON.stringify(diffDoc, null, 2));
							}}>
							Find Diff
							</Button> */}
						</Box>
					</Box>
					<Section>
						<Box marginTop={2} display='grid' gridTemplateColumns='1fr' gap={2}>
							<Input
								size='sm'
								placeholder='URL'
								value={filters.url}
								onChange={(event) => setFilters({ ...filters, url: event.target.value })}
							/>
							<Input
								size='sm'
								placeholder='Method'
								value={filters.method}
								onChange={(event) => setFilters({ ...filters, method: event.target.value })}
							/>
							<Input
								size='sm'
								placeholder='Content Type'
								value={filters.contentType}
								onChange={(event) => setFilters({ ...filters, contentType: event.target.value })}
							/>
							<ReactCodeMirror
								value={filters.requestBody}
								autoFocus={false}
								onChange={(value) => setFilters({ ...filters, requestBody: value })}
								editable
								style={{ fontSize: '12px' }}
								extensions={[json()]}
								theme='light'
							/>
							{/* <Textarea
								size='sm'
								placeholder='Request Body'
								value={filters.requestBody}
								onChange={(event) => setFilters({ ...filters, requestBody: event.target.value })}
							/> */}
							<Divider />
							<Typography level='title-sm'>Response Template</Typography>
							{/* <ReactCodeMirror
								value={parsedResponseBody}
								height='400px'
								autoFocus={false}
								// onChange={(value) => setFilters({ ...filters, requestBody: value })}
								// editable
								style={{ fontSize: '12px', position: 'relative', zIndex: 1000, width: '100%', overflowX: 'auto' }}
								extensions={[json()]}
								theme='light'
							/> */}
						</Box>
						{/* <Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='center' rowGap={1} columnGap={2} margin={0}>
							<Typography color='neutral' level='title-sm'>Site</Typography>
							<Box display='flex' flexDirection='row' alignItems='center' gap={1}>
								<img width={16} src={selectedRequest?.url} alt={selectedRequest?.url} />
								<Typography level='body-sm'>{selectedRequest?.url}</Typography>
							</Box>
							<Typography color='neutral' level='title-sm'>URL</Typography>
							<Typography level='body-sm'>{selectedRequest?.url}</Typography>
						</Box> */}
					</Section>
				</Box>
			</Box>
			<Box borderTop='1px solid #e4e4e4' height='60%'>
				<Box display='flex' flexDirection='row' width='100%' padding={1} py={1} justifyContent='space-between' gap={1}>
					<Box display='flex' flexDirection='row' gap={1}>
						<Button size='sm' variant='outlined' color='neutral' onClick={() => {
							const newTemplate = acceptBoth(JSON.parse(bodyTemplate), parseResponseBody(filteredRequests[1].request), currentDiff.path);
							setBodyTemplate(JSON.stringify(newTemplate, null, 2));
						}}>
							Both
						</Button>
						<Button size='sm' variant='outlined' color='neutral' onClick={() => {
							const newTemplate = acceptAny(JSON.parse(bodyTemplate), parseResponseBody(filteredRequests[1].request), currentDiff.path);
							setBodyTemplate(JSON.stringify(newTemplate, null, 2));
						}}>
							Any
						</Button>
						<Button size='sm' variant='outlined' color='neutral' onClick={() => {
							// const newTemplate = useVariable(JSON.parse(bodyTemplate), parseResponseBody(filteredRequests[1].request), currentDiff.path);
							// setBodyTemplate(JSON.stringify(newTemplate, null, 2));
						}}>
							Variable
						</Button>
					</Box>

					<Button size='sm' variant='outlined' color='neutral' onClick={() => {
						const diff = getDiff(JSON.parse(bodyTemplate), parseResponseBody(filteredRequests[1].request));
						console.log(diff);
						setCurrentDiff(diff);
						// setParsedDiffDoc(JSON.stringify(diffDoc, null, 2));
					}}>
							Next Diff
					</Button>
				</Box>
				<CodeMirrorMerge destroyRerender={false} onChange={(value) => console.log('updated', value)} theme='light' orientation='a-b' style={{ fontSize: '12px', height: '100%', position: 'relative', width: '100%', overflowX: 'auto' }}>
					<Original
						value={bodyTemplate}
						onChange={(value) => setBodyTemplate(value)}
						extensions={[json()]}
						editable
					/>
					<Modified
						value={JSON.stringify(currentDiff?.doc, null, 2) || bodyTemplate}
						extensions={[json(), EditorView.editable.of(true), EditorState.readOnly.of(true)]}
					/>
				</CodeMirrorMerge>
			</Box>

			{/* <Select value={filters.originUrl} onChange={(event, value) => setFilters({ ...filters, originUrl: value })}>
				{originUrls.map((originUrl) => (
					<Option key={originUrl} value={originUrl}>{originUrl}</Option>
				))}
			</Select> */}

			{/* <Box marginTop={2} display='grid' gridTemplateColumns='1fr' gap={2}>
				<Input
					placeholder='URL'
					value={filters.url}
					onChange={(event) => setFilters({ ...filters, url: event.target.value })}
				/>
				<Input
					placeholder='Method'
					value={filters.method}
					onChange={(event) => setFilters({ ...filters, method: event.target.value })}
				/>
				<Input
					placeholder='Content Type'
					value={filters.contentType}
					onChange={(event) => setFilters({ ...filters, contentType: event.target.value })}
				/>
				<Input
					placeholder='Session ID'
					value={filters.sessionPublicId}
					onChange={(event) => setFilters({ ...filters, sessionPublicId: event.target.value })}
				/>
			</Box> */}

			{/* <Grid container spacing={2}>
				<Grid xs={12} md={4}>
					<Paper style={{ padding: 0, marginTop: 16, overflow: 'hidden' }}>
						<table style={{ tableLayout: 'fixed', width: '100%' }}>
							<thead>
								<tr>
									<th style={{ width: '82px' }}>Method</th>
								</tr>
							</thead>
							<tbody>
								{siteRequests.map((siteRequest) => (
									<TableRow active={siteRequest === selectedRequest} key={siteRequest.url + siteRequest.method + siteRequest.contentType} onClick={() => setSelectedRequest(siteRequest)}>
										<td>
											<Box display='flex' alignItems='center' gap={1}>
												<Chip size='sm' variant='plain' color='neutral'>{siteRequest.method}</Chip>
												<Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} level='body-xs'>
													{siteRequest.url}
												</Typography>
											</Box>
										</td>
									</TableRow>
								))}
							</tbody>
						</table>
					</Paper>
				</Grid>
				<Grid xs={12} md={8}>
					<Paper style={{ padding: 0, marginTop: 16 }}>
						<Section>
							<Typography level='title-sm' sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{selectedRequest?.url}</Typography>
						</Section>
						<Divider />
						<Section>
							<Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='center' rowGap={1} columnGap={2} margin={0}>
								<Typography color='neutral' level='title-sm'>Method</Typography>
								<Chip size='sm' variant='soft' color='neutral'>{selectedRequest?.method}</Chip>
								<Typography color='neutral' whiteSpace='nowrap' level='title-sm'>Content Type</Typography>
								<Chip size='sm' variant='soft' color='neutral'>{selectedRequest?.contentType}</Chip>
							</Box>
							<Button
								sx={{ marginTop: '16px' }}
								color='secondary'
								size='sm'
								variant='shadowed'
								onClick={onGenerateEndpoint}
								startDecorator={<CodeIcon fill='currentColor' width={14} height={14} />}
							>
								Generate Endpoint
							</Button>
						</Section>
						<Divider />
						<Section>
							{Boolean(selectedRequest) && (
								<>
									<Typography level='title-sm'>Response</Typography>
									<Box display='flex' flexDirection='column' gap={1}>
										{siteResponses?.map((siteResponse) => {
											return (
												<Box key={siteResponse.url + siteResponse.method + siteResponse.contentType} display='flex' alignItems='center' gap={1}>
													<Checkbox
														size='sm'
														checked={siteResponse.selected}
														onChange={() => {
															setSiteResponses(siteResponses.map((response) => ({ ...response, selected: response.url === siteResponse.url })));
														}}
													/>
													<Typography level='title-sm'>{siteResponse.url}</Typography>
												</Box>
											);
										})}
									</Box>
								</>
							)}
						</Section>
					</Paper>
				</Grid>
			</Grid> */}
			{/* <AccordionGroup>
				{Object.entries(sitesByOriginUrl).map(([originUrl, urls]) => (
					<Accordion key={originUrl}>
						<AccordionSummary>
							<Typography>{originUrl}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							{urls.map((url) => <Typography key={url}>{url}</Typography>)}
						</AccordionDetails>
					</Accordion>
				))}
			</AccordionGroup> */}
		</DashboardContainer>
	);
};

export default RequestTemplateBuilder;
