export interface ElementData {
  id: string;
  text: string;
  attributes: Record<string, string>;
  tagName: string;
  classList: string[];
  parent: ElementData | null;
	platoId: string | null;
}


export function getElementData(element: Element, includeText = true): ElementData {
	const attributes: Record<string, string> = {};
	for (const attribute of Array.from(element.attributes)) {
		attributes[attribute.name] = attribute.value;
	}

	return {
		id: element.id,
		text: includeText ? (element as HTMLElement)?.innerText : '',
		attributes: attributes,
		platoId: element.getAttribute('plato-id'),
		tagName: element.tagName,
		classList: Array.from(element.classList),
		parent: (element.tagName !== 'BODY' && element.parentElement) ? getElementData(element.parentElement, false) : null,
	};
}

export function getElementsFromData(parentEl: Element, elementData: ElementData, isUnique = false): Element[] {
	try {
		if (!elementData) return [];
		// todo: will have to reference the stack in some way
		const stack: ElementData[] = [];
		let current: ElementData | null = elementData;
		while (current && current.platoId !== parentEl.getAttribute?.('plato-id')) {
			stack.unshift(current);
			current = current.parent;
		}
		const query = stack.map((el) => buildElementQuery(el)).join(' > ');
		const elements = Array.from(parentEl.querySelectorAll(query));
		if (!isUnique) {
			return elements as Element[];
		} else {
			// TODO: can do smarter things here probably
			const filtered = elements.filter((el) => (el as HTMLElement)?.innerText === elementData.text);
			return filtered as Element[];
		}
	} catch (e) {
		console.warn('error getting elements from data', e);
		return [];
	}
}

export function escapeSpecialChars(text: string) {
	// put double backslash infront of : or [ or ] or % or # or / or @ or . or & or = or + or ,
	if (!text) return text;
	// replace - but only if its right after a .
	text = text.replaceAll(/([.])-/g, '$1\\-');
	return text.replaceAll(/[:[\]%#/@.&=+,!()]/g, '\\$&');
}

export function buildElementQuery(element: Element | ElementData, includeId = false) {
	if (['HTML', 'BODY', 'HEAD'].includes(element.tagName)) {
		return element.tagName;
	}
	const classList = Array.from(element.classList || []);
	const idQuery = element.id ? `#${escapeSpecialChars(element.id)}` : '';
	const classQuery = classList.map(c => `.${escapeSpecialChars(c)}`).join('');
	return `${element.tagName}${includeId ? idQuery : ''}${classQuery}`;
}

export function buildLowestListParent(doc: Document, element: Element) {
	// starting with element parent, go up the dom tree and find the first parent that contains more than 1 child
	let current = element.parentElement;
	let prev = element;
	let query = buildElementQuery(element);
	while (current && current.tagName !== 'HTML') {
		const subList = current.querySelectorAll(`:scope > ${query}`);
		if (subList.length > 1) {
			const allListParents = getElementsFromData(doc.body, getElementData(current, true));
			const listParents = allListParents.filter(el => el.querySelectorAll(`:scope > ${query}`).length > 0);
			return {
				listElement: current,
				allListElements: listParents,
				nonListContextElement: prev,
			};
		}
		// query = `${current.tagName}${current.classList[0] ? '.' + escapeSpecialChars(current.classList[0]) : ''} > ${query}`;
		query = buildElementQuery(current) + ' > ' + query;
		prev = current;
		current = current.parentElement;
	}
	return {
		listElement: null,
		allListElements: [],
	};
}

export function buildListParent(element: Element, listParent: Element) {
	const stack = [];
	let current: Element | null = element;
	while (current && current !== listParent) {
		stack.unshift(getElementData(current));
		current = current.parentElement;
	}
	return {
		listElement: current,
		stack,
	};
}

export function getMatchingListItemsFromStack(listElement: Element, stack: Element[]) {
	// TODO: ideally it would do some type of search where it starts really strict but if there is no list then it loosens restrictions in the best way to get a list
	// highlighting specific children in a list item
	let currentListStack = stack;
	let index = 1;
	let result: Element[] = [];
	while (index <= currentListStack.length) {
		const lastFromIndex = currentListStack.slice(0, index);
		const query = `:scope > ${lastFromIndex.map((el) => buildElementQuery(el)).join(' > ')}`;
		console.log('query', query);
		const list = listElement.querySelectorAll(query);
		console.log('list', list);
		if (list.length > 0) {
			result = Array.from(list);
			index++;
		} else {
			currentListStack = currentListStack.slice(0, index - 1);
			break;
			// todo: try other variations of class names etc
		}
	}
	return {
		items: result,
		stack: currentListStack,
	};
}

export async function getBase64FromUrl(url: string) {
	try {
		const data = await fetch(url);
		const blob = await data.blob();

		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => resolve(reader.result);
			reader.onerror = (err) => reject(err);
		});
	} catch (e) {
		console.warn('error getting base64 from url', e);
		return null;
	}
};

function getSanitizedHtmlFromElement(element: Element) {
	if (!isElementVisible(element)) {
		return '';
	}
	const cloneContainer = document.createElement('div');
	// remove all non-visible items
	// recursivley call children
	const addVisibleChildren = (el: Element) => {
		const clone = el.cloneNode(false);
		cloneContainer.appendChild(clone);
		for (const child of Array.from(el.children)) {
			if (isElementVisible(child)) {
				addVisibleChildren(child);
			}
		}
	};
	addVisibleChildren(element);
	return cloneContainer.innerHTML;
}

// script to get box outlines for all visible dom elements
// if parent is simply a container, then use the width and height of the parent for the child and combine them.
// if the element is clickable, then don't recurse into it. anymore.
export function isElementVisible(element: Element) {

	const style = getComputedStyle(element);

	if (style.display === 'contents') {
		return true; // maybe change? idk this is probably right
	}

	// Check for display, visibility, and opacity
	if (style.display === 'none' || style.visibility === 'hidden' || style.opacity === '0') {
		return false;
	}

	const rect = element.getBoundingClientRect();
	// add translate to the rect
	const transform = style.transform;
	if (transform && transform.includes('translate')) {
		return true;
	}

	// Check if any of the ancestors hide the element with overflow
	let currentElement: Element | null = element;

	while (currentElement) {
		const currentStyle = getComputedStyle(currentElement);

		if (currentStyle.overflow === 'hidden' || currentStyle.overflow === 'auto' || currentStyle.overflow === 'scroll') {
			const parentRect = currentElement.getBoundingClientRect();

			if (
				rect.bottom < parentRect.top ||
        rect.top > parentRect.bottom ||
        rect.right < parentRect.left ||
        rect.left > parentRect.right
			) {
				return false;
			}
		}

		currentElement = currentElement.parentElement;
	}

	return true;
}


function isClickable(element: Element) {
	// const computedStyle = getComputedStyle(element);
	// if (computedStyle.cursor === 'pointer' || computedStyle.cursor === 'text') {
	// 	return true;
	// }

	// Check if the element is a button or a link
	if (['A', 'BUTTON', 'LINK', 'INPUT', 'SELECT', 'TEXTAREA'].includes(element.tagName)) {
		return true;
	}

	// Check if any class applied to the element sets cursor: pointer
	const dummyElement = document.createElement(element.tagName);
	dummyElement.className = element.classList.toString();
	dummyElement.setAttribute('style', element.getAttribute('style') || '');
	document.body.appendChild(dummyElement);
	if (getComputedStyle(dummyElement).cursor === 'pointer') {
		document.body.removeChild(dummyElement);
		return true;
	}
	document.body.removeChild(dummyElement);

	return false;
}

function areElementsIdentical(element1: Element, element2: Element) {
	return element1.tagName === element2.tagName && element1.classList.toString() === element2.classList.toString();
}

export function getElementRect(element: Element) {
	const domRect = element.getBoundingClientRect();
	const rect = {
		left: domRect.left,
		top: domRect.top,
		bottom: domRect.bottom,
		right: domRect.right,
	};
	// if element is an anchor tag, then get the part that overflows if any children are larger
	if (element.tagName === 'A') {
		for (const child of Array.from(element.children)) {
			const childRect = child.getBoundingClientRect();
			if (!isElementVisible(child)) continue;
			rect.left = Math.min(rect.left, childRect.left);
			rect.right = Math.max(rect.right, childRect.right);
			rect.top = Math.min(rect.top, childRect.top);
			rect.bottom = Math.max(rect.bottom, childRect.bottom);
		}
	}

	return {
		x: rect.left,
		y: rect.top,
		width: Math.max(1, rect.right - rect.left),
		height: Math.max(1, rect.bottom - rect.top),
	};
}

// FOR TESTING
function drawRect(rect: { x: number; y: number; width: number; height: number }, color = 'red') {
	const highlight = document.createElement('div');
	highlight.className = 'plato-highlight';
	highlight.style.left = `${rect.x}px`;
	highlight.style.top = `${rect.y}px`;
	highlight.style.width = `${rect.width}px`;
	highlight.style.height = `${rect.height}px`;
	highlight.style.border = `1px solid ${color}`;
	highlight.style.position = 'absolute';
	highlight.style.pointerEvents = 'none';
	highlight.style.zIndex = '1000000000';
	document.body.appendChild(highlight);
}

// FOR TESTING
function drawAnnotations(element: Element, group = false) {
	// delete all existing highlights
	document.querySelectorAll('.plato-highlight').forEach(el => el.remove());
	const annotations = getBoxAnnotations(element, group);
	for (const annotation of annotations) {
		drawRect(annotation.rect);
	}
}

interface Annotation {
	type: string;
	element: Element;
	platoId: string | null;
	containerplatoId: string | null;
	elRect: { x: number; y: number; width: number; height: number };
	rect: { x: number; y: number; width: number; height: number };
	subAnnotations: Annotation[];
	groups?: Annotation[][];
}


function getClickableBoxAnnotations(element: Element): Annotation[] {
	// returns a list of annotations
	// if is clickable, return self annotation with subAnnotations as children
	// if not clickable, simply return the combined list of all sub annotations

	const rect = getElementRect(element);

	if (element.tagName === 'svg') {
		return [];
	}


	const _isClickable = isClickable(element);
	const _isVisible = isElementVisible(element) && rect.width > 3 && rect.height > 3;

	const subAnnotations = [];

	for (const child of Array.from(element.children)) {
		const childAnnotations = getClickableBoxAnnotations(child);
		subAnnotations.push(...childAnnotations);
	}

	if (_isClickable && _isVisible) {
		return [
			{
				type: 'clickable',
				element: element,
				platoId: element.getAttribute('plato-id'),
				containerplatoId: element.getAttribute('plato-id'),
				elRect: rect,
				rect,
				subAnnotations,
			}
		];
	}

	return subAnnotations;
}

function getClickableBoxAnnotationsClustered(element: Element): Annotation[] {
	// returns a list of annotations
	// if is clickable, return self annotation with subAnnotations as children
	// if not clickable, simply return the combined list of all sub annotations

	const rect = getElementRect(element);

	if (element.tagName === 'svg') {
		return [];
	}


	const _isClickable = isClickable(element);
	const _isVisible = isElementVisible(element) && rect.width > 3 && rect.height > 3;

	// const subAnnotations = [];

	const subAnnotationsByChild: [Element, Annotation[]][] = [];

	for (const child of Array.from(element.children)) {
		const childAnnotations = getClickableBoxAnnotationsClustered(child);
		subAnnotationsByChild.push([child, childAnnotations]);
		// subAnnotations.push(...childAnnotations);
	}

	// this is where clustering can occur
	// if the children are in a list, then can assume its a cluster
	// ideally can check the actual annotations too

	const childrenWithAnnotations = subAnnotationsByChild.filter(([_, annotations]) => annotations.length > 0);
	let areSimilar = true;
	for (const [child, _] of childrenWithAnnotations) {
		if (!childrenWithAnnotations.every(([c, _]) => areElementsIdentical(child, c))) {
			areSimilar = false;
			break;
		}
	}


	if (areSimilar && childrenWithAnnotations.length > 3) {
		const subAnnotationsGroups: Annotation[][] = [];

		for (const [_, annotations] of childrenWithAnnotations) {
			for (const annotation of annotations) {
				const group = subAnnotationsGroups.find(group => group.every((a: any) => areElementsIdentical(a.element, annotation.element)));
				if (group) {
					group.push(annotation);
				} else {
					subAnnotationsGroups.push([annotation]);
				}
			}
		}

		return [
			{
				type: 'group',
				element,
				platoId: element.getAttribute('plato-id'),
				containerplatoId: element.getAttribute('plato-id'),
				rect,
				elRect: rect,
				groups: subAnnotationsGroups,
				subAnnotations: childrenWithAnnotations.map(([child, annotations]) => {
					if (annotations.length === 1) {
						return annotations[0];
					}
					return {
						type: 'group',
						element: child,
						platoId: child.getAttribute('plato-id'),
						containerplatoId: child.getAttribute('plato-id'),
						elRect: getElementRect(child),
						rect: getElementRect(child),
						subAnnotations: annotations,
					};
				}),
			}
		];
	} else if (_isClickable && _isVisible) {
		return [
			{
				type: 'clickable',
				element: element,
				platoId: element.getAttribute('plato-id'),
				containerplatoId: element.getAttribute('plato-id'),
				elRect: rect,
				rect,
				subAnnotations: subAnnotationsByChild.map(([_, annotations]) => annotations).flat(),
			}
		];
	}

	return subAnnotationsByChild.map(([_, annotations]) => annotations).flat();
}

function serializeAnnotations(annotations: Annotation[]): any[] {
	return annotations.map(a => ({ ...a, element: getElementData(a.element), subAnnotations: serializeAnnotations(a.subAnnotations), groups: a.groups ? a.groups.map(g => serializeAnnotations(g)) : [] }));
}


function expandAnnotationsRects(annotations: Annotation[]) {
	// first of all, expand each element's rect to its parent so long as that parent isn't the parent of another leaf.
	const elements = annotations.map(a => a.element);
	for (const annotation of annotations) {
		let current = annotation.element;
		annotation.elRect = annotation.rect;
		while (current.parentElement && current.parentElement.tagName !== 'BODY' && elements.filter(e => current?.parentElement?.contains(e)).length === 1) {
			current = current.parentElement;
		}
		annotation.rect = getElementRect(current);
		annotation.containerplatoId = current.getAttribute('plato-id');
	}
	return annotations;
}

export function getBoxAnnotations(element: Element, group = false) {
	if (group) {
		return serializeAnnotations(expandAnnotationsRects(getClickableBoxAnnotationsClustered(element)));
	}
	return serializeAnnotations(expandAnnotationsRects(getClickableBoxAnnotations(element)));
}

function getClusteredBoxAnnotations(element: Element) {
	const annotations = expandAnnotationsRects(getClickableBoxAnnotations(element));
	// the idea here is that for all parent elements of boxed annotations, if the children are all the same then group it into a section
	// this is useful for extracting lists
	// and for giving context to smaller elements. for example, a bunch of text items in a nav menu. without grouping, they are just "text", but after grouping they are now "nav menu items"
	// and also potentially for then evaluating actions by group

	const groupedAnnotations: Annotation[] = [];

	const annotationsByParent: Record<string, Annotation[]> = {};

	for (const annotation of annotations) {
		const parentId = document.querySelector(`[plato-id="${annotation.containerplatoId}"]`)?.parentElement?.getAttribute('plato-id');
		if (!parentId) {
			continue;
		}
		if (!annotationsByParent[parentId]) {
			annotationsByParent[parentId] = [];
		}
		annotationsByParent[parentId].push(annotation);
	}

	const elements = annotations.map(a => a.element);

	// what we're doing here is getting all leafs, which in this case, is just all the clickable items, and if multiple share a same parent
	// then we're grouping them as a list
	// the thing thats missing here is what if the list is a group of elements. like a table of rows where each row has 3 buttons
	// in this case, we actually want to group the parents themselves.


	Object.entries(annotationsByParent).forEach(([parentplatoId, annotations]) => {
		// might remove this later, if they are the exact same then I guess they should be grouped anyway
		if (annotations.length < 3) {
			groupedAnnotations.push(...annotations);
			return;
		}

		const containerElement = document.querySelector(`[plato-id="${parentplatoId}"]`);

		if (!containerElement) {
			return;
		}

		const elementsUnderContainer = elements.filter(e => containerElement.contains(e));

		if (elementsUnderContainer.length !== annotations.length) {
			groupedAnnotations.push(...annotations);
			return;
		}


		// the hard part here is determining if the items are identical.
		// for now, i'll just do the algorithm the frontend selector is using which is comparing tags and classNames
		// ideally it can look at the content/children too. this will help later for grouping groups. ex: groups of filters on yc companies page

		let areSimilar = true;
		for (const annotation of annotations) {
			if (!annotations.every(a => areElementsIdentical(a.element, annotation.element))) {
				areSimilar = false;
				break;
			}
		}

		if (areSimilar) {
			const containerRect = getElementRect(containerElement);
			groupedAnnotations.push({
				type: 'group',
				subAnnotations: annotations,
				element: containerElement,
				platoId: parentplatoId,
				containerplatoId: parentplatoId,
				rect: containerRect,
				elRect: containerRect,
			});
		} else {
			groupedAnnotations.push(...annotations);
		}
	});

	return groupedAnnotations;
}

export function injectScript() {
	return `
	window.escapeSpecialChars = ${escapeSpecialChars.toString()};
	window.getMatchingListItemsFromStack = ${getMatchingListItemsFromStack.toString()};
	window.buildLowestListParent = ${buildLowestListParent.toString()};
	window.buildListParent = ${buildListParent.toString()};
	window.getElementsFromData = ${getElementsFromData.toString()};
	window.getElementData = ${getElementData.toString()};
	window.buildElementQuery = ${buildElementQuery.toString()};
	window.isElementVisible = ${isElementVisible.toString()};
	window.isClickable = ${isClickable.toString()};
	window.getBoxAnnotations = ${getBoxAnnotations.toString()};
	window.expandAnnotationsRects = ${expandAnnotationsRects.toString()};
	window.getClickableBoxAnnotations = ${getClickableBoxAnnotations.toString()};
	window.getClickableBoxAnnotationsClustered = ${getClickableBoxAnnotationsClustered.toString()};
	window.getClusteredBoxAnnotations = ${getClusteredBoxAnnotations.toString()};
	window.getElementRect = ${getElementRect.toString()};
	window.areElementsIdentical = ${areElementsIdentical.toString()};
	window.getSanitizedHtmlFromElement = ${getSanitizedHtmlFromElement.toString()};
	window.drawRect = ${drawRect.toString()};
	window.drawAnnotations = ${drawAnnotations.toString()};
	window.serializeAnnotations = ${serializeAnnotations.toString()};
	`;
}
