import API from '@/api';
import DisconnectedIcon from '@/icons/DisconnectedIcon';
import LogoIcon from '@/icons/LogoIcon';
import WebIcon from '@/icons/WebIcon';
import { BuilderContext } from '@/pages/Builder/BuilderContext';
import styled from '@emotion/styled';
import { Box, Button, Checkbox, Input, ListItemDecorator, Modal, ModalDialog, Option, Select, Typography } from '@mui/joy';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

// Add type for environment
type Environment = {
	name: string;
	value: string;
	icon: JSX.Element;
}

const RunEvalModal = ({ open, onClose }: { open: boolean, onClose: () => void }) => {
	const navigate = useNavigate();
	const { setBrowserSession } = useContext(BuilderContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [originUrls, setOriginUrls] = useState<string[]>([]);
	const [testCaseSets, setTestCaseSets] = useState<any[]>([]);

	// const benchmarks = [{
	// 	name: 'WebVoyager',
	// }];
	const [selectedTestCaseSet, setSelectedTestCaseSet] = useState<number | null>(null);

	const environments = [{
		name: 'Offline',
		value: 'offline',
		icon: <DisconnectedIcon width={12} height={12} />
	}, {
		name: 'Online',
		value: 'online',
		icon: <WebIcon width={12} height={12} />
	}];
	const [selectedEnvironment, setSelectedEnvironment] = useState<string>(environments[0].value);
	const [name, setName] = useState<string>('');
	const [startRightAway, setStartRightAway] = useState<boolean>(false);
	const [runsPerTestCase, setRunsPerTestCase] = useState<number>(1);

	useEffect(() => {
		API.getTestCaseSets().then(response => {
			setTestCaseSets(response.testCaseSets);
			setSelectedTestCaseSet(response.testCaseSets?.[0]?.id);
		});
	}, []);


	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		const response = await API.runEval(selectedTestCaseSet, {
			environment: selectedEnvironment,
			name,
			queueImmediately: startRightAway,
			runsPerTestCase
		});
		console.log('evalRun', response);
		// setBrowserSession(response.browserSession);
		// navigate('/offline');
		navigate(`/evals/${response.publicId}`);
		setLoading(false);
		onClose();
	};

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog
				sx={{
					p: 2,
					backgroundColor: 'rgba(250, 250, 250, 0.75)',
					backdropFilter: 'blur(4px)',
					width: '400px',
					boxShadow: '0 1px inset var(--joy-palette-third-shadowHighColor)',
				}}
			>
				<LogoIcon width={42} height={42} />
				<Box>
					<Typography level='title-lg'>Run Agent Evaluation</Typography>
					<Typography level='body-sm'>
						Select a benchmark to run an agent evaluation.
					</Typography>
				</Box>
				<Form onSubmit={handleSubmit}>
					<Box display='flex' flexDirection='column' gap={0.5}>
						<Typography level='body-sm'>Name</Typography>
						<Input
							size='sm'
							value={name}
							onChange={(e) => setName(e.target.value)}
							required
							sx={{ boxShadow: 'none', borderRadius: '5px', border: '1px solid #e4e4e4' }}
							placeholder='Evaluation name'
						/>
					</Box>
					<Box display='flex' flexDirection='column' gap={0.5}>
						<Typography level='body-sm'>Benchmark</Typography>
						<Select
							// startDecorator={<WebIcon width={12} height={12} />}
							size='sm'
							value={selectedTestCaseSet}
							onChange={(_, value) => value && setSelectedTestCaseSet(value as number)}
							required
							sx={{ boxShadow: 'none', borderRadius: '5px', border: '1px solid #e4e4e4' }}
							renderValue={({ value }) => {
								const testCaseSet = testCaseSets.find((testCaseSet) => testCaseSet.id === value);
								return (
									<Box display='flex' alignItems='center' gap={1}>
										<ListItemDecorator>{testCaseSet.imgUrl ? <img width={12} src={testCaseSet.imgUrl} alt={testCaseSet.name} /> : <WebIcon width={12} height={12} />}</ListItemDecorator>
										{testCaseSet.name}
									</Box>
								);
							}}
						>
							{testCaseSets.map((testCaseSet) => (
								<Option key={testCaseSet.id} value={testCaseSet.id}>
									<ListItemDecorator sx={{ width: '12px', height: '12px' }}>{testCaseSet.imgUrl ? <img width={12} src={testCaseSet.imgUrl} alt={testCaseSet.name} /> : <WebIcon width={12} height={12} />}</ListItemDecorator>
									{testCaseSet.name}
								</Option>
							))}
						</Select>
					</Box>
					<Box display='flex' flexDirection='column' gap={0.5}>
						<Typography level='body-sm'>Environment</Typography>
						<Select
							size='sm'
							value={selectedEnvironment}
							onChange={(_, value) => value && setSelectedEnvironment(value as string)}
							required
							sx={{ boxShadow: 'none', borderRadius: '5px', border: '1px solid #e4e4e4' }}
							renderValue={({ value }) => {
								const environment = environments.find((e: Environment) => e.value === value) as Environment;
								return (
									<>
										<ListItemDecorator sx={{ marginRight: '8px' }}>{environment.icon}</ListItemDecorator>
										{environment.name}
									</>
								);
							}}
						>
							{environments.map((environment) => (
								<Option key={environment.name} value={environment.value}>
									<ListItemDecorator>{environment.icon}</ListItemDecorator>
									{environment.name}
								</Option>
							))}
						</Select>
					</Box>
					<Box display='flex' flexDirection='column' gap={0.5}>
						<Typography level='body-sm'>Runs per Test Case</Typography>
						<Input
							type="number"
							size='sm'
							value={runsPerTestCase}
							onChange={(e) => setRunsPerTestCase(Math.max(1, parseInt(e.target.value) || 1))}
							required
							sx={{ boxShadow: 'none', borderRadius: '5px', border: '1px solid #e4e4e4' }}
							placeholder='Number of runs'
							inputProps={{ min: 1 }}
						/>
					</Box>
					<Box display='flex' alignItems='center' gap={1} mt={2}>
						<Checkbox
							size="sm"
							checked={startRightAway}
							onChange={(e) => setStartRightAway(e.target.checked)}
							label="Start Runs Immediately"
							sx={{
								'--Checkbox-gap': '8px',
							}}
						/>
					</Box>
					<Box display='flex' justifyContent='flex-end' mt={2}>
						<Button size='sm' type='submit' variant='shadowed' color='secondary' loading={loading}>Start</Button>
					</Box>
				</Form>

			</ModalDialog>
		</Modal>
	);
};

export default RunEvalModal;
