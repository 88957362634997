import React, { useState } from 'react';
import ParameterInput from '@/pages/Builder/components/ParameterInputV2';
import { Box, Chip, Divider, IconButton, Input, Tooltip } from '@mui/joy';
import PlusIcon from '@/icons/PlusIcon';
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@/icons/CloseIcon';
import MinusIcon from '@/icons/MinusIcon';

export interface JsonSchema {
	type: 'object' | 'string' | 'number' | 'boolean' | 'array';
	title?: string;
	description?: string;
	properties?: {
		[key: string]: JsonSchema;
	};
	items?: JsonSchema;
}


interface SchemaInputProps {
	value: JsonSchema;
	onChange: (value: JsonSchema & { name?: string }) => void;
	isRoot?: boolean;
  name?: string;
}

const SchemaInput = ({ value, onChange, isRoot = true, name }: SchemaInputProps) => {

	const isArray = value.type === 'array';
	const type = isArray ? value.items?.type : value.type;
	const properties = isArray ? value.items?.properties : value.properties;

	const onAddProperty = () => {
		onChange({ ...value, properties: { ...value.properties, [uuidv4()]: { type: 'string' } } });
	};

	const onRemove = () => {
		onChange({ ...value, properties: Object.keys(value.properties || {}).reduce((acc, key) => {
			if (key !== name) acc[key] = value.properties[key];
			return acc;
		}, {}) });
	};

	const _onChange = (key: string) => (update: JsonSchema & { name: string }) => {
		// change the property with this key to have a new key called [name]
		onChange({ ...value, properties: { ...value.properties, [update.name]: { ...value.properties[key] }, [key]: undefined } });
	};


	if (isRoot) {
		return (
			<Box borderRadius={5} border='1px solid #e4e4e4' overflow='hidden'>
				<Input
					size='sm'
					variant='plain'
					placeholder='Title'
					required
					value={value.title || ''}
					sx={{
						flexGrow: 1,
						// paddingLeft: 0,
						borderRadius: 0,
						backgroundColor: 'var(--joy-palette-background-level1)',
						color: 'var(--joy-palette-text-primary)',
						'&:before': {
							boxShadow: 'none',
						},
						'&:disabled': {
							color: 'var(--joy-palette-text-primary)',
						},
					}}
					startDecorator={
						<Box>
							<Chip size='sm' variant='solid' color='secondary'>Schema</Chip>
						</Box>
					}

					onChange={(e) => onChange({ ...value, title: e.target.value })}
				/>
				{/* <ParameterInput
					value={value.title || ''}
					onChange={(update) => onChange({ ...value, title: update.value })}
					typeOptions={['object']}
					typeValue='object'
					isArray={false}
					allowArray={false}
					actions={
						<>
							{value.type === 'object' && (
								<Tooltip size='sm' title='Add Property' placement='right'>
									<IconButton size='sm' variant='plain' color='secondary' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onAddProperty}>
										<PlusIcon fill='currentColor' width={10} height={10} />
									</IconButton>
								</Tooltip>
							)}
							<Tooltip size='sm' title='Remove' placement='right'>
								<IconButton disabled size='sm' variant='plain' color='secondary' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onRemove}>
									<MinusIcon fill='currentColor' width={10} height={10} />
								</IconButton>
							</Tooltip>
						</>
					}
				/> */}
				<Box>
					{Object.keys(properties || {}).filter((key) => Boolean(properties[key])).map((key) => (
						<>
							<Divider />
							<SchemaInput isRoot={false} key={key} value={properties[key]} name={key} onChange={_onChange(key)} />
						</>
					))}
				</Box>
			</Box>
		);
	}

	return (
		<>
			<ParameterInput
				value={name || ''}
				onChange={(update) => onChange({ ...value, name: update.value })}
				typeOptions={['object', 'string', 'number', 'boolean']}
				typeValue={type}
				isArray={isArray}
				allowArray
				actions={
					<>
						{type === 'object' && (
							<Tooltip size='sm' title='Add Property' placement='right'>
								<IconButton size='sm' variant='plain' color='secondary' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onAddProperty}>
									<PlusIcon fill='currentColor' width={10} height={10} />
								</IconButton>
							</Tooltip>
						)}
						<Tooltip size='sm' title='Remove' placement='right'>
							<IconButton size='sm' variant='plain' color='secondary' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onRemove}>
								<MinusIcon fill='currentColor' width={10} height={10} />
							</IconButton>
						</Tooltip>
					</>
				}
			/>
			<Box paddingLeft={1.5} bgcolor='#E4E4E4'>
				{Object.keys(properties || {}).filter((key) => Boolean(properties[key])).map((key) => (
					<>
						<Divider />
						<SchemaInput isRoot={false} key={key} value={properties[key]} name={key} onChange={_onChange(key)} />
					</>
				))}
			</Box>
		</>
	);
};

export default SchemaInput;
