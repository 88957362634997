import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Chip, Typography, IconButton } from '@mui/joy';
import DashboardContainer from '../components/DashboardContainer';
import API from '../api';
import styled from '@emotion/styled';
import CodeMirror, { EditorView } from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import CloseIcon from '@/icons/CloseIcon';
import { NavLink, useNavigate } from 'react-router-dom';
import { BuilderContext } from './Builder/BuilderContext';
import { AppContext } from '@/context/AppContext';
import { Helmet } from 'react-helmet';

const customTheme = EditorView.theme({
	// '.cm-content': {
	// 	backgroundColor: 'var(--joy-palette-neutral-100)',
	// },
	// '&': {
	// 	backgroundColor: 'var(--joy-palette-neutral-100)',
	// },
	// '.cm-activeLine': {
	// 	backgroundColor: 'transparent',
	// },
	// '.cm-activeLineGutter': {
	// 	backgroundColor: 'transparent',
	// },
	// '.cm-gutters': {
	// 	backgroundColor: 'var(--joy-palette-neutral-100)',
	// },
});


const Section = styled(Box)`
  padding: 1rem;
`;

const TableRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? 'var(--joy-palette-neutral-100)' : 'transparent'};
	&:hover {
		background-color: var(--joy-palette-neutral-50);
	}
`;

const TestsPage: React.FC = () => {
	const [testCases, setTestCases] = useState<any[]>([]);
	const [selectedTestCase, setSelectedTestCase] = useState<any>(null);
	const [selectedTestCaseEdited, setSelectedTestCaseEdited] = useState<any>(false);
	const { setBrowserSession } = useContext(BuilderContext);
	const [recordLoading, setRecordLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const { addSnackbar } = useContext(AppContext);


	useEffect(() => {
		const fetchTestCases = async () => {
			const { testcases } = await API.getTestCases();
			setTestCases(testcases);
			console.log(testcases);
		};
		fetchTestCases();
	}, []);

	useEffect(() => {
		setSelectedTestCaseEdited(false);
	}, [selectedTestCase?.id]);

	const onSave = async () => {
		await API.updateTestCase(selectedTestCase.id, { prompt: selectedTestCase.prompt, correctOutputs: selectedTestCase.correctOutputs });
		setSelectedTestCaseEdited(false);
		setTestCases(testCases.map((testCase) => testCase.id === selectedTestCase.id ? selectedTestCase : testCase));
		addSnackbar({
			content: (
				<Box>
					<Typography level='title-sm'>Test case updated</Typography>
				</Box>
			),
			severity: 'success',
		});
	};

	const onRecord = async () => {
		setRecordLoading(true);
		const responseFormat =  { type: 'object', title: 'response', properties: {
			output: { type: 'string' },
		} };
		const response = await API.startTrainerSession({ startUrl: selectedTestCase.startUrl, task: selectedTestCase.prompt, responseFormat, trainerTestCaseId: selectedTestCase.id });
		setBrowserSession(response.browserSession);
		navigate('/trainer');
		setRecordLoading(false);
	};

	return (
		<DashboardContainer titleBar={{ title: 'Tests' }} showTitleBar mainProps={{ style: { padding: 0 } }}>
			<Helmet>
				<title>Plato - Tests</title>
			</Helmet>
			<Box display='flex' flexDirection='row' style={{ height: '100%' }}>
				<Box width='100%' sx={{ overflowX: 'auto' }}>
					<table>
						<thead>
							<tr>
								<th><Typography level='title-sm' color='neutral'>Site</Typography></th>
								<th><Typography level='title-sm' color='neutral'>URL</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Prompt</Typography></th>
								<th><Typography level='title-sm' whiteSpace='nowrap' color='neutral'>Eval Mode</Typography></th>
								<th><Typography level='title-sm' whiteSpace='nowrap' color='neutral'>Expected Outputs</Typography></th>
							</tr>
						</thead>
						<tbody>
							{testCases.map((testCase) => (
								<TableRow active={selectedTestCase?.id === testCase.id} key={testCase.id} onClick={() => setSelectedTestCase(testCase)}>
									<td>
										<Box display='flex' alignItems='center' gap={1}>
											<img width={16} src={testCase.site.imgUrl} alt={testCase.site.name} />
											<Typography level='body-md'>{testCase.site.name}</Typography>
										</Box>
									</td>
									<td style={{ maxWidth: '250px' }}>
										<Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} level='body-sm'>{testCase.startUrl}</Typography>
									</td>
									<td>
										<Typography level='body-sm'>{testCase.prompt}</Typography>
									</td>
									<td>
										<Chip size='sm' variant='soft' color='neutral'>
											{testCase.isSingleStep ? 'Single Step ' : ''}
											{testCase.evaluationMode[0].toUpperCase() + testCase.evaluationMode.slice(1)}
										</Chip>
									</td>
									<td>
										{testCase?.correctOutputs?.length ? (
											<Chip size='sm' variant='outlined' color='success'>
												Set
											</Chip>
										) : (
											<Chip size='sm' variant='outlined' color='danger'>
												Not set
											</Chip>
										)}
									</td>
								</TableRow>
							))}
						</tbody>
					</table>
				</Box>
				{Boolean(selectedTestCase) && (
					<Box sx={{ borderLeft: '1px solid #e4e4e4', minWidth: '400px', maxWidth: '400px' }}>
						<Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' gap={2} p={2} py={1} borderBottom='1px solid #e4e4e4'>
							<Typography level='body-xs'>{selectedTestCase?.name || selectedTestCase?.publicId}</Typography>
							<Box display='flex' flexDirection='row' alignItems='center' gap={1}>
								<Button size='sm' variant='outlined' color='neutral' onClick={onRecord} loading={recordLoading}>
									Record
								</Button>
								{selectedTestCaseEdited && (
									<Button size='sm' variant='outlined' color='primary' onClick={onSave}>
										Save
									</Button>
								)}
								<IconButton size='sm' variant='outlined' color='neutral' onClick={() => setSelectedTestCase(null)} sx={{ minWidth: '28px', minHeight: '28px' }}>
									<CloseIcon fill='currentColor' width={10} height={10} />
								</IconButton>
							</Box>
						</Box>
						<Section>
							<Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='center' rowGap={1} columnGap={2} margin={0}>
								<Typography color='neutral' level='title-sm'>Site</Typography>
								<Box display='flex' flexDirection='row' alignItems='center' gap={1}>
									<img width={16} src={selectedTestCase?.site.imgUrl} alt={selectedTestCase?.site.name} />
									<Typography level='body-sm'>{selectedTestCase?.site.name}</Typography>
								</Box>
								<Typography color='neutral' level='title-sm'>URL</Typography>
								<Typography level='body-sm'>{selectedTestCase?.startUrl}</Typography>
							</Box>
						</Section>
						<Box p={2} display='flex' flexDirection='column' gap={2}>
							<Box display='flex' flexDirection='column' gap={1}>
								<Typography level='title-sm' color='neutral'>Prompt</Typography>
								<Box borderRadius='5px' border='1px solid #e4e4e4' bgcolor='var(--joy-palette-neutral-100)' overflow='hidden'>
									<CodeMirror
										value={selectedTestCase?.prompt}
										onChange={(value) => {
											setSelectedTestCaseEdited(true);
											setSelectedTestCase({
												...selectedTestCase,
												prompt: value,
											});
										}}
										style={{ fontSize: '12px' }}
										theme={vscodeDark}
										basicSetup={{ lineNumbers: true }}
										extensions={[EditorView.lineWrapping]}
									/>
								</Box>
							</Box>
							<Box display='flex' flexDirection='column' gap={1}>
								<Typography level='title-sm' color='neutral'>Expected Output</Typography>
								<Box borderRadius='5px' border='1px solid #e4e4e4' bgcolor='var(--joy-palette-neutral-100)' overflow='hidden'>
									<CodeMirror
										value={JSON.stringify(selectedTestCase?.correctOutputs, null, 2)}
										onChange={(value) => {
											setSelectedTestCaseEdited(true);
											setSelectedTestCase({
												...selectedTestCase,
												correctOutputs: JSON.parse(value),
											});
										}}
										editable={true}
										style={{ fontSize: '12px' }}
										theme={vscodeDark}
										extensions={[json(), EditorView.lineWrapping]}
										basicSetup={{ lineNumbers: true }}
									/>
								</Box>
							</Box>
							{selectedTestCase?.outputSchema && (
								<Box display='flex' flexDirection='column' gap={1}>
									<Typography level='title-sm' color='neutral'>Output Schema</Typography>
									<Box borderRadius='5px' border='1px solid #e4e4e4' bgcolor='var(--joy-palette-neutral-100)' overflow='hidden'>
										<CodeMirror
											value={JSON.stringify(selectedTestCase?.outputSchema, null, 2)}
											onChange={(value) => {
												setSelectedTestCaseEdited(true);
												setSelectedTestCase({
													...selectedTestCase,
													outputSchema: JSON.parse(value),
												});
											}}
											editable={true}
											style={{ fontSize: '12px' }}
											theme={vscodeDark}
											extensions={[json(), EditorView.lineWrapping]}
											basicSetup={{ lineNumbers: true }}
										/>
									</Box>
								</Box>
							)}
						</Box>
					</Box>
				)}
			</Box>
		</DashboardContainer>
	);
};

export default TestsPage;

