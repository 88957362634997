import React, { useContext, useState } from 'react';
import { Box, Button, Input, Modal, ModalDialog, Typography } from '@mui/joy';
import LogoIcon from '@/icons/LogoIcon';
import styled from '@emotion/styled';
import WebIcon from '@/icons/WebIcon';
import API from '@/api';

const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const CreateSiteModal = ({ open, onClose }: { open: boolean, onClose: () => void }) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [name, setName] = useState<string>('');
	const [url, setUrl] = useState<string>('');
	const [imgUrl, setImgUrl] = useState<string>('');

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		try {
			await API.createSite({ name, url, imgUrl });
			onClose();
		} catch (error) {
			console.error('Error creating site:', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog
				sx={{
					p: 2,
					backgroundColor: 'rgba(250, 250, 250, 0.75)',
					backdropFilter: 'blur(4px)',
					width: '400px',
					boxShadow: '0 1px inset var(--joy-palette-third-shadowHighColor)',
				}}
			>
				<LogoIcon width={42} height={42} />
				<Box>
					<Typography level='title-lg'>Create Site</Typography>
					<Typography level='body-sm'>Provide site details to create a new entry.</Typography>
				</Box>
				<Form onSubmit={handleSubmit}>
					<Input
						startDecorator={<WebIcon width={12} height={12} />}
						size='sm'
						value={name}
						onChange={(e) => setName(e.target.value)}
						required
						placeholder='Site Name'
						sx={{ boxShadow: 'none', borderRadius: '5px', border: '1px solid #e4e4e4' }}
					/>
					<Input
						startDecorator={<WebIcon width={12} height={12} />}
						size='sm'
						value={url}
						onChange={(e) => setUrl(e.target.value)}
						required
						type='url'
						placeholder='https://example.com'
						sx={{ boxShadow: 'none', borderRadius: '5px', border: '1px solid #e4e4e4' }}
					/>
					<Input
						size='sm'
						value={imgUrl}
						onChange={(e) => setImgUrl(e.target.value)}
						required
						placeholder='Image URL'
						sx={{ boxShadow: 'none', borderRadius: '5px', border: '1px solid #e4e4e4' }}
					/>
					<Box display='flex' justifyContent='flex-end'>
						<Button size='sm' type='submit' variant='shadowed' color='secondary' loading={loading}>Create</Button>
					</Box>
				</Form>
			</ModalDialog>
		</Modal>
	);
};

export default CreateSiteModal;
