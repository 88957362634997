import React from 'react';

export default function ThreedotsIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			version='1.1'
			id='Capa_1'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			x='0px'
			y='0px'
			viewBox='0 0 384 384'
			{...props}
		>
			<g>
				<g>
					<circle cx='192' cy='42.667' r='42.667' />
				</g>
			</g>
			<g>
				<g>
					<circle cx='192' cy='192' r='42.667' />
				</g>
			</g>
			<g>
				<g>
					<circle cx='192' cy='341.333' r='42.667' />
				</g>
			</g>
		</svg>
	);
}
