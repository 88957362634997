import API from '@/api';
import RunEvalModal from '@/components/RunEvalModal';
import styled from '@emotion/styled';
import { Box, Button, Chip, IconButton, Typography } from '@mui/joy';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import DashboardContainer from '../components/DashboardContainer';
import PlusIcon from '../icons/PlusIcon';
import TrashIcon from '../icons/TrashIcon';


const UsageRow = styled.tr<{ active: boolean }>`
	cursor: pointer;
	background-color: ${({ active }) => active ? 'var(--joy-palette-neutral-100)' : 'transparent'};
	&:hover {
		background-color: var(--joy-palette-neutral-50);
	}
`;

const EvalRunsPage: React.FC = () => {
	const [evalRuns, setEvalRuns] = useState<any[]>([]);
	const [runEvalModalOpen, setRunEvalModalOpen] = useState<boolean>(false);
	// const [selectedEvalRun, setSelectedEvalRun] = useState<any>(null);
	const navigate = useNavigate();
	useEffect(() => {
		API.getMyEvalRuns().then(data => {
			setEvalRuns(data);
			// setSelectedEvalRun(data.evalRuns[0]);
		});
	}, []);

	const runEval = () => {
		console.log('runEval');
	};

	const getStatus = (evalRun: any) => {
		if (evalRun.endedAt) {
			if (evalRun.evalTestCaseRuns.find(testCaseRun => testCaseRun.endedAt === null)) {
				return {
					text: 'Incomplete',
					color: 'neutral'
				};
			}
			if (evalRun.evalTestCaseRuns.find(testCaseRun => testCaseRun.score === null)) {
				return {
					text: 'Needs Review',
					color: 'warning'
				};
			}
			return {
				text: 'Completed',
				color: 'success'
			};
		}
		if (evalRun.startedAt) {
			return {
				text: 'Running',
				color: 'primary'
			};
		}
		return {
			text: 'Pending',
			color: 'neutral'
		};
	};

	return (
		<DashboardContainer titleBar={{
			title: 'Eval Runs', actions: [
				<Button
					size='sm'
					variant='shadowed'
					color='secondary'
					onClick={() => setRunEvalModalOpen(true)}
					startDecorator={<PlusIcon width={12} height={12} fill='currentColor' />}
				>
					Start Eval
				</Button>,
			]
		}} showTitleBar mainProps={{ style: { padding: 0 } }}>
			<Helmet>
				<title>Plato Eval Runs</title>
			</Helmet>
			<RunEvalModal open={runEvalModalOpen} onClose={() => setRunEvalModalOpen(false)} />
			<Box display='flex' flexDirection='row' style={{ height: '100%' }}>
				<Box width='100%'>
					<table>
						<thead>
							<tr>
								<th><Typography level='title-sm' color='neutral'>Name</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Benchmark</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Started</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Status</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Score</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Num. Tests</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Actions</Typography></th>
							</tr>
						</thead>
						<tbody>
							{evalRuns.map((evalRun) => {
								const score = (evalRun.evalTestCaseRuns.reduce((acc, testCaseRun) => acc + testCaseRun.score, 0) / evalRun.evalTestCaseRuns.length) * 100;
								const sites: Record<string, any> = {};
								const numTests = evalRun.evalTestCaseRuns.length;
								const numPassed = evalRun.evalTestCaseRuns.filter(testCaseRun => testCaseRun.score === 1).length;
								const numFailed = evalRun.evalTestCaseRuns.filter(testCaseRun => testCaseRun.endedAt && testCaseRun.score <= 0.5).length;
								const numNeedsReview = evalRun.evalTestCaseRuns.filter(testCaseRun => testCaseRun.endedAt && testCaseRun.score === null).length;
								const numPending = evalRun.evalTestCaseRuns.filter(testCaseRun => !testCaseRun.startedAt).length;
								const numRunning = evalRun.evalTestCaseRuns.filter(testCaseRun => !testCaseRun.endedAt && testCaseRun.startedAt).length;
								// evalRun.evalTestCaseRuns.forEach(testCaseRun => {
								// 	if (!sites[testCaseRun.testCase.site.id]) {
								// 		sites[testCaseRun.testCase.site.id] = testCaseRun.testCase.site;
								// 	}
								// });
								return (
									<UsageRow
										// active={selectedEvalRun?.id === evalRun.id}
										active={false}
										key={evalRun.id}
										// onClick={() => setSelectedEvalRun(evalRun)}
										onClick={() => navigate(`/evals/${evalRun.publicId}`)}
									>
										<td>
											<Typography level='body-md'>{evalRun.name || '-'}</Typography>
										</td>
										<td>
											{evalRun.testCaseSet ? (
												<Box display='flex' alignItems='center' gap={1}>
													<img width={16} src={evalRun.testCaseSet.imgUrl} alt={evalRun.testCaseSet.name} />
													<Typography level='body-md'>{evalRun.testCaseSet.name}</Typography>
												</Box>
											) : (
												<Typography level='body-md'>-</Typography>
											)}
										</td>
										{/* <td>
											<AvatarGroup size='sm'>
												{Object.entries(sites).map(([siteId, site]) => (
													<Tooltip key={siteId} size='sm' title={site.name}>
														<Avatar sx={{ width: 18, height: 18 }} src={site.imgUrl} />
													</Tooltip>
												))}
											</AvatarGroup>
										</td> */}
										<td>
											<Typography level='body-sm'>
												{evalRun.startedAt ? moment(evalRun.startedAt).fromNow() : '-'}
											</Typography>
										</td>
										<td>
											{numPending === 0 && (
												<Chip
													size='sm'
													color='success'
													variant='soft'
												>
													Done
												</Chip>
											)}
											{numPending > 0 && (
												<Chip
													size='sm'
													color='neutral'
												>
													{numPending} Pending
												</Chip>
											)}
										</td>
										{/* <td>
											<Typography level='body-sm'>
												{evalRun.startedAt && evalRun.endedAt ?
													moment.duration(moment(evalRun.endedAt).diff(moment(evalRun.startedAt))).humanize()
													: '-'}
											</Typography>
										</td> */}
										<td>
											<Typography fontWeight={600} level='body-sm' color={score >= 80 ? 'success' : score >= 50 ? 'neutral' : 'danger'}>
												{score.toFixed(0)}%
											</Typography>
										</td>
										<td>
											<Typography level='body-sm'>
												<b>{numTests}</b>
												&nbsp;
												(
												<Typography level='body-sm' color='success'>
													{numPassed}
												</Typography>
												<Typography level='body-sm' color='neutral'>
													/
												</Typography>
												<Typography level='body-sm' color='danger'>
													{numFailed}
												</Typography>
												<Typography level='body-sm' color='neutral'>
													/
												</Typography>
												<Typography level='body-sm' color='warning'>
													{numNeedsReview}
												</Typography>
												<Typography level='body-sm' color='neutral'>
													/
												</Typography>
												<Typography level='body-sm' color='neutral'>
													{numPending}
												</Typography>
												)
											</Typography>
										</td>
										<td>
											<IconButton
												size='sm'
												variant='plain'
												color='neutral'
												onClick={(e) => {
													e.stopPropagation();
													API.archiveEval(evalRun.publicId).then(() => {
														setEvalRuns(evalRuns.filter(er => er.publicId !== evalRun.publicId));
													});
												}}
											>
												<TrashIcon width={16} height={16} />
											</IconButton>
										</td>
									</UsageRow>
								);
							})}
						</tbody>
					</table>
				</Box>
			</Box>
		</DashboardContainer>
	);
};

export default EvalRunsPage;
