import React, { useContext, useEffect, useState } from 'react';
import Main from '@/components/Main';
import Header from '@/components/Header';
import { Typography, Box, Grid, Input, Button, Skeleton, Table, Chip, Divider, ToggleButtonGroup, Textarea } from '@mui/joy';
import { Fade, Grow, Paper, Slide, Zoom } from '@mui/material';
import moment from 'moment'; import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from '@emotion/styled';
import DashboardContainer from '@/components/DashboardContainer';
import { NavLink } from 'react-router-dom';
import API from '@/api';
import { AppContext } from '@/context/AppContext';
import StartTrainerSessionModal from '@/components/StartTrainerSessionModal';
import StartOfflineSessionModal from '@/components/StartOfflineSessionModal';
import { Helmet } from 'react-helmet';
import LogoIcon from '../icons/LogoIcon';
import Footer from '../components/Footer';
import CursorPointerIcon from '@/icons/CursorPointerIcon';
import gdbEvalSrc from '@/public/gdb-evals.png';
import karpathyEvalSrc from '@/public/karpathy-evals.png';

const Section = styled(Box)`
  padding: 1rem;
`;

const UsageRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? '#f0f0f0' : 'transparent'};
`;

const StyledTypography = styled(Typography)`
  font-family: Lora, serif;
`;

const RadialGradient = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	height: 100%;
	mask-image: radial-gradient(ellipse at center,transparent 1%,#000 90%);
	background-color: white;
`;

const PageContainer = styled(Box)`
  background-color: #ffffff;
	background-size: 96px;
	background-image: linear-gradient(to right, #d9d9d9 1px, transparent 1px);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative;
`;

const ContentContainer = styled(Box)`
  max-width: calc(100% - 1rem);
  width: 800px;
  background-color: #fafafa;
	padding: 1.5rem;
	border-radius: 8px;
	border: 1px solid #E4E4E4;
	box-shadow: 0 2px inset var(--joy-palette-third-shadowHighColor), var(--joy-shadow-md);
  z-index: 100;
`;

const PitchNotePage: React.FC = () => {
	return (
		<div>
			<PageContainer>
				<Helmet>
					<title>Plato Pitch Memo</title>
				</Helmet>
				<RadialGradient />
				<Grow in>
					<ContentContainer boxShadow='md'>
						<NavLink to='/'>
							<LogoIcon style={{ borderRadius: '6px', marginBottom: '4px' }} width='38px' height='38px' />
						</NavLink>
						<StyledTypography level='h1' fontSize={36}>Verifiable Agentic Systems.</StyledTypography>
						<Box marginBottom={2} marginTop={2}>
							<StyledTypography level='body-md'>
                We beleive verification is the most important part towards building better agents. An agent that completes only 80% of the tasks but with 100% confidence is better than an agent that completes 100% of the same tasks but with only 80% confidence.
							</StyledTypography>
							<br />
							<StyledTypography level='body-md'>
							This is why we&apos;re not actually building an agent, but rather an <b>agent verifier</b>.
							</StyledTypography>
						</Box>
						<Box marginBottom={2} marginTop={4}>
							<StyledTypography level='h3' marginBottom={1}>
							First things first, better public benchmarks for web agents
							</StyledTypography>
							<StyledTypography level='body-md'>
              Web agents have seen great progress in 2024 ending the year with DeepMind&apos;s Project Mariner achieving <a href='https://deepmind.google/technologies/project-mariner/' target='_blank' rel='noopener noreferrer'>a score of 90.5%</a> on the WebVoyager benchmark.
              Existing benchmarks such as WebVoyager and WebArena are an okay starting point to get web agents working for basic UI interactions like applying search filters and clicking buttons, but have major shortcomings including <b>lack of ground truth answers</b>, or <b>unrealistic tasks and websites</b>.
								<br/><br/>
              This is why our first project is building a successor to WebVoyager, which will include a diverse set of tasks that are evaulated in simulated web environments <i>exactly</i> like the real ones. This allows us to provide <b>consistent ground truth answers</b> and instant results. The dataset will be opensource and can run easily on Plato.
							</StyledTypography>
						</Box>
						<Box marginBottom={2} marginTop={4}>
							<StyledTypography level='h3' marginBottom={1}>
							  A data platform for training and evaluating agents
							</StyledTypography>
							<StyledTypography level='body-md'>
                After basic UI interactions are solved, the next challenge is building agents that can solve specific long horizon tasks in a way that aligns with the user&apos;s intentions. This is hard because it involves collecting, understanding, indexing, and retreiving data for any task.
                Our solution includes 3 core components:
								<br/><br/>
								<b>Demostration collection</b><br/>
                Collecting demonstrations from the agent in the browser for a specific task, website, or workflow
								<br/><br/>
								<b>Agent verifier & evals</b><br/>
                A verifier is created which ensures the agent is behaving in a way aligned with demonstrations. Evaluations are automatically generated to ensure the agent can solve the tasks which were demonstrated.
								<br/><br/>
								<b>Continous monitoring & iteration</b><br/>
                As the agent encounters situations unseen from demonstrations, it will require a human to demonstrate how to handle it.
							</StyledTypography>
						</Box>
						<Box marginBottom={2} marginTop={4}>
							<StyledTypography level='h3' marginBottom={1}>
							Why we&apos;re working on this
							</StyledTypography>
							<StyledTypography level='body-md'>
                We&apos;re excited to work on this not only because it&apos;s a much needed product, but more importantly because it&apos;s the <b>most important</b> and very difficult step towards advancing the entire field of agents.
							</StyledTypography>
							<Box display='flex' flexDirection='row' gap={2} marginTop={2} marginBottom={2}>
								<Box width='50%'>
									<img src={gdbEvalSrc} alt='GDP Evals' style={{ width: '100%' }} />
								</Box>
								<Box width='50%'>
									<img src={karpathyEvalSrc} alt='Karpathy Evals' style={{ width: '100%' }} />
								</Box>
							</Box>
							<StyledTypography level='body-md'>
                We both worked on teams trying to build generalizable web agents and first hand came to this conclusion.
							</StyledTypography>
						</Box>
						{/* <Box marginBottom={2} marginTop={4} width='100%'>
							<Box display='grid' gridTemplateColumns='1fr 1fr 1fr' gap={0} width='100%'>
								<Box display='flex' flexDirection='column' alignItems='center' borderRight='1px solid #E4E4E4' p={2}>
									<StyledTypography level='body-md'>
                    Collect demonstrations
									</StyledTypography>
									<Box width='100%' p={2} position='relative'>
										<Box display='flex' justifyContent='center' alignItems='center' sx={{ transform: 'scale(0.75)' }}>
											<Button size='sm' variant='outlined' color='neutral'>
                        Submit
											</Button>
											<CursorPointerIcon width='24px' height='24px' style={{ position: 'absolute', top: '90%', left: '65%', transform: 'translate(-50%, -50%)' }} />
										</Box>
									</Box>
								</Box>
								<Box display='flex' flexDirection='column' alignItems='center' borderRight='1px solid #E4E4E4' p={2}>
									<StyledTypography level='body-md'>
                    Construct verifier
									</StyledTypography>
								</Box>
								<Box display='flex' flexDirection='column' alignItems='center' p={2}>
									<StyledTypography level='body-md'>
                  Monitor, iterate, and improve
									</StyledTypography>
								</Box>
							</Box>
						</Box> */}

						<Box marginBottom={2} marginTop={4}>
							<StyledTypography level='h3' marginBottom={1}>
							Who we&apos;re looking to work with
							</StyledTypography>
							<StyledTypography level='body-md'>
                We&apos;re currently focused on providing great data & evaluation tooling for startups and researchers building web agents and partnerships with companies building agent frameworks. We are making it as easy as possible to train and evaluate agents.
							</StyledTypography>
						</Box>


						<Box marginBottom={2} marginTop={4}>
							<StyledTypography level='body-md'>
                If you&apos;re interested in working with us, or excited about what we&apos;re building, please reach out!
							</StyledTypography>
						</Box>

						<Box display='flex' flexDirection='row' gap={2}>
							<a href='https://cal.com/rob-plato/30min' target='_blank' rel='noreferrer'>
								<Button variant='shadowed' color='secondary' size='sm'>Our calendar is open</Button>
							</a>
							<a href='mailto:rob@plato.so'>
								<Button variant='shadowed' color='third' size='sm'>Or email us</Button>
							</a>
						</Box>

						<Box marginBottom={2} marginTop={4}>
							<StyledTypography level='body-md'>
							- Rob & Pranav, Founders of Plato
							</StyledTypography>
						</Box>
					</ContentContainer>
				</Grow>
			</PageContainer>
			<Footer />
		</div>
	);
};

export default PitchNotePage;
