import { Box, Button, FormControl, FormLabel, Input, Modal, ModalClose, ModalDialog, Option, Select, Stack, Textarea, Typography } from '@mui/joy';
import React, { useState } from 'react';
import API from '../api';

interface CreateAgentArtifactModalProps {
    open: boolean;
    onClose: (created?: boolean) => void;
}

interface AgentTypeConfig {
    emoji: string;
    description: string;
    longDescription: string;
}

const AGENT_TYPE_CONFIG: Record<'human' | 'dev' | 'git_container', AgentTypeConfig> = {
    human: {
        emoji: '👤',
        description: 'A human agent that can interact with the system',
        longDescription: 'A human agent that can interact with the system and provide responses'
    },
    dev: {
        emoji: '👨‍💻',
        description: 'A agent accessible via an endpoint. Typically used for quick development and testing.',
        longDescription: 'A agent accessible via an endpoint. Typically used for quick development and testing.'
    },
    git_container: {
        emoji: '📦',
        description: 'Agent build from a git repository. Ensure the repoistory has the correct permissions and can be built by docker successfully.',
        longDescription: 'Agent build from a git repository. Ensure the repoistory has the correct permissions and can be built by docker successfully.'
    }
};

const convertEmojiToBase64 = (emoji: string): string => {
    const canvas = document.createElement('canvas');
    canvas.width = 128;
    canvas.height = 128;
    const ctx = canvas.getContext('2d');
    if (ctx) {
        // Clear the canvas with transparency instead of white
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = 'black';
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        ctx.font = '96px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(emoji, canvas.width / 2, canvas.height / 2);
        return canvas.toDataURL('image/png', 1.0);
    }
    return emoji;
};

const CreateAgentArtifactModal: React.FC<CreateAgentArtifactModalProps> = ({ open, onClose }) => {
    const [alias, setAlias] = useState('');
    const [type, setType] = useState<'human' | 'dev' | 'git_container'>('human');
    const [description, setDescription] = useState('');
    const [imgUrl, setImgUrl] = useState('👤');
    const [imgDisplay, setImgDisplay] = useState(() => convertEmojiToBase64('👤'));
    const [humanName, setHumanName] = useState('');
    const [endpoint, setEndpoint] = useState('');
    const [repoUrl, setRepoUrl] = useState('');
    const [branch, setBranch] = useState('');
    const [commitHash, setCommitHash] = useState('');

    const handleTypeChange = (newType: 'human' | 'dev' | 'git_container') => {
        setType(newType);
        handleIconChange(AGENT_TYPE_CONFIG[newType].emoji);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await API.createAgentArtifact({
                alias,
                type,
                description,
                imgUrl: imgDisplay || undefined,
                humanName: type === 'human' ? humanName : undefined,
                endpoint: type === 'dev' ? endpoint : undefined,
                repoUrl: type === 'git_container' ? repoUrl : undefined,
                branch: type === 'git_container' ? branch : undefined,
                commitHash: type === 'git_container' ? commitHash : undefined,
            });
            onClose(true);
        } catch (error) {
            console.error('Failed to create agent artifact:', error);
        }
    };

    const handleIconChange = (value: string) => {
        setImgUrl(value);

        // Check if input is a URL
        const isUrl = /^(http|https):\/\/[^ "]+$/.test(value);

        if (!isUrl) {
            setImgDisplay(convertEmojiToBase64(value));
        } else {
            setImgDisplay(value);
        }
    };

    return (
        <Modal open={open} onClose={() => onClose()}>
            <ModalDialog sx={{
                p: 2,
                backgroundColor: 'rgba(250, 250, 250, 0.75)',
                backdropFilter: 'blur(4px)',
                width: '500px',
                boxShadow: '0 1px inset var(--joy-palette-third-shadowHighColor)',
                maxHeight: '90vh',
                overflow: 'hidden',
            }}>
                <ModalClose />
                <Typography level='h4'>Create Agent Artifact</Typography>
                <form onSubmit={handleSubmit} style={{
                    height: 'calc(90vh - 120px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Box sx={{
                        overflowY: 'auto',
                        flex: '1 1 auto',
                        pr: 2,
                        mr: -2,
                    }}>
                        <Stack spacing={2} sx={{ mt: 1 }}>
                            <FormControl required>
                                <FormLabel>Type</FormLabel>
                                <Select
                                    value={type}
                                    onChange={(_, value) => value && handleTypeChange(value)}
                                >
                                    <Option value='human'>Human</Option>
                                    <Option value='dev'>Dev</Option>
                                    <Option value='git_container'>Git Container</Option>
                                </Select>
                                <Typography level='body-xs' sx={{ mt: 0.5, color: 'text.tertiary' }}>
                                    {AGENT_TYPE_CONFIG[type].longDescription}
                                </Typography>
                            </FormControl>

                            <FormControl required>
                                <FormLabel>Alias</FormLabel>
                                <Input
                                    value={alias}
                                    onChange={(e) => setAlias(e.target.value)}
                                    placeholder='Enter alias'
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder='Enter description (optional)'
                                    minRows={2}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Icon</FormLabel>
                                <Input
                                    value={imgUrl}
                                    onChange={(e) => handleIconChange(e.target.value)}
                                    placeholder='Enter an image URL or emoji'
                                />
                                <Typography level='body-xs' sx={{ color: 'text.tertiary' }}>
                                    You can enter either a valid image URL or an emoji character
                                </Typography>
                                {imgDisplay && (
                                    <Box mt={1} display='flex' justifyContent='center'>
                                        <img
                                            src={imgDisplay}
                                            alt="Selected icon"
                                            style={{
                                                width: 32,
                                                height: 32,
                                                objectFit: 'contain',
                                                borderRadius: '4px'
                                            }}
                                        />
                                    </Box>
                                )}
                            </FormControl>

                            {type === 'human' && (
                                <FormControl required>
                                    <FormLabel>Human Name</FormLabel>
                                    <Input
                                        value={humanName}
                                        onChange={(e) => setHumanName(e.target.value)}
                                        placeholder='Enter human name'
                                    />
                                </FormControl>
                            )}

                            {type === 'dev' && (
                                <FormControl required>
                                    <FormLabel>Endpoint</FormLabel>
                                    <Input
                                        value={endpoint}
                                        onChange={(e) => setEndpoint(e.target.value)}
                                        placeholder='Enter endpoint URL'
                                    />
                                </FormControl>
                            )}

                            {type === 'git_container' && (
                                <>
                                    <FormControl required>
                                        <FormLabel>Repository URL</FormLabel>
                                        <Input
                                            value={repoUrl}
                                            onChange={(e) => setRepoUrl(e.target.value)}
                                            placeholder='Enter repository URL'
                                        />
                                    </FormControl>

                                    <FormControl required>
                                        <FormLabel>Branch</FormLabel>
                                        <Input
                                            value={branch}
                                            onChange={(e) => setBranch(e.target.value)}
                                            placeholder='Enter branch name'
                                        />
                                    </FormControl>

                                    <FormControl required>
                                        <FormLabel>Commit Hash</FormLabel>
                                        <Input
                                            value={commitHash}
                                            onChange={(e) => setCommitHash(e.target.value)}
                                            placeholder='Enter commit hash'
                                        />
                                    </FormControl>
                                </>
                            )}
                        </Stack>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        gap: 1,
                        justifyContent: 'flex-end',
                        mt: 2,
                        pt: 2,
                        borderTop: '1px solid',
                        borderColor: 'divider',
                    }}>
                        <Button variant='plain' color='neutral' onClick={() => onClose()}>
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            color='secondary'
                            variant='solid'
                        >
                            Create
                        </Button>
                    </Box>
                </form>
            </ModalDialog>
        </Modal>
    );
};

export default CreateAgentArtifactModal;