import React, { createContext, useEffect, useState } from 'react';
import API from '../api';
import moment from 'moment';
import { usePostHog } from 'posthog-js/react';

// Create the AppContext
export const AppContext = createContext<AppContextType>(null);

interface AppContextType {
	user: {
		loaded: boolean;
		user: any;
		project: any;
		projects: any[];
	},
	snackbarQueue: SnackbarQueueItem[];
	addSnackbar: (snackbar: SnackbarQueueItem) => void;
	setSnackbarQueue: (snackbarQueue: SnackbarQueueItem[]) => void;
}

export interface SnackbarQueueItem {
	content: React.ReactNode;
	severity: 'success' | 'error' | 'info' | 'warning';
}

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
	const [user, setUser] = useState({
		loaded: false,
		user: null,
		project: null,
		projects: [],
	});
	const [snackbarQueue, setSnackbarQueue] = useState<SnackbarQueueItem[]>([]);
	const posthog = usePostHog();

	const addSnackbar = (snackbar: SnackbarQueueItem) => {
		setSnackbarQueue((prev) => [...prev, snackbar]);
	};

	useEffect(() => {
		API.getUserFromSession().then(({ user: userData, project, projects }) => {
			console.log(userData);
			if (userData?.email) {
				localStorage.setItem('user_loaded', 'true');
			} else {
				localStorage.removeItem('user_loaded');
			}
			setUser({
				loaded: true,
				user: userData,
				project,
				projects,
			});
			posthog.identify(
				userData?.publicId,
				{
					email: userData?.email,
					name: userData?.name,
				}
			);
		});
	}, []);

	return (
		<AppContext.Provider
			value={{
				user,
				snackbarQueue,
				addSnackbar,
				setSnackbarQueue,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};
