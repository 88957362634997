import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Typography, Chip, Box, Breadcrumbs, Link, IconButton, Divider, Dropdown, Menu, MenuItem, MenuButton, Slider, Input, ColorPaletteProp } from '@mui/joy';
import DashboardContainer from '../components/DashboardContainer';
import API from '@/api';
import { AppContext } from '@/context/AppContext';
import moment from 'moment';
import styled from '@emotion/styled';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@/icons/ArrowBackIcon';
import CloseIcon from '@/icons/CloseIcon';
import { EditorView } from '@codemirror/view';
import ThreedotsIcon from '@/icons/ThreedotsIcon';
import ReloadIcon from '@/icons/ReloadIcon';
import { formatDuration } from '@/util';
import ReactMarkdown from 'react-markdown';
import ReactCodeMirror from '@uiw/react-codemirror';
import { getStatus } from './EvalTestPage';
import { Helmet } from 'react-helmet';


export const customTheme = EditorView.theme({
	// '.cm-content': {
	// 	backgroundColor: 'var(--joy-palette-neutral-100)',
	// },
	// '&': {
	// 	backgroundColor: 'var(--joy-palette-neutral-100)',
	// },
	// '.cm-activeLine': {
	// 	backgroundColor: 'transparent',
	// },
	// '.cm-activeLineGutter': {
	// 	backgroundColor: 'transparent',
	// },
	// '.cm-gutters': {
	// 	backgroundColor: 'var(--joy-palette-neutral-100)',
	// },
});

const UsageRow = styled.tr<{ active: boolean }>`
	cursor: pointer;
	background-color: ${({ active }) => active ? 'var(--joy-palette-neutral-100)' : 'transparent'};
	&:hover {
		background-color: var(--joy-palette-neutral-50);
	}
`;

const EvalRunPage: React.FC = () => {
	const navigate = useNavigate();
	const [evalRun, setEvalRun] = useState<any>(null);
	const [selectedTestCaseRun, setSelectedTestCaseRun] = useState<any>(null);
	const [selectedTestCaseRunSteps, setSelectedTestCaseRunSteps] = useState<any>(null);
	const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
	const { evalRunId } = useParams();
	const iframeContainerRef = useRef<HTMLDivElement>(null);
	const [iframeZoom, setIframeZoom] = useState(0.5);
	const [annotatorReasoning, setAnnotatorReasoning] = useState<string>('');

	useEffect(() => {
		if (iframeContainerRef.current) {
			const handleResize = () => {
				setIframeZoom((iframeContainerRef.current.clientWidth - 16) / 1600);
			};

			handleResize();

			window.addEventListener('resize', handleResize);

			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}
	}, [iframeContainerRef]);

	useEffect(() => {
		if (!evalRunId) {
			return;
		}
		API.getEvalRun(evalRunId).then(data => {
			// sort by score: first 0, then null/undefined, then ascending order
			data.evalTestCaseRuns = data.evalTestCaseRuns.sort((a: any, b: any) => new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime());
			data.evalTestCaseRuns = data.evalTestCaseRuns.sort((a: any, b: any) => new Date(b.endedAt).getTime() - new Date(a.endedAt).getTime());
			data.evalTestCaseRuns = data.evalTestCaseRuns.sort((a: any, b: any) => {
				if (a.score === null && b.score === null) {
					return new Date(b.endedAt).getTime() - new Date(a.endedAt).getTime();
				}
				if (a.score === null) return -1;
				if (b.score === null) return 1;
				if (a.score === 0 && b.score === 0) {
					return new Date(b.endedAt).getTime() - new Date(a.endedAt).getTime();
				}
				if (a.score === 0) return -1;
				if (b.score === 0) return 1;
				if (a.score === b.score) {
					return new Date(b.endedAt).getTime() - new Date(a.endedAt).getTime();
				}
				return a.score - b.score;
			});
			setEvalRun(data);
			setSelectedTestCaseRun(data.evalTestCaseRuns[0]);
			console.log('evalRun', data);
		});
	}, [evalRunId]);

	useEffect(() => {
		setCurrentStepIndex(0);
		setAnnotatorReasoning(selectedTestCaseRun?.annotatorReasoning || '');
	}, [selectedTestCaseRun?.id]);


	return (
		<DashboardContainer showTitleBar={false} titleBar={{ title: 'Eval Runs' }} mainProps={{ style: { padding: 0, overflowY: 'hidden', height: '100vh', display: 'flex', flexDirection: 'column' } }}>
			<Helmet>
				<title>Plato Eval Run - {evalRun?.testCaseSet?.name || ''}</title>
			</Helmet>
			<Box p={2} py={1.5} display='flex' alignItems='center' gap={2}>
				<IconButton size='sm' variant='outlined' color='neutral' onClick={() => navigate(-1)} sx={{ minWidth: '28px', minHeight: '28px' }}>
					<ArrowBackIcon fill='currentColor' width={12} height={12} />
				</IconButton>

				<Box display='flex' flexDirection='row' alignItems='baseline' gap={1}>
					{evalRun?.testCaseSet ? (
						<>
							<Typography level='h4'>{evalRun.testCaseSet.name}</Typography>
							<Typography level='body-sm' color='neutral' fontWeight='regular'>{moment(evalRun?.startedAt).format('MMM DD, YYYY hh:mm a')}</Typography>
						</>
					) : (
						<>
							<Typography level='h4'>Eval Run</Typography>
							<Typography level='body-sm' color='neutral' fontWeight='regular'>{moment(evalRun?.startedAt).format('MMM DD, YYYY hh:mm a')}</Typography>
						</>
					)}
				</Box>
				<Box flexGrow={1} display='flex' justifyContent='flex-end'>
					<Button size='sm' variant='shadowed' color='secondary' onClick={() => API.enqueuePendingEvalTestCaseRuns(evalRunId)}>
						Run All Pending
					</Button>
				</Box>
			</Box>
			<Box display='flex' flexDirection='row' style={{ borderTop: '1px solid #e4e4e4', height: '100%', overflowY: 'auto' }}>
				<Box width='100%' sx={{ overflowX: 'auto' }}>
					<table>
						<thead>
							<tr>
								{/* <th><Typography level='title-sm' color='neutral'>Status</Typography></th> */}
								<th><Typography level='title-sm' color='neutral'>Score</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Finished At</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Duration</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Site</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Type</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Task</Typography></th>
								{/* <th style={{ textAlign: 'right', width: '100px' }} /> */}
							</tr>
						</thead>
						<tbody>
							{evalRun?.evalTestCaseRuns.map((testCaseRun: any) => (
								<UsageRow key={testCaseRun.id} active={selectedTestCaseRun?.id === testCaseRun.id} onClick={() => setSelectedTestCaseRun(testCaseRun)}>
									{/* <td>
										<Box display='flex' alignItems='center' gap={1}>
											<Chip size='sm' color={getStatus(testCaseRun).color as ColorPaletteProp}>
												{getStatus(testCaseRun).text}
											</Chip>
										</Box>
									</td> */}
									<td>
										{testCaseRun.score !== null ? (
											<Chip size='sm' variant='soft' color={testCaseRun.score === 1 ? 'success' : 'danger'}>
												{testCaseRun.score * 100}
											</Chip>
										) : (
											<Chip size='sm' color='warning'>
												Review
											</Chip>
										)}
									</td>
									<td>
										{testCaseRun.endedAt ? (
											<Typography level='body-sm' sx={{ whiteSpace: 'nowrap' }}>{moment(testCaseRun.endedAt).format('MMM DD, YYYY hh:mm a')}</Typography>
										) : (
											<Chip size='sm' color={getStatus(testCaseRun).color as ColorPaletteProp}>
												{getStatus(testCaseRun).text}
											</Chip>
										)}
									</td>
									<td>
										{testCaseRun.endedAt ? (
											<Typography level='body-sm'>{formatDuration(moment.duration(moment(testCaseRun.endedAt).diff(moment(testCaseRun.startedAt)))) || '0s'}</Typography>
										) : (
											<Typography level='body-sm'>-</Typography>
										)}
									</td>
									<td>
										<Box display='flex' alignItems='center' gap={1}>
											<img width={16} src={testCaseRun.testCase.site.imgUrl} alt={testCaseRun.testCase.site.name} />
											<Typography level='body-md'>{testCaseRun.testCase.site.name}</Typography>
										</Box>
									</td>
									<td>
										<Typography level='body-sm'>{testCaseRun.testCase.evaluationMode === 'output' ? 'Extract' : 'Act'}</Typography>
									</td>
									<td>
										<Typography level='body-xs'>{testCaseRun.testCase.prompt}</Typography>
									</td>
									{/* <td style={{ textAlign: 'right', width: '100px' }}>
										<NavLink to={`/sessions/${testCaseRun.browserSessionPublicId}`}>
											<Button size='sm' variant='shadowed' color='third'>
											Debug
											</Button>
										</NavLink>
									</td> */}
								</UsageRow>
							))}
						</tbody>
					</table>
				</Box>
				{Boolean(selectedTestCaseRun) && (
					<Box sx={{ borderLeft: '1px solid #e4e4e4', minWidth: '500px', maxWidth: '500px', maxHeight: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
						<Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' gap={2} p={2} py={1} borderBottom='1px solid #e4e4e4'>
							<Typography level='body-xs'>{selectedTestCaseRun.publicId}</Typography>
							<Box display='flex' flexDirection='row' alignItems='center' gap={1}>
								<NavLink to={`/eval-test/${selectedTestCaseRun.publicId}`}>
									<Button size='sm' variant='outlined' color='neutral'>
										Inspect
									</Button>
								</NavLink>
								<IconButton size='sm' variant='outlined' color='neutral' onClick={() => API.enqueueEvalTestCaseRun(selectedTestCaseRun.publicId, selectedTestCaseRun.testCase.id)} sx={{ minWidth: '28px', minHeight: '28px' }}>
									<ReloadIcon fill='currentColor' width={10} height={10} />
								</IconButton>
								<Dropdown>
									<MenuButton slots={{ root: IconButton }} slotProps={{ root: { size: 'sm', variant: 'outlined', color: 'neutral', sx: { minWidth: '28px', minHeight: '28px' } } }}>
										<ThreedotsIcon fill='currentColor' width={10} height={10} />
									</MenuButton>
								</Dropdown>
								<IconButton size='sm' variant='outlined' color='neutral' onClick={() => setSelectedTestCaseRun(null)} sx={{ minWidth: '28px', minHeight: '28px' }}>
									<CloseIcon fill='currentColor' width={10} height={10} />
								</IconButton>
							</Box>
						</Box>
						<Box display='flex' flexDirection='column'>
							<Box p={2} marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='flex-start' rowGap={1} columnGap={2} margin={0}>
								<Typography color='neutral' level='title-sm'>Type</Typography>
								<Typography level='body-sm'>{selectedTestCaseRun?.eval?.options?.environment}</Typography>
								<Typography color='neutral' level='title-sm'>Status</Typography>
								<Chip size='sm' variant='soft' color={getStatus(selectedTestCaseRun).color as ColorPaletteProp}>{getStatus(selectedTestCaseRun).text}</Chip>
								<Typography color='neutral' level='title-sm' whiteSpace='nowrap'>Started At</Typography>
								{selectedTestCaseRun?.startedAt ? (
									<Typography level='body-sm'>{moment(selectedTestCaseRun?.startedAt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
								) : (
									<Typography level='body-sm'>-</Typography>
								)}
								<Typography color='neutral' level='title-sm' whiteSpace='nowrap'>Ended At</Typography>
								{selectedTestCaseRun?.endedAt ? (
									<Typography level='body-sm'>{moment(selectedTestCaseRun?.endedAt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
								) : (
									<Typography level='body-sm'>-</Typography>
								)}
								<Typography color='neutral' level='title-sm'>Duration</Typography>
								{selectedTestCaseRun?.startedAt && selectedTestCaseRun?.endedAt ? (
									<Typography level='body-sm'>{formatDuration(moment.duration(moment(selectedTestCaseRun?.endedAt).diff(moment(selectedTestCaseRun?.startedAt)))) || '0s'}</Typography>
								) : (
									<Typography level='body-sm'>-</Typography>
								)}
								<Typography color='neutral' level='title-sm' sx={{ whiteSpace: 'nowrap' }}>Session ID</Typography>
								<Typography level='body-sm'>{selectedTestCaseRun?.sessionId}</Typography>
							</Box>
							<Divider />
							<Box p={2} marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='flex-start' rowGap={1} columnGap={2} margin={0}>
								<Typography sx={{ whiteSpace: 'nowrap' }} color='neutral' level='title-sm'>Testcase</Typography>
								<Typography level='body-sm'>{selectedTestCaseRun?.testCase?.name}</Typography>

								<Typography color='neutral' whiteSpace='nowrap' level='title-sm'>Start URL</Typography>
								<Typography level='body-sm'>{selectedTestCaseRun?.testCase?.startUrl}</Typography>

								<Typography color='neutral' whiteSpace='nowrap' level='title-sm'>Prompt</Typography>
								<Typography level='body-sm'>{selectedTestCaseRun?.testCase?.prompt}</Typography>

								<Typography color='neutral' whiteSpace='nowrap' level='title-sm'>Evaluation Mode</Typography>
								<Typography level='body-sm'>{selectedTestCaseRun?.testCase?.evaluationMode}</Typography>

							</Box>
							<Divider />
							<Box p={2} marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='flex-start' rowGap={1} columnGap={2} margin={0}>
								<Typography sx={{ whiteSpace: 'nowrap' }} color='neutral' level='title-sm'>Score</Typography>
								<Typography level='body-sm'>{selectedTestCaseRun?.score !== null ? selectedTestCaseRun?.score * 100 : '-'}</Typography>

								<Typography color='neutral' whiteSpace='nowrap' level='title-sm'>Score Reasoning</Typography>
								<Typography level='body-sm'>{selectedTestCaseRun?.annotatorReasoning || '-'}</Typography>


							</Box>
							{selectedTestCaseRun?.testCase?.evaluationMode === 'output' && (
								<>
									<Divider />
									<Box padding={2}>
										{/* <ToggleButtonGroup value={view} onChange={(e, v) => setView(v)}>
									<Button value='output' size='sm' color={view === 'output' ? 'secondary' : 'neutral'} variant={view === 'output' ? 'solid' : 'outlined'}>Output</Button>
									<Button value='logs' size='sm' color={view === 'logs' ? 'secondary' : 'neutral'} variant={view === 'logs' ? 'solid' : 'outlined'}>Logs</Button>
								</ToggleButtonGroup> */}
										<Box display='flex' flexDirection='column' gap={2}>
											<Box display='flex' flexDirection='column' gap={1}>
												<Typography color='neutral' level='title-sm'>Output</Typography>
												<Box borderRadius='5px' px='4px' sx={{ fontSize: '12px', '& p': { marginBlockStart: '4px', marginBlockEnd: '4px' } }} border='1px solid #e4e4e4' bgcolor='var(--joy-palette-neutral-100)' overflow='hidden'>
													{selectedTestCaseRun?.output?.output !== undefined ? (
														<ReactMarkdown>{selectedTestCaseRun?.output?.output || 'null'}</ReactMarkdown>
													) : (
														selectedTestCaseRun?.output?.message ? (
															<ReactMarkdown>{selectedTestCaseRun?.output?.message}</ReactMarkdown>
														) : (
															<ReactMarkdown>{selectedTestCaseRun?.output}</ReactMarkdown>
														)
													)}
												</Box>
											</Box>
										</Box>
									</Box>
									<Box padding={2}>
										{/* <ToggleButtonGroup value={view} onChange={(e, v) => setView(v)}>
									<Button value='output' size='sm' color={view === 'output' ? 'secondary' : 'neutral'} variant={view === 'output' ? 'solid' : 'outlined'}>Output</Button>
									<Button value='logs' size='sm' color={view === 'logs' ? 'secondary' : 'neutral'} variant={view === 'logs' ? 'solid' : 'outlined'}>Logs</Button>
								</ToggleButtonGroup> */}
										<Box display='flex' flexDirection='column' gap={2}>
											<Box display='flex' flexDirection='column' gap={1}>
												<Typography color='neutral' level='title-sm'>Expected Output</Typography>
												{selectedTestCaseRun?.testCase?.correctOutputs?.[0] ? (
													<Box borderRadius='5px' px='4px' sx={{ fontSize: '12px', '& p': { marginBlockStart: '4px', marginBlockEnd: '4px' } }} border='1px solid #e4e4e4' bgcolor='var(--joy-palette-neutral-100)' overflow='hidden'>
														{/* <ReactCodeMirror
													value={JSON.stringify(selectedTestCaseRun?.testCase?.correctOutputs[0], null, 2)}
													readOnly
													editable={false}
													style={{ fontSize: '12px' }}
													extensions={[EditorView.lineWrapping, customTheme]}
												/> */}
														<ReactMarkdown>{selectedTestCaseRun?.testCase?.correctOutputs?.[0]}</ReactMarkdown>
													</Box>
												) : (
													<Typography fontStyle='italic' level='body-sm'>No expected output set</Typography>
												)}
											</Box>
										</Box>
									</Box>
								</>
							)}

							{/* {selectedTestCaseRun?.startedAt && !selectedTestCaseRun?.endedAt && (
								<Box
									ref={iframeContainerRef}
									style={{
										padding: '8px',
										backgroundColor: '#fafafa',
										backgroundImage: 'linear-gradient(to right, #d9d9d9 1px, transparent 1px), linear-gradient(to bottom, #d9d9d9 1px, transparent 1px)',
										backgroundSize: '12px 12px',
									}}
								>
									<Box
										boxShadow='md'
										sx={{
											borderRadius: '5px',
											overflow: 'hidden',
											position: 'relative',
											border: '1px solid #e4e4e4',
											width: '100%',
											height: 0,
											paddingTop: 'calc(9/16 * 100%)',
										}}
									>
										<BrowserIFrame
											style={{
												position: 'absolute',
												width: '1600px',
												height: '900px',
												top: 0,
												left: 0,
												transform: `scale(${iframeZoom})`,
												transformOrigin: '0 0',
											}}
											browserSessionId={selectedTestCaseRun.browserSessionPublicId}
										/>
									</Box>
								</Box>
							)} */}
						</Box>
					</Box>
				)}
			</Box>
		</DashboardContainer>
	);
};

export default EvalRunPage;
