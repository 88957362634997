import React, { useContext } from 'react';
import { Typography, Box, Input, Button } from '@mui/joy';
import { AppContext } from '../context/AppContext';
import DashboardContainer from '../components/DashboardContainer';

const SettingsPage: React.FC = () => {
	const { user: { loaded, user } } = useContext(AppContext);


	const copyToClipboard = () => {
		navigator.clipboard.writeText(user?.organization?.apiKey);
	};

	return (
		<DashboardContainer showTitleBar titleBar={{ title: 'Settings' }} mainProps={{ style: { padding: 0 } }}>
			<Box p={4} display='flex' flexDirection='column' gap={2}>
				<Box display='flex' alignItems='center' gap={2}>
					<Box width='25%'>
						<Typography level='title-md'>API Key</Typography>
						<Typography level='body-sm'>Use your API key to access our API.</Typography>
					</Box>
					<Box flexGrow={1} display='flex'>
						{loaded && (
							<Input
								sx={{ width: '420px' }}
								onChange={() => {}}
								value={user?.organization?.apiKey}
								endDecorator={<Button onClick={copyToClipboard} size='sm' variant='shadowed' color='secondary'>Copy</Button>}
							/>
						)}
					</Box>
				</Box>
				<Box display='flex' alignItems='center' gap={2}>
					<Box width='25%'>
						<Typography level='title-md'>Run Task URL</Typography>
						<Typography level='body-sm'>Use this URL to run tasks.</Typography>
					</Box>
					<Box flexGrow={1} display='flex'>
						{loaded && (
							<Input
								sx={{ width: '420px' }}
								onChange={() => {}}
								placeholder={'https://api.yourapp.com/run-task'}
								value={user?.organization?.runTaskUrl}
							/>
						)}
					</Box>
				</Box>
			</Box>
		</DashboardContainer>
	);
};

export default SettingsPage;
