import CloseIcon from '@/icons/CloseIcon';
import styled from '@emotion/styled';
import { Avatar, AvatarGroup, Box, Button, IconButton, Typography } from '@mui/joy';
import { EditorView } from '@uiw/react-codemirror';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import API from '../api';
import CreateBenchmarkModal from '../components/CreateBenchmarkModal';
import CreateTestCaseModal from '../components/CreateTestCaseModal';
import DashboardContainer from '../components/DashboardContainer';
import PlayIcon from '../icons/PlayIcon';
import PlusIcon from '../icons/PlusIcon';
import TrashIcon from '../icons/TrashIcon';

const customTheme = EditorView.theme({
	// '.cm-content': {
	// 	backgroundColor: 'var(--joy-palette-neutral-100)',
	// },
	// '&': {
	// 	backgroundColor: 'var(--joy-palette-neutral-100)',
	// },
	// '.cm-activeLine': {
	// 	backgroundColor: 'transparent',
	// },
	// '.cm-activeLineGutter': {
	// 	backgroundColor: 'transparent',
	// },
	// '.cm-gutters': {
	// 	backgroundColor: 'var(--joy-palette-neutral-100)',
	// },
});


const Section = styled(Box)`
  padding: 1rem;
`;

const TableRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? 'var(--joy-palette-neutral-100)' : 'transparent'};
	&:hover {
		background-color: var(--joy-palette-neutral-50);
	}
`;

const BenchmarksPage: React.FC = () => {
	const [testCasesSets, setTestCasesSets] = useState<any[]>([]);
	const [selectedTestCaseSet, setSelectedTestCaseSet] = useState<any>(null);
	const [createBenchmarkModalOpen, setCreateBenchmarkModalOpen] = useState<boolean>(false);
	const [createTestCaseModalOpen, setCreateTestCaseModalOpen] = useState<boolean>(false);
	const [expandedTestCaseSet, setExpandedTestCaseSet] = useState<any>(null);
	const [selectedTestCase, setSelectedTestCase] = useState<any>(null);

	const fetchTestCases = async () => {
		const { testCaseSets } = await API.getTestCaseSets();
		setTestCasesSets(testCaseSets);
	};
	const handleArchiveTestCaseSet = async (testCaseSet: any) => {
		if (window.confirm(`Are you sure you want to archive "${testCaseSet.name}"?`)) {
			await API.deleteTestCaseSet(testCaseSet.id);
			setSelectedTestCaseSet(null);
			fetchTestCases();
		}
	};

	const handleRowClick = (testCaseSet: any) => {
		if (expandedTestCaseSet?.id === testCaseSet.id) {
			setExpandedTestCaseSet(null);
			setSelectedTestCaseSet(null);
		} else {
			setExpandedTestCaseSet(testCaseSet);
			setSelectedTestCaseSet(testCaseSet);
		}
	};

	const handleTestCaseClick = (e: React.MouseEvent, testCase: any) => {
		e.stopPropagation();
		setSelectedTestCase(testCase);
	};

	useEffect(() => {
		fetchTestCases();
	}, []);


	return (
		<DashboardContainer titleBar={{
			title: 'Benchmarks', actions: [
				<Button
					startDecorator={<PlusIcon width={12} height={12} fill='currentColor' />}
					variant="shadowed"
					color="secondary"
					onClick={() => setCreateBenchmarkModalOpen(true)}
				>
					Create
				</Button>
			]
		}} showTitleBar mainProps={{ style: { padding: 0 } }}>
			<Helmet>
				<title>Plato - Benchmarks</title>
			</Helmet>
			<CreateBenchmarkModal open={createBenchmarkModalOpen} onClose={(created) => {
				setCreateBenchmarkModalOpen(false);
				if (created) {
					fetchTestCases();
				}
			}} />
			<CreateTestCaseModal
				open={createTestCaseModalOpen}
				testCaseSet={selectedTestCaseSet}
				onClose={(created) => {
					setCreateTestCaseModalOpen(false);
					if (created) {
						fetchTestCases();
					}
				}}
			/>
			<Box display='flex' flexDirection='row' style={{ height: '100%' }}>
				<Box width='100%' sx={{ overflowX: 'auto' }}>
					<table>
						<thead>
							<tr>
								<th><Typography level='title-sm' color='neutral'>Set</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Sites</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Test Cases</Typography></th>
								<th style={{ textAlign: 'center' }}><Typography level='title-sm' color='neutral'>Add Test Case</Typography></th>
								<th style={{ textAlign: 'center' }}><Typography level='title-sm' color='neutral'>Archive Benchmark</Typography></th>
							</tr>
						</thead>
						<tbody>
							{testCasesSets.map((testCaseSet) => (
								<React.Fragment key={testCaseSet.id}>
									<TableRow
										active={expandedTestCaseSet?.id === testCaseSet.id}
										onClick={() => handleRowClick(testCaseSet)}
									>
										<td>
											<Box display='flex' alignItems='center' gap={1}>
												<img width={16} src={testCaseSet.imgUrl} alt={testCaseSet.name} />
												<Typography level='body-md'>{testCaseSet.name}</Typography>
											</Box>
										</td>
										<td>
											{!testCaseSet.testCases.length && (
												<Typography level='body-sm'>-</Typography>
											)}
											<AvatarGroup size='sm'>
												{Array.from(new Set(testCaseSet.testCases.map(tc => tc.site?.imgUrl)))
													.filter(Boolean)
													.map((siteImgUrl) => (
														<Avatar sx={{ width: 18, height: 18 }} size='sm' src={siteImgUrl as string} />
													))}
											</AvatarGroup>
										</td>
										<td>
											<Typography level='body-sm'>{testCaseSet.testCases.length}</Typography>
										</td>
										<td onClick={(e) => e.stopPropagation()} style={{ textAlign: 'center' }}>
											<IconButton
												size='sm'
												variant='outlined'
												color='neutral'
												onClick={() => {
													setSelectedTestCaseSet(testCaseSet);
													setCreateTestCaseModalOpen(true);
												}}
											>
												<PlusIcon width={14} height={14} />
											</IconButton>
										</td>
										<td onClick={(e) => e.stopPropagation()} style={{ textAlign: 'center' }}>
											<IconButton
												size='sm'
												variant='outlined'
												color='danger'
												onClick={(e) => {
													e.stopPropagation();
													handleArchiveTestCaseSet(testCaseSet);
												}}
											>
												<TrashIcon width={14} height={14} />
											</IconButton>
										</td>
									</TableRow>
									{expandedTestCaseSet?.id === testCaseSet.id && (
										<tr>
											<td colSpan={5}>
												<Box sx={{ backgroundColor: 'var(--joy-palette-neutral-50)', ml: 4 }}>
													<table style={{ width: '100%' }}>
														<thead>
															<tr>
																<th><Typography level='title-sm' fontWeight="bold" color='neutral'>Site</Typography></th>
																<th><Typography level='title-sm' fontWeight="bold" color='neutral'>Name</Typography></th>
																<th style={{ width: '40%' }}><Typography level='title-sm' fontWeight="bold" color='neutral'>Prompt</Typography></th>
																<th><Typography level='title-sm' fontWeight="bold" color='neutral'>URL</Typography></th>
																<th style={{ width: '120px', textAlign: 'center' }}><Typography level='title-sm' fontWeight="bold" color='neutral'>Actions</Typography></th>
															</tr>
														</thead>
														<tbody>
															{testCaseSet.testCases.map((testCase: any) => (
																<tr key={testCase.id}>
																	<td style={{ width: '40px' }}>
																		{testCase.startUrl && (
																			<Avatar
																				size="sm"
																				src={`https://www.google.com/s2/favicons?domain=${testCase.startUrl}&sz=32`}
																				sx={{ width: 20, height: 20 }}
																			/>
																		)}
																	</td>
																	<td style={{ width: '15%' }}>
																		<Typography level="body-sm">{testCase.name}</Typography>
																	</td>
																	<td>
																		<Typography level="body-sm" sx={{
																			whiteSpace: 'pre-wrap',
																			wordBreak: 'break-word'
																		}}>
																			{testCase.prompt}
																		</Typography>
																	</td>
																	<td style={{ width: '20%' }}>
																		<Typography level="body-sm" sx={{
																			maxWidth: '200px',
																			overflow: 'hidden',
																			textOverflow: 'ellipsis',
																			whiteSpace: 'nowrap'
																		}}>
																			{testCase.startUrl}
																		</Typography>
																	</td>
																	<td style={{ textAlign: 'center' }}>
																		<Box display="flex" gap={1} justifyContent="center">
																			<IconButton
																				size="sm"
																				variant="outlined"
																				color="neutral"

																				onClick={(e) => {
																					e.stopPropagation();
																					// Handle agent evaluation
																				}}
																			>
																				<PlayIcon width={14} height={14} />
																			</IconButton>
																			<IconButton
																				size="sm"
																				variant="outlined"
																				color="danger"
																				onClick={(e) => {
																					e.stopPropagation();
																					// TODO: Implement test case deletion
																					console.log('Delete test case:', testCase.id);
																				}}
																			>
																				<TrashIcon width={14} height={14} />
																			</IconButton>
																		</Box>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												</Box>
											</td>
										</tr>
									)}
								</React.Fragment>
							))}
						</tbody>
					</table>
				</Box>
				{Boolean(selectedTestCase) && (
					<Box sx={{ borderLeft: '1px solid #e4e4e4', minWidth: '400px', maxWidth: '400px' }}>
						<Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' gap={2} p={2} py={1} borderBottom='1px solid #e4e4e4'>
							<Typography level='body-xs'>{selectedTestCase?.name}</Typography>
							<IconButton
								size='sm'
								variant='outlined'
								color='neutral'
								onClick={() => setSelectedTestCase(null)}
								sx={{ minWidth: '28px', minHeight: '28px' }}
							>
								<CloseIcon fill='currentColor' width={14} height={14} />
							</IconButton>
						</Box>
						<Section>
							<Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='center' rowGap={1} columnGap={2} margin={0}>
								<Typography color='neutral' level='title-sm'>Name</Typography>
								<Typography level='body-sm'>{selectedTestCase?.name}</Typography>

								<Typography color='neutral' level='title-sm'>Site</Typography>
								<Box display='flex' flexDirection='row' alignItems='center' gap={1}>
									{selectedTestCase?.site?.imgUrl && (
										<Avatar size="sm" src={selectedTestCase.site.imgUrl} sx={{ width: 20, height: 20 }} />
									)}
									<Typography level='body-sm'>{selectedTestCase?.site?.name}</Typography>
								</Box>

								<Typography color='neutral' level='title-sm'>URL</Typography>
								<Typography level='body-sm'>{selectedTestCase?.startUrl}</Typography>

								<Typography color='neutral' level='title-sm'>Prompt</Typography>
								<Typography level='body-sm'>{selectedTestCase?.prompt}</Typography>
							</Box>
						</Section>
					</Box>
				)}
			</Box>
		</DashboardContainer>
	);
};

export default BenchmarksPage;

