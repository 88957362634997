import React, { useContext } from 'react';
import SideNavigation from './SideNavigation';
import styled from '@emotion/styled';
import { Box, ListItemDecorator, Option, Select, Typography } from '@mui/joy';
import { Helmet } from 'react-helmet';
import TopNavigation from './TopNavigation';
import { AppContext } from '@/context/AppContext';
import PlusIcon from '@/icons/PlusIcon';


interface DashboardContainerProps {
	children: React.ReactNode;
	mainProps?: React.HTMLAttributes<HTMLDivElement>;
	showTitleBar?: boolean;
	titleBar?: {
		title: string;
		actions?: React.ReactNode[];
	}
}

const Container = styled(Box)`
	height: 100vh;
	display: flex;
	flex-direction: row;
`;

const SideContainer = styled(Box)`
  // width: 270px;
	width: 68px;
	overflow-x: visible;
`;

const ContentContainer = styled(Box)`
  flex: 1;
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: hidden;
`;

const Main = styled.main`
  flex: 1;
	padding: 2rem;
	overflow-y: auto;
`;

const DashboardContainer = (props: DashboardContainerProps) => {
	const { user: { user } } = useContext(AppContext);
	// drawer side navigation on the left
	// content on the right
	return (
		<Container>
			<Helmet>
				<style>{'body { background-color: #ffffff !important; }'}</style>
			</Helmet>
			<SideContainer>
				<TopNavigation />
			</SideContainer>
			<ContentContainer>
				{props.showTitleBar && (
					<Box p={2} py={1.5} display='flex' alignItems='center' gap={2} borderBottom='1px solid #e4e4e4'>
						{/* <IconButton size='sm' variant='outlined' color='neutral' onClick={() => navigate(-1)} sx={{ minWidth: '28px', minHeight: '28px' }}>
					<ArrowBackIcon fill='currentColor' width={12} height={12} />
				</IconButton> */}
						<Box display='flex' alignItems='center' gap={1.5}>
							<Select size='sm' variant='soft' color='neutral' value={user?.organization?.publicId} renderValue={({ value }) => {
								return (
									<React.Fragment>
										<ListItemDecorator>
											<img src={user?.organization?.logoUrl} alt={user?.organization?.name} width={16} height={16} style={{ marginRight: '0.5rem' }} />
										</ListItemDecorator>
										{user?.organization?.name}
									</React.Fragment>
								);
							}}>
								<Option value={user?.organization?.publicId}>
									<ListItemDecorator>
										<img src={user?.organization?.logoUrl} alt={user?.organization?.name} width={16} height={16} />
									</ListItemDecorator>
									{user?.organization?.name}
								</Option>
							</Select>
							<Typography fontSize='18px' color='secondary' fontWeight='light'>/</Typography>
							<Typography level='h4'>{props.titleBar?.title}</Typography>
						</Box>
						<Box flexGrow={1} display='flex' justifyContent='flex-end'>
							{/* <Typography level='body-sm' color='neutral' fontWeight='regular'>{evalRunId}</Typography> */}
							<Box display='flex' alignItems='center' gap={1}>
								{...(props.titleBar?.actions || [])}
							</Box>
						</Box>
					</Box>
				)}
				<Main {...props.mainProps}>
					{props.children}
				</Main>
			</ContentContainer>
		</Container>
	);
};

export default DashboardContainer;
