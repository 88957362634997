import React from 'react';

export default function BoldIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			id='bold'
			enableBackground='new 0 0 24 24'
			height='512'
			viewBox='0 0 24 24'
			width='512'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='m23.508.003c-4.685-.084-10.028 2.365-13.41 6.164-3.232.061-6.379 1.386-8.696 3.703-.135.133-.183.332-.124.512.06.181.216.312.404.339l3.854.552-.476.533c-.177.198-.168.499.02.687l6.427 6.427c.097.097.225.146.354.146.119 0 .238-.042.333-.127l.533-.476.552 3.854c.027.188.175.326.354.386.046.015.094.022.143.022.142 0 .287-.062.387-.161 2.285-2.285 3.61-5.432 3.671-8.664 3.803-3.389 6.272-8.73 6.163-13.409-.007-.266-.222-.481-.489-.488zm-4.608 8.632c-.487.487-1.127.731-1.768.731s-1.281-.244-1.768-.731c-.974-.975-.974-2.561 0-3.536.975-.975 2.561-.975 3.536 0s.975 2.562 0 3.536z'/>
			<path d='m2.724 16.905c-1.07 1.07-2.539 5.904-2.703 6.451-.053.176-.004.367.125.497.096.096.223.147.354.147.048 0 .096-.007.144-.021.547-.164 5.381-1.633 6.451-2.703 1.205-1.205 1.205-3.166 0-4.371-1.206-1.205-3.166-1.204-4.371 0z'/>
		</svg>
	);
}
