import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Chip, FormLabel, Input, Modal, ModalDialog, Option, Select, Textarea, Typography } from '@mui/joy';
import LogoIcon from '@/icons/LogoIcon';
import ParameterInput from '@/pages/Builder/components/ParameterInputV2';
import SchemaInput, { JsonSchema } from '@/pages/Builder/components/SchemaInput';
import styled from '@emotion/styled';
import WebIcon from '@/icons/WebIcon';
import API from '@/api';
import { BuilderContext } from '@/pages/Builder/BuilderContext';
import { useNavigate } from 'react-router-dom';


const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

interface RequestData {
  originUrl: string;
  method: string;
  url: string;
}

const CreateSiteEndpointModal = ({ open, onClose, requestData }: { open: boolean, onClose: () => void, requestData: RequestData }) => {
	const navigate = useNavigate();

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		onClose();
	};

	if (!requestData) {
		return null;
	}

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog
				sx={{
					p: 2,
					backgroundColor: 'rgba(250, 250, 250, 0.75)',
					backdropFilter: 'blur(4px)',
					width: '600px',
					boxShadow: '0 1px inset var(--joy-palette-third-shadowHighColor)',
				}}
			>
				<Box>
					<Typography level='title-lg'>Create Site Endpoint</Typography>
					<Typography level='body-sm'>
					  Create a site endpoint from requests.
					</Typography>
					<Box>
						<Typography level='body-sm'>
							{requestData.originUrl}
						</Typography>
						<Box display='flex' gap={1}>
							<Chip size='sm' variant='soft' color='neutral'>{requestData.method}</Chip>
							<Typography level='body-sm'>
								{requestData.url}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Form onSubmit={handleSubmit}>
				</Form>

			</ModalDialog>
		</Modal>
	);
};

export default CreateSiteEndpointModal;
