import React, { useContext, useEffect, useState } from 'react';
import Main from '@/components/Main';
import Header from '@/components/Header';
import { Typography, Box, Grid, Input, Button, Skeleton, Table, Chip, Divider, ToggleButtonGroup, CircularProgress, AspectRatio, ButtonGroup, ColorPaletteProp, IconButton } from '@mui/joy';
import { Paper } from '@mui/material';
import API from '../api';
import moment from 'moment';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark, a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from '@emotion/styled';
import DashboardContainer from '../components/DashboardContainer';
import BuilderIcon from '../icons/BuilderIcon';
import Node from './Builder/components/Node';
import PersonIcon from '@/icons/PersonIcon';
import SparkleIcon from '@/icons/SparkleIcon';
import { NavLink, useNavigate } from 'react-router-dom';
import DisconnectedIcon from '@/icons/DisconnectedIcon';
import CloseIcon from '@/icons/CloseIcon';
import CodeMirror from '@uiw/react-codemirror';
import { EditorView } from '@codemirror/view';
import { customTheme } from './EvalRunPage';
import { json } from '@codemirror/lang-json';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import { AppContext } from '@/context/AppContext';
import WebIcon from '@/icons/WebIcon';
import { formatDuration } from '@/util';


const Section = styled(Box)`
  padding: 1rem;
`;

const UsageRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? 'var(--joy-palette-neutral-100)' : 'transparent'};
	&:hover {
		background-color: var(--joy-palette-neutral-50);
	}
`;

const SessionsPage: React.FC = () => {
	const [sessions, setSessions] = useState([]);
	const [selectedSession, setSelectedSession] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		API.getSessions().then((data) => {
			console.log(data);
			setSessions(data);
		});

	}, []);


	return (
		<DashboardContainer titleBar={{ title: 'Browser Sessions' }} showTitleBar mainProps={{ style: { padding: 0 } }}>
			<Box display='flex' flexDirection='row' style={{ height: '100%' }}>
				<Box width='100%' sx={{ overflowX: 'auto' }}>
					<table>
						<thead>
							<tr>
								<th><Typography level='title-sm' color='neutral'>Created At</Typography></th>
								<th><Typography level='title-sm' color='neutral'>Session ID</Typography></th>
							</tr>
						</thead>
						<tbody>
							{sessions?.map((s) => (
								<UsageRow active={selectedSession?.id === s.id} key={s.id} onClick={() => navigate(`/sessions/${s.sessionId}`)}>
									<td>
										<Typography level='body-sm'>{moment(s.createdAt).format('MMM DD, YYYY hh:mm a')}</Typography>
									</td>
									<td>
										<Typography level='body-sm'>{s.sessionId}</Typography>
									</td>
								</UsageRow>
							))}
						</tbody>
					</table>
				</Box>
			</Box>
		</DashboardContainer>
	);
};

export default SessionsPage;
