import API from '@/api';
import DisconnectedIcon from '@/icons/DisconnectedIcon';
import LogoIcon from '@/icons/LogoIcon';
import WebIcon from '@/icons/WebIcon';
import styled from '@emotion/styled';
import { Box, Button, Input, Modal, ModalDialog, Textarea, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

// Add type for environment
type Environment = {
	name: string;
	value: string;
	icon: JSX.Element;
}

const CreateBenchmarkModal = ({ open, onClose }: { open: boolean, onClose: (created: boolean) => void }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [name, setName] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [selectedIcon, setSelectedIcon] = useState<string>('');
	const [iconInput, setIconInput] = useState<string>('');

	const icons = [
		{
			name: 'Web',
			value: 'web',
			icon: <WebIcon width={12} height={12} />
		},
		{
			name: 'Logo',
			value: 'logo',
			icon: <LogoIcon width={12} height={12} />
		},
		{
			name: 'Offline',
			value: 'offline',
			icon: <DisconnectedIcon width={12} height={12} />
		}
	];

	const handleIconChange = (value: string) => {
		setIconInput(value);

		// Check if input is a URL
		const isUrl = /^(http|https):\/\/[^ "]+$/.test(value);

		if (isUrl) {
			setSelectedIcon(value);
		} else {
			// Treat as emoji and convert to base64
			const canvas = document.createElement('canvas');
			canvas.width = 128;
			canvas.height = 128;
			const ctx = canvas.getContext('2d');
			if (ctx) {
				// Enable anti-aliasing
				ctx.imageSmoothingEnabled = true;
				ctx.imageSmoothingQuality = 'high';

				// Make font size smaller to ensure padding
				ctx.font = '96px Arial';
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';

				// Add padding by adjusting the y position slightly up
				ctx.fillText(value, canvas.width / 2, canvas.height / 2);
				const base64 = canvas.toDataURL('image/png', 1.0);
				setSelectedIcon(base64);
			}
		}
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		try {
			await API.createTestCaseSet({
				name,
				description,
				icon: selectedIcon
			});
			onClose(true);
		} catch (error) {
			console.error('Failed to create benchmark:', error);
		}
		setLoading(false);
	};

	return (
		<Modal open={open} onClose={() => onClose(false)}>
			<ModalDialog
				sx={{
					p: 2,
					backgroundColor: 'rgba(250, 250, 250, 0.75)',
					backdropFilter: 'blur(4px)',
					width: '400px',
					boxShadow: '0 1px inset var(--joy-palette-third-shadowHighColor)',
				}}
			>
				<LogoIcon width={42} height={42} />
				<Box>
					<Typography level='title-lg'>Create New Benchmark</Typography>
					<Typography level='body-sm'>
						Create a new benchmark to evaluate agent performance.
					</Typography>
				</Box>
				<Form onSubmit={handleSubmit}>
					<Box display='flex' flexDirection='column' gap={0.5}>
						<Typography level='body-sm'>Name</Typography>
						<Input
							size='sm'
							value={name}
							onChange={(e) => setName(e.target.value)}
							required
							sx={{ boxShadow: 'none', borderRadius: '5px', border: '1px solid #e4e4e4' }}
							placeholder='Enter benchmark name'
						/>
					</Box>

					<Box display='flex' flexDirection='column' gap={0.5}>
						<Typography level='body-sm'>Description</Typography>
						<Textarea
							size='sm'
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							required
							minRows={3}
							sx={{ boxShadow: 'none', borderRadius: '5px', border: '1px solid #e4e4e4' }}
							placeholder='Describe the benchmark purpose and goals'
						/>
					</Box>

					<Box display='flex' flexDirection='column' gap={0.5}>
						<Typography level='body-sm'>Icon</Typography>
						<Input
							size='sm'
							value={iconInput}
							onChange={(e) => handleIconChange(e.target.value)}
							required
							sx={{ boxShadow: 'none', borderRadius: '5px', border: '1px solid #e4e4e4' }}
							placeholder='Enter an image URL or emoji'
						/>
						<Typography level='body-xs' sx={{ color: 'text.tertiary' }}>
							You can enter either a valid image URL or an emoji character
						</Typography>
						{selectedIcon && (
							<Box mt={1} display='flex' justifyContent='center'>
								<img src={selectedIcon} alt="Selected icon" style={{ width: 32, height: 32, objectFit: 'contain' }} />
							</Box>
						)}
					</Box>

					<Box display='flex' justifyContent='flex-end' mt={2}>
						<Button size='sm' type='submit' variant='shadowed' color='secondary' loading={loading}>
							Create
						</Button>
					</Box>
				</Form>
			</ModalDialog>
		</Modal>
	);
};

export default CreateBenchmarkModal;