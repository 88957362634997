import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Box, Button, Checkbox, Chip, Divider, Grid, Input, Option, Select, Typography } from '@mui/joy';
import DashboardContainer from '../components/DashboardContainer';
import API from '../api';
import { Paper } from '@mui/material';
import styled from '@emotion/styled';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark, a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import CodeIcon from '@/icons/CodeIcon';
import CreateSiteEndpointModal from '@/components/CreateSiteEndpointModal';
import { useDebounce } from 'use-debounce';

const Section = styled(Box)`
  padding: 1rem;
`;

const TableRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? 'var(--joy-palette-neutral-100)' : 'transparent'};
`;

const SiteRequestsPage: React.FC = () => {
	// const [sitesByOriginUrl, setSitesByOriginUrl] = useState<Record<string, any[]>>({});
	const [originUrls, setOriginUrls] = useState<string[]>([]);
	const [selectedRequest, setSelectedRequest] = useState<any | null>(null);
	const [siteResponse, setSiteResponse] = useState<any>(null);
	const [siteRequests, setSiteRequests] = useState<any[]>([]);
	const [siteResponses, setSiteResponses] = useState<any[]>([]);

	const [filters, setFilters] = useState<Record<string, string>>({
		originUrl: '',
		url: '',
		method: '',
		contentType: '',
	});

	const [createEndpointModalOpen, setCreateEndpointModalOpen] = useState<boolean>(false);

	const [debouncedFilters] = useDebounce(filters, 300);

	useEffect(() => {
		setSiteResponses([]);
		if (selectedRequest) {
			API.getSiteResponses({ ...selectedRequest }).then((res) => {
				setSiteResponses(res.site);
			});
		}
	}, [selectedRequest]);

	const onGenerateEndpoint = () => {
		// API.postSiteEndpointFromRequest({ ...selectedAsset }).then((res) => {
		// 	console.log(res);
		// });
		setCreateEndpointModalOpen(true);
	};

	useEffect(() => {
		const fetchSites = async () => {
			const { originUrls } = await API.getSiteOriginUrls();
			setFilters({ ...filters, originUrl: originUrls[0] });
			setOriginUrls(originUrls);
		};
		fetchSites();
	}, []);

	useEffect(() => {
		const fetchSites = async () => {
			if (!debouncedFilters.originUrl) return;

			const { requests } = await API.getSiteRequests({ ...debouncedFilters });

			if (!selectedRequest) {
				setSelectedRequest(requests[0]);
			}
			setSiteRequests(requests);
		};
		fetchSites();
	}, [debouncedFilters]);

	return (
		<DashboardContainer>
			<CreateSiteEndpointModal open={createEndpointModalOpen} onClose={() => setCreateEndpointModalOpen(false)} requestData={selectedRequest} />
			<Typography marginBottom={2} level='h2'>Site Requests</Typography>
			<Select value={filters.originUrl} onChange={(event, value) => setFilters({ ...filters, originUrl: value })}>
				{originUrls.map((originUrl) => (
					<Option key={originUrl} value={originUrl}>{originUrl}</Option>
				))}
			</Select>

			<Box marginTop={2} display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' gap={2}>
				<Input
					placeholder='URL'
					value={filters.url}
					onChange={(event) => setFilters({ ...filters, url: event.target.value })}
				/>
				<Input
					placeholder='Method'
					value={filters.method}
					onChange={(event) => setFilters({ ...filters, method: event.target.value })}
				/>
				<Input
					placeholder='Content Type'
					value={filters.contentType}
					onChange={(event) => setFilters({ ...filters, contentType: event.target.value })}
				/>
				<Input
					placeholder='Session ID'
					value={filters.sessionPublicId}
					onChange={(event) => setFilters({ ...filters, sessionPublicId: event.target.value })}
				/>
			</Box>

			<Grid container spacing={2}>
				<Grid xs={12} md={4}>
					<Paper style={{ padding: 0, marginTop: 16, overflow: 'hidden' }}>
						<table style={{ tableLayout: 'fixed', width: '100%' }}>
							<thead>
								<tr>
									<th style={{ width: '82px' }}>Method</th>
								</tr>
							</thead>
							<tbody>
								{siteRequests.map((siteRequest) => (
									<TableRow active={siteRequest === selectedRequest} key={siteRequest.url + siteRequest.method + siteRequest.contentType} onClick={() => setSelectedRequest(siteRequest)}>
										{/* <td style={{ width: '82px' }}><Chip size='sm' variant='soft' color='neutral'>{siteRequest.method}</Chip></td>
										<Typography sx={{ width: 'auto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} component='td' level='body-xs'>{siteRequest.url}</Typography>
										<td style={{ width: '142px' }}><Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} level='body-xs'>{siteRequest.contentType}</Typography></td> */}
										<td>
											<Box display='flex' alignItems='center' gap={1}>
												<Chip size='sm' variant='plain' color='neutral'>{siteRequest.method}</Chip>
												<Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} level='body-xs'>
													{siteRequest.url}
												</Typography>
											</Box>
										</td>
									</TableRow>
								))}
							</tbody>
						</table>
					</Paper>
				</Grid>
				<Grid xs={12} md={8}>
					<Paper style={{ padding: 0, marginTop: 16 }}>
						<Section>
							<Typography level='title-sm' sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{selectedRequest?.url}</Typography>
						</Section>
						<Divider />
						<Section>
							<Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='center' rowGap={1} columnGap={2} margin={0}>
								<Typography color='neutral' level='title-sm'>Method</Typography>
								<Chip size='sm' variant='soft' color='neutral'>{selectedRequest?.method}</Chip>
								<Typography color='neutral' whiteSpace='nowrap' level='title-sm'>Content Type</Typography>
								<Chip size='sm' variant='soft' color='neutral'>{selectedRequest?.contentType}</Chip>
							</Box>
							<Button
								sx={{ marginTop: '16px' }}
								color='secondary'
								size='sm'
								variant='shadowed'
								onClick={onGenerateEndpoint}
								startDecorator={<CodeIcon fill='currentColor' width={14} height={14} />}
							>
								Generate Endpoint
							</Button>
						</Section>
						<Divider />
						<Section>
							{Boolean(selectedRequest) && (
								<>
									<Typography level='title-sm'>Response</Typography>
									<Box display='flex' flexDirection='column' gap={1}>
										{siteResponses?.map((siteResponse) => {
											return (
												<Box key={siteResponse.url + siteResponse.method + siteResponse.contentType} display='flex' alignItems='center' gap={1}>
													<Checkbox
														size='sm'
														checked={siteResponse.selected}
														onChange={() => {
															setSiteResponses(siteResponses.map((response) => ({ ...response, selected: response.url === siteResponse.url })));
														}}
													/>
													<Typography level='title-sm'>{siteResponse.url}</Typography>
												</Box>
											);
										})}
									</Box>
									{/* <Box bgcolor='var(--joy-palette-neutral-700)' padding={1.5}>
										<SyntaxHighlighter
											language='json'
											style={a11yDark}
											customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }}
											showLineNumbers={false}
											wrapLongLines
											copyable
										>
											{siteResponse?.content}
										</SyntaxHighlighter>
									</Box> */}
								</>
							)}
						</Section>
					</Paper>
				</Grid>
			</Grid>
			{/* <AccordionGroup>
				{Object.entries(sitesByOriginUrl).map(([originUrl, urls]) => (
					<Accordion key={originUrl}>
						<AccordionSummary>
							<Typography>{originUrl}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							{urls.map((url) => <Typography key={url}>{url}</Typography>)}
						</AccordionDetails>
					</Accordion>
				))}
			</AccordionGroup> */}
		</DashboardContainer>
	);
};

export default SiteRequestsPage;
