import React, { useEffect } from 'react';
import { Typography, Box, Grid } from '@mui/joy';
import styled from '@emotion/styled';
import DashboardContainer from '@/components/DashboardContainer';
import { Helmet } from 'react-helmet';


const Section = styled(Box)`
  padding: 1rem;
`;


const SessionsPage: React.FC = () => {

	useEffect(() => {

	}, []);


	return (
		<DashboardContainer showTitleBar titleBar={{ title: 'Admin' }} mainProps={{ style: { padding: 0 } }}>
			<Helmet>
				<title>Plato</title>
			</Helmet>
		</DashboardContainer>
	);
};

export default SessionsPage;
