import React from 'react';

export default function Icon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' id='Layer_1' enableBackground='new 0 0 32 32' {...props}>
			<g>
				<g>
					<path d='M11.9558592 13.822526h-4.0450521c-3.2593746 0-5.9108071-2.6523438-5.9108071-5.9117189s2.6514325-5.9108071 5.9108071-5.9108071 5.9117188 2.6514325 5.9117188 5.9108071v4.0450521c.0000001 1.0308599-.8358068 1.8666668-1.8666667 1.8666668zm-4.0450521-8.0891929c-1.2003903 0-2.177474.9770837-2.177474 2.177474 0 1.2013021.9770837 2.1783853 2.177474 2.1783853h2.1783853v-2.1783853c0-1.2003903-.9770832-2.177474-2.1783853-2.177474z' />
				</g>
				<g>
					<path d='M24.0891933 13.822526h-4.0450535c-1.030859 0-1.8666668-.8358068-1.8666668-1.8666668v-4.0450521c.0000001-3.2593746 2.6523438-5.9108071 5.9117203-5.9108071s5.9108067 2.6514325 5.9108067 5.9108071-2.651432 5.9117189-5.9108067 5.9117189zm-2.1783866-3.7333336h2.1783867c1.2003899 0 2.1774731-.9770832 2.1774731-2.1783853 0-1.2003903-.9770832-2.177474-2.1774731-2.177474-1.2013035 0-2.1783867.9770837-2.1783867 2.177474z' />
				</g>
				<g>
					<path d='M7.9108071 30c-3.2593746 0-5.9108071-2.651432-5.9108071-5.9108067s2.6514325-5.9117203 5.9108071-5.9117203h4.0450521c1.0308599 0 1.8666668.8358078 1.8666668 1.8666668v4.0450535c0 3.2593747-2.6523438 5.9108067-5.9117189 5.9108067zm0-8.0891933c-1.2003903 0-2.177474.9770832-2.177474 2.1783867 0 1.2003899.9770837 2.1774731 2.177474 2.1774731 1.2013021 0 2.1783853-.9770832 2.1783853-2.1774731v-2.1783867z' />
				</g>
				<g>
					<path d='M24.0891933 30c-3.2593765 0-5.9117203-2.651432-5.9117203-5.9108067v-4.0450535c0-1.030859.8358078-1.8666668 1.8666668-1.8666668h4.0450535c3.2593747.0000001 5.9108067 2.6523438 5.9108067 5.9117203s-2.651432 5.9108067-5.9108067 5.9108067zm-2.1783866-8.0891933v2.1783867c0 1.2003899.9770832 2.1774731 2.1783867 2.1774731 1.2003899 0 2.1774731-.9770832 2.1774731-2.1774731 0-1.2013035-.9770832-2.1783867-2.1774731-2.1783867z' />
				</g>
				<g>
					<path d='M20.0441399 21.9108067h-8.0882807c-1.030859 0-1.8666668-.8358059-1.8666668-1.8666668v-8.0882807c0-1.030859.8358078-1.8666668 1.8666668-1.8666668h8.0882807c1.0308609 0 1.8666668.8358078 1.8666668 1.8666668v8.0882807c0 1.0308609-.8358059 1.8666668-1.8666668 1.8666668zm-6.2216139-3.7333336h4.3549471v-4.3549471h-4.3549471z' />
				</g>
			</g>
		</svg>
	);
}
