import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Input, List, ListItem, ListItemButton, ListItemContent, Typography, Button, ListItemDecorator, Chip } from '@mui/joy';
import styled from '@emotion/styled';
import LogoIcon from '@/icons/LogoIcon';
import { BuilderContext, Pages } from './BuilderContext';
import { NavLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import SchemaInput, { JsonSchema } from './components/SchemaInput';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import CheckIcon from '@/icons/CheckIcon';
import SchemaOutput from './components/SchemaOutput';
import API from '@/api';
import Events from './Events';
import { buildElementQuery } from '@/src/web-util';


const Root = styled(Box)`
  width: 380px;
  height: 100%;
  background: var(--joy-palette-background-level1);
  // background-color: #ffff;
  // border-radius: 5px;
  flex-shrink: 0;
  overflow-y: auto;
  border-right: 1px solid #e4e4e4;
  // border: 1px solid #e4e4e4;
  position: relative;
	display: flex;
	flex-direction: column;
  box-shadow: 2px 0px 8px #00000029;
`;

const Header = styled(Box)`
	// background-color: rgba(240, 240, 240, 0.70);
	background: var(--joy-palette-background-level1);
	backdrop-filter: blur(8px);
	// border-bottom: 1px solid #e4e4e4;
  display: flex;
  height: 58px;
  align-items: center;
  gap: 8px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  justify-content: space-between;
`;


const OfflineSidebar = ({ iframeRef }: { iframeRef: React.RefObject<HTMLIFrameElement> }) => {
	const { page, selectedWebFlow, browserSession } = useContext(BuilderContext);
	const navigate = useNavigate();
	const ref = useRef(null);
	const [sessionDuration, setSessionDuration] = useState(Date.now() - (new Date(browserSession?.activeAt || Date.now())).getTime());
	const [selectedItem, setSelectedItem] = useState<string>('');
	const [itemSelectors, setItemSelectors] = useState<Record<string, string>>({});
	const [output, setOutput] = useState<any>({});

	const onMarkCompleted = async () => {
		// await API.markTrainerSessionCompleted(browserSession?.publicId, output);
		await navigate('/sessions');
	};

	const browserUrl = `${window.location.protocol}//browser.${window.location.host}`;


	const onSelectItem = (item: string) => {
		console.log('item', item);
		if (item === selectedItem) {
			setSelectedItem('');
		} else {
			setSelectedItem(item);
		}
	};


	useEffect(() => {
		const onOutputCalculated = (event: CustomEvent) => {
			setOutput(event.detail.output);
		};

		window.addEventListener(Events.ON_OUTPUT_CALCULATED, onOutputCalculated);

		return () => {
			window.removeEventListener(Events.ON_OUTPUT_CALCULATED, onOutputCalculated);
		};
	}, [setOutput]);

	const handleElementSelected = async (event: CustomEvent) => {
		console.log('element selected', event);
		const { element } = event.detail;
		const newItemSelectors = { ...itemSelectors, [selectedItem]: buildElementQuery(element) };
		setItemSelectors(newItemSelectors);
		const res = await API.extractFromSelectors(browserSession?.publicId, newItemSelectors, browserSession?.trainerSessionOptions?.responseFormat || {});
		console.log(res);
		setOutput(res.data);
		// iframeRef.current?.contentWindow?.postMessage(JSON.stringify({
		// 	type: 'output',
		// 	data: {
		// 		selectors: newItemSelectors,
		// 		schema: browserSession?.trainerSessionOptions?.responseFormat || {}
		// 	}
		// }), browserUrl);
	};

	useEffect(() => {
		iframeRef.current?.contentWindow?.postMessage(JSON.stringify({
			type: 'activeSelector',
			data: {
				selector: itemSelectors[selectedItem]
			}
		}), browserUrl);
		if (selectedItem.length > 0) {
			window.addEventListener(Events.ON_ELEMENT_SELECTED, handleElementSelected);
		}
		return () => {
			window.removeEventListener(Events.ON_ELEMENT_SELECTED, handleElementSelected);
		};
	}, [selectedItem, itemSelectors, iframeRef.current]);


	useEffect(() => {
		ref.current.scrollTo({ top: 0 });
	}, [page]);

	useEffect(() => {
		const interval = setInterval(() => {
			setSessionDuration(Date.now() - (new Date(browserSession?.activeAt || Date.now())).getTime());
		}, 1000);
		return () => clearInterval(interval);
	}, [browserSession?.activeAt]);

	return (
		<Root ref={ref}>
			<Header p={2} pr={1}>
				<Box display='flex' alignItems='center' gap={1}>
					<NavLink style={{ display: 'flex' }} to='/'>
						<LogoIcon style={{ borderRadius: '4px' }} width={24} height={24} />
					</NavLink>
					<NavLink to='/'>
						<Typography fontSize='18px' fontWeight='bold' level='title-md'>Plato</Typography>
					</NavLink>
					<Chip size='sm' variant='soft' color='danger'>Offline</Chip>
				</Box>
				<Box display='flex' alignItems='center' gap={1}>
					<Typography level='body-sm'>{moment.utc(sessionDuration).format('HH:mm:ss')}</Typography>
					<Button size='sm' variant='shadowed' color='third' startDecorator={<CheckIcon width={12} height={12} />} onClick={onMarkCompleted}>End</Button>
				</Box>
			</Header>
			<Box flexGrow={1} p={1} display='flex' flexDirection='column' gap={2}>
				<Box py={1} px={1.5} border='1px solid #e4e4e4' borderRadius={5} bgcolor='white'>
					<Typography textAlign='center'level='title-md'>{browserSession?.trainerSessionOptions?.task || 'Extract companies from ycombinator'}</Typography>
				</Box>

				<SchemaOutput
					value={(browserSession?.trainerSessionOptions?.responseFormat || { type: 'object', title: 'Companies', properties: { name: { type: 'string' } } }) as JsonSchema}
					onChange={() => {}}
					onSelectItem={onSelectItem}
					selectedItem={selectedItem}
				/>

				<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='flex-end' marginTop={2}>
					<Typography level='title-lg' mb={1}>Output</Typography>
					<Box p={2} bgcolor='neutral.700' flexGrow={1} sx={{ overflowY: 'auto' }} borderRadius={5}>
						<SyntaxHighlighter showLineNumbers={false} language='json' style={a11yDark} customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }} wrapLongLines>
							{JSON.stringify(output, null, 2)}
						</SyntaxHighlighter>
					</Box>
				</Box>
			</Box>
		</Root>
	);
};

export default OfflineSidebar;
